import React, { useState, useEffect } from 'react'
import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/PersonAdd'
import { Tooltip } from '@material-ui/core'
import axios from 'axios'
import Cookies from 'universal-cookie'
import Paper from '@material-ui/core/Paper';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from '@material-ui/core'

import PartnersTable from '../components/partners/PartnersTable';
import PartnersCard from '../components/partners/PartnersCard';
import AddPartnerDialog from '../components/partners/AddPartnerDialog';

import FeedBackDialog from '../components/ErrorDialog/FeedBackDialog';

import './css/Partners.css'

const cookies = new Cookies();

function Partners() {

    const [activeUser, setActiveUser] = useState('');
    const [triggerAddDialog, setTriggerAddDialog] = useState(false);
    const [triggerFetch, setTriggerFetch] = useState(false);
    const [partners, setPartners] = useState([]);
    const [selectedPartner, setSelectedPartner] = useState('');

    const [feedbackDialog, setFeedbackDialog] = useState(false);
    const [feedbackTitle, setFeedbackTitle] = useState('');
    const [feedbackContent, setFeedbackContent] = useState('')


    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}/api/partnerships`, { headers: { 'apikey': cookies.get('key') } })
            .then((res) => {
                setPartners(res.data);
            }, (err) => {
                console.log(err)
                setFeedbackDialog(true);
                setFeedbackTitle('Erro')
                setFeedbackContent(`Não foi possivel comunicar com o servidor. Se o problema persitir contacte o administrador do servidor. ${err}`)
            });
    }, [triggerFetch])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}/api/users/${cookies.get('userID')}`, { headers: { 'apikey': cookies.get('key') } })
            .then((res) => {
                setActiveUser(res.data)
            }, (err) => {
                console.log(err)
            });
    }, [triggerFetch])

    const refresh = () => {
        setTriggerFetch(!triggerFetch);
    }

    const getTableData = (currentSelection) => {
        setSelectedPartner(currentSelection)
    }

    return (
        <div className="partners-container" >
            <div className="partners-content">
                <div className="util-field-partners">
                    <div className='partners-title'>Parceiros</div>
                    <Tooltip title="Adicionar novo Parceiro" placement="left">
                        <Fab style={{ marginRight: '15px' }} color="primary" aria-label="add" onClick={() => setTriggerAddDialog(true)}>
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                </div>
                <div className="bottom-partners-container">
                    <div className="partners-content-table">
                        <Paper elevation={5} style={{ height: 'fit-content' }} className="dash-paper" sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <PartnersTable data={partners} getCurrentData={getTableData} />
                        </Paper>
                    </div>
                    <div className="partners-preview">
                        {selectedPartner !== '' ? (
                            <Paper elevation={5} style={{ height: 'fit-content', width: '450px' }} className="dash-paper" sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                <PartnersCard refreshPartners={refresh} currentSelectedPartner={selectedPartner} activeUser={activeUser} />
                            </Paper>
                        ) : ''}
                    </div>
                </div>
            </div>
            <Dialog
                fullWidth={true}
                maxWidth='md'
                open={triggerAddDialog}
                onClose={() => setTriggerAddDialog(false)}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent>
                    <AddPartnerDialog />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setTriggerAddDialog(false); refresh(); }} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
            <FeedBackDialog
                open={feedbackDialog}
                close={() => setFeedbackDialog(false)}
                title={feedbackTitle}
                content={feedbackContent} />
        </div>
    )
}

export default Partners
import React, { useState, useEffect } from 'react'
import './css/Reservations.css'
import Paper from '@material-ui/core/Paper';
import axios from 'axios'
import Cookies from 'universal-cookie'
import FeedbackTable from '../components/feedbacks/FeedbackTable'
import moment from 'moment';
import FeedbackGraph from '../components/feedbacks/FeedbackGraph';
import { makeStyles } from '@material-ui/core/styles';


const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
    graphDiv: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '25px',
        justifyContent: 'center'
    },
    graphPaper: {
        width: '500px',
        margin: '10px 10px 10px 10px',
        display: 'flex',
    },
    graphHelper: {
        width: '30%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
    },
    mediaDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    graphButtonDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
}));

function Feedback() {
    const classes = useStyles();
    const [refreshTrigger, setRefreshTrigger] = useState(false);
    const [data, setData] = useState([])
    const [coworks, setCoworks] = useState([])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}/api/feedbacks`, { headers: { 'apikey': cookies.get('key') } })
            .then((res) => {
                res.data.map(item => {
                    item.checkIn = moment(item.checkIn).format('YYYY/MM/DD HH:mm');
                    item.checkOut = moment(item.checkOut).format('YYYY/MM/DD HH:mm');
                    return '';
                })
                setData(res.data);
            }, (err) => {
                console.log(err)
            });

        axios.get(`${process.env.REACT_APP_SERVER_URL}/api/coworks`, { headers: { 'apikey': cookies.get('key') } })
            .then((res) => {
                setCoworks(res.data);
            }, (err) => {
                console.log(err)
            });

    }, [refreshTrigger])

    const refreshData = () => {
        setRefreshTrigger(!refreshTrigger)
    }

    return (
        <div className="reservations-container">
            <div className="reservations-content">
                <div className="bottom-reservations-container" style={{ marginTop: '65px'}}>
                    <Paper elevation={5} style={{ height: 'fit-content' }} className="dash-paper" sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <FeedbackTable data={data} refreshData={refreshData} />
                    </Paper>
                </div>
                <div className={classes.graphDiv}>
                    {coworks.map(cowork => {
                        return (
                            <Paper elevation={5} key={cowork.id} className={classes.graphPaper} sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                <div style={{ width: '100%' }}>
                                    <FeedbackGraph coworkData={cowork} data={data.filter(feedback => feedback.cowork === cowork.title)} />
                                </div>
                            </Paper>
                        )
                    })}
                </div>
            </div>
        </div >
    )
}

export default Feedback
import React, { useState } from 'react'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import CardMedia from '@material-ui/core/CardMedia';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { Tooltip } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core'
import Badge from '@material-ui/core/Badge';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import UserIcon from '@material-ui/icons/Person'
import EmailIcon from '@material-ui/icons/Email'
import KeyIcon from '@material-ui/icons/VpnKey'
import moment from 'moment';
import axios from 'axios'
import Cookies from 'universal-cookie'

import UserPlaceholderImage from '../../assets/users/user_placeholder.png'
import EditUserDialog from './EditUserDialog'

import '../../pages/css/UserCard.css'

const useStyles = makeStyles((theme) => ({
    root: {
        /*         display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center', */
        '& > *': {
            /*             margin: theme.spacing(1),
                        width: theme.spacing(20),
                        height: theme.spacing(20), */
            maxWidth: 270,
            width: 270,
        },
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '10px'
    },
    header: {
        width: '100%',
        height: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

    },
    cardContent: {
        padding: 0,
    },
    cardActions: {
        justifyContent: 'space-around',
        paddingBottom: '5px',
        paddingTop: '0px'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    media: {
        height: '150px',
        width: '50%',
        paddingTop: '15px',
    },
    avatar: {
        backgroundColor: 'rgb(187,222,251)',
        color: 'rgb(30,136,229)',
    },
    badge: {
        backgroundColor: 'green',
    }
}));

const cookies = new Cookies();

function UserCard(props) {

    const classes = useStyles();

    const [expanded, setExpanded] = React.useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [feedbackDialog, setFeedbackDialog] = useState(false);
    const [feedbackTitle, setFeedbackTitle] = useState('');
    const [feedbackContent, setFeedbackContent] = useState('');

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleDeteleUser = () => {

        axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/users/${props.user.id}`, { headers: { 'apikey': cookies.get('key') } })
            .then((res) => {
                setFeedbackDialog(true);
                setFeedbackTitle('Sucesso')
                setFeedbackContent(`O usuario ${props.user.username} foi eliminado com sucesso.`);
            }, (err) => {
                console.log(err)
                setFeedbackDialog(true);
                setFeedbackTitle('Erro')
                setFeedbackContent(`Não foi possivel eliminar o user ${props.user.username}. Se o problema persitir contacte o administrador do servidor`)
            });
    }

    const handleCloseDialog = () => {
        setDeleteDialog(false);
        setFeedbackDialog(false);
        props.refreshUsers();
    }

    const stringToColor = (string) => {

        if (string === undefined) string = 'No User'

        let hash = 0;
        let i;

        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.substr(-2);
        }

        return color

    }

    const getFirstLetterFromFirstAndLastName = (name) => {

        if (name === undefined) name = 'No User'

        let firstLetterFirstName = '';
        let firstLetterLastName = '';

        if (name.split(' ')[1] === undefined) {
            firstLetterFirstName = name[0];
            firstLetterLastName = name[1];
        } else {
            firstLetterFirstName = name.split(' ')[0][0];
            firstLetterLastName = name.split(' ')[1][0];
        }

        return `${firstLetterFirstName}${firstLetterLastName}`
    }

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <div className='round-userCard'>
                    <CardMedia
                        className='profilePic-userCard'
                        image={props.user.picture ? `${process.env.REACT_APP_SERVER_URL}/resources/images/uploads/users/${props.user.picture}` : UserPlaceholderImage}
                        component="img"
                        alt="Profile Pic"
                        height="140"

                    />
                </div>
                <CardHeader className={classes.header}
                    avatar={
                        <Badge invisible={!props.user.loggedIn} classes={{ badge: classes.badge }} overlap='circular' variant='dot'>
                            <Avatar style={{ backgroundColor: stringToColor(props.user.username) }}>
                                {getFirstLetterFromFirstAndLastName(props.user.username)}
                            </Avatar>
                        </Badge>
                    }
                    action={
                        <Tooltip title="Ver Mais" placement='bottom'>
                            <IconButton
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: expanded,
                                })}
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    title={props.user.username}
                    subheader={props.user.userType}
                />
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent className={classes.cardContent}>
                        <List style={{ paddingBottom: '0' }}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className={classes.avatar}>
                                        <UserIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="User Name" secondary={props.user.username} style={{ wordWrap: 'anywhere'}} />
                            </ListItem>
                            <Divider light />
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar  className={classes.avatar}>
                                        <EmailIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Email" secondary={props.user.email} style={{ wordWrap: 'anywhere'}} />
                            </ListItem>
                            <Divider light />
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar  className={classes.avatar}>
                                        <KeyIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Ultimo Login" secondary={moment(props.user.last_login).format('DD/MM/YYYY HH:mm')} />
                            </ListItem>
                        </List>
                    </CardContent>
                    {props.activeUser.userType === 'Admin' ? (
                        <CardActions disableSpacing className={classes.cardActions}>
                            <Tooltip title="Editar User" placement='bottom'>
                                <IconButton aria-label="edit-user" onClick={() => setEditDialog(true)}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Eliminar User" placement='bottom'>
                                <IconButton aria-label="delete-user" onClick={() => setDeleteDialog(true)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </CardActions>
                    ) : ''}
                </Collapse>
            </Card>
            <Dialog
                open={deleteDialog}
                onClose={() => setDeleteDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Tem a certesa que pretende eliminar este registo"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Se eliminar este user não poderá reverter este porcesso. Tem a certesa?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialog(false)} color="primary" autoFocus>
                        Cancelar
                    </Button>
                    <Button onClick={handleDeteleUser} color="primary">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth='md'
                open={editDialog}
                onClose={() => setEditDialog(false)}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent>
                    <EditUserDialog data={props.user} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setEditDialog(false); props.refreshUsers() }} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={feedbackDialog}
                onClose={() => setFeedbackDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="confirmation-dialog-title">{feedbackTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirmation-dialog-description">
                        {feedbackContent}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default UserCard

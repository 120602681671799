import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/core/styles';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import Rating from '@material-ui/lab/Rating';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    graphHelper: {
        width: '30%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
    },
    mediaDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    graphButtonDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    mainGraphDiv: {
        height: '50px',
        borderBottom: '1px solid black',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        textAlign: 'center'
    }
}));

const ratingInfo = {
    rating1: {
        subtitle: 'Rating Satisfação Geral',
        labels: ["0.5", "1", "1.5", "2", "2.5", "3", "3.5", "4", "4.5", "5"],
        colors: ['RGB(68,0,10)', 'RGB(102,0,16)', 'RGB(129,84,1)', 'RGB(180,117,0)', 'RGB(255,166,0)', 'RGB(255,223,0)', 'RGB(128,169,52)', 'RGB(94,169,52)', 'RGB(68,206,38)', 'RGB(46,255,0)']
    },
    rating2: {
        subtitle: 'Probabilidade de voltar a trabalhar neste cowork',
        labels: ["1", "2", "3", "4", "5"],
        colors: ['RGB(68,0,10)', 'RGB(129,84,1)', 'RGB(255,166,0)', 'RGB(128,169,52)', 'RGB(68,206,38)']
    },
    rating3: {
        subtitle: 'Probabilidade de recomendar a um amigo ou familiar',
        labels: ["1", "2", "3", "4", "5"],
        colors: ['RGB(68,0,10)', 'RGB(129,84,1)', 'RGB(255,166,0)', 'RGB(128,169,52)', 'RGB(68,206,38)']
    },
    rating4: {
        subtitle: 'Qualidade de dispositivos/internet',
        labels: ["1", "2", "3", "4", "5"],
        colors: ['RGB(68,0,10)', 'RGB(129,84,1)', 'RGB(255,166,0)', 'RGB(128,169,52)', 'RGB(68,206,38)']
    },
}

function FeedbackGraph(props) {

    const classes = useStyles();
    const [activeValue, setActiveValue] = useState('rating1')

    const rating = [];
    const rating2 = [];
    const rating3 = [];
    const rating4 = [];

    (function splitRatings() {
        props.data.forEach(feedback => {
            rating.push(feedback.rating)
            rating2.push(feedback.rating2)
            rating3.push(feedback.rating3)
            rating4.push(feedback.rating4)
        })
    }())

    const findArrayValuesMedium = () => {
        let media = 0;

        switch (activeValue) {
            case 'rating1': media = rating.reduce((sum, a) => sum + a, 0) / rating.length;
                break;
            case 'rating2': media = rating2.reduce((sum, a) => sum + a, 0) / rating2.length;
                break;
            case 'rating3': media = rating3.reduce((sum, a) => sum + a, 0) / rating3.length;
                break;
            case 'rating4': media = rating4.reduce((sum, a) => sum + a, 0) / rating4.length;
                break;
            default: media = 0;
        }

        return Math.round(media * 10) / 10;

    }

    const findGlobalValuesMedium = () => {
        const rating1Med = rating.reduce((sum, a) => sum + a, 0) / rating.length;
        const rating2Med = rating2.reduce((sum, a) => sum + a, 0) / rating2.length;
        const rating3Med = rating3.reduce((sum, a) => sum + a, 0) / rating3.length;
        const rating4Med = rating4.reduce((sum, a) => sum + a, 0) / rating4.length;

        return Math.round(((rating1Med + rating2Med + rating3Med + rating4Med) / 4) * 10) / 10
    }

    const organizeDataToGraph = (arr, multiplier, range = 5) => {
        const organizedArr = [];

        for (let i = multiplier; i <= range; i += multiplier) {
            organizedArr.push(arr.filter(item => item === i).length)
        }

        return organizedArr;
    }

    const switchValues = () => {
        switch (activeValue) {
            case 'rating1': return organizeDataToGraph(rating, 0.5);
            case 'rating2': return organizeDataToGraph(rating2, 1);
            case 'rating3': return organizeDataToGraph(rating3, 1);
            case 'rating4': return organizeDataToGraph(rating4, 1);
            default: return ''
        }
    }



    const handleToggleButtonChange = (event, newValue) => {
        if (!newValue) return;
        setActiveValue(newValue)
    }

    const state = {
        labels: ratingInfo[activeValue].labels,
        datasets: [
            {
                backgroundColor: ratingInfo[activeValue].colors,
                data: switchValues()
            }
        ],
    }

    return (
        <div>
            <div className={classes.mainGraphDiv}>
                <p style={{ fontSize: '22px', fontWeight: '500' }}>{props.coworkData.title}</p>
                <Tooltip title='Média global de todos os ratings'>
                    <div style={{ marginTop: '5px', display: 'flex' }}>
                        <Rating precision={0.1} name="read-only" value={findGlobalValuesMedium()} readOnly />
                        <p style={{ marginLeft: '10px', fontSize: '22px', fontWeight: '500' }}>{findGlobalValuesMedium()}</p>
                    </div>
                </Tooltip>
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{ width: '70%' }}>
                    <Bar
                        data={state}
                        width={400}
                        height={250}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    display: false,
                                    position: 'bottom',
                                },
                                title: {
                                    display: true,
                                    position: 'top',
                                    text: ratingInfo[activeValue].subtitle,
                                    padding: 15,
                                }
                            },
                            scales: {
                                y: {
                                    ticks: {
                                        stepSize: 1
                                    }
                                }
                            }
                        }}
                    />
                </div>
                <div className={classes.graphHelper}>
                    <Tooltip title='Média do rating selecionado'>
                        <div className={classes.mediaDiv}>
                            <p>Média:</p>
                            <Rating precision={0.1} name="read-only" value={findArrayValuesMedium()} readOnly />
                            <p>{findArrayValuesMedium()}</p>
                        </div>
                    </Tooltip>
                    <div className={classes.graphButtonDiv}>
                        <ToggleButtonGroup
                            color='primary'
                            orientation='vertical'
                            size='small'
                            exclusive
                            value={activeValue}
                            onChange={handleToggleButtonChange}
                        >
                            <Tooltip title='Rating Satisfação Geral' placement='right'>
                                <ToggleButton value='rating1'>Geral</ToggleButton>
                            </Tooltip>
                            <Tooltip title='Probabilidade de voltar a trabalhar neste cowork' placement='right'>
                                <ToggleButton value='rating2'>Voltar</ToggleButton>
                            </Tooltip>
                            <Tooltip title='Probabilidade de recomendar a um amigo ou familiar' placement='right'>
                                <ToggleButton value='rating3'>Recomendar</ToggleButton>
                            </Tooltip>
                            <Tooltip title='Qualidade de dispositivos/internet' placement='right'>
                                <ToggleButton value='rating4'>Qualidade</ToggleButton>
                            </Tooltip>
                        </ToggleButtonGroup>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default FeedbackGraph;
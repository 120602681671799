import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core'
import { Redirect } from 'react-router';


export default class FeedBackDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logoutTrigger: false
        }
    }

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.close}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="confirmation-dialog-title">{this.props.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="confirmation-dialog-description">
                            {this.props.content}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {this.props.title === 'Erro' ? (
                            <Button
                                onClick={() => this.setState({ logoutTrigger: true })} color="primary" autoFocus>
                                OK :'(
                            </Button>
                        ) : (
                            <Button
                                onClick={this.props.close} color="primary" autoFocus>
                                OK
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
                {this.state.logoutTrigger && (<Redirect to="/" />)}
            </div>
        )
    }
}

import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

export default class Info extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: props.defaultData.title,
            cowork: props.defaultData.cowork,
            description: props.defaultData.description,
            formErrors: { title: '', description: '' },
            titleIsValid: true,
            descriptionIsValid: true,
            formIsValid: true,
        }
    }


    handleInput(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.props.updateMainState(name, value);
        this.setState({ [name]: value }, () => { this.validateFields(name, value) });
    }

    validateFields(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let titleIsValid = this.state.titleIsValid;
        let descriptionIsValid = this.state.descriptionIsValid;

        switch (fieldName) {
            case 'title':
                titleIsValid = new RegExp(/^[a-zA-Z\u00C0-\u00FF\s*]*$/).test(value) && value.length > 4
                fieldValidationErrors.title = titleIsValid ? '' : 'Nome Invalido'
                break;    
            case 'description':
                descriptionIsValid = value.length > 1;
                fieldValidationErrors.description = descriptionIsValid ? '' : 'Deve escrever uma descrição'
                break;
            default:
                break;
        }


        this.setState({
            formErrors: fieldValidationErrors,
            titleIsValid: titleIsValid,
            descriptionIsValid: descriptionIsValid
        }, this.validateForm)

    }

    validateForm() {
        this.setState({
            formIsValid:
                this.state.titleIsValid &&
                this.state.descriptionIsValid
        }, () => {
            this.props.updateMainState('validForm', this.state.formIsValid);
        })
    }

    render() {
        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            autoFocus
                            error={!this.state.titleIsValid ? true : false}
                            helperText={this.state.formErrors.title}
                            id="title"
                            name="title"
                            label="Titulo da tarefa"
                            fullWidth
                            type="text"
                            defaultValue={this.state.title}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled
                            id="cowork"
                            name="cowork"
                            label="Cowork"
                            fullWidth
                            type="text"
                            defaultValue={this.state.cowork}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            variant='outlined'
                            required
                            multiline
                            rows={7}
                            error={!this.state.descriptionIsValid ? true : false}
                            helperText={this.state.formErrors.description}
                            id="description"
                            name="description"
                            label="Descrição da tarefa"
                            type='text'
                            fullWidth
                            defaultValue={this.state.description}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import axios from 'axios'
import Cookies from 'universal-cookie'
import FormData from 'form-data'

import InfoPT from './add/InfoPT'
import InfoEN from './add/InfoEN'
import ImageUpload from './add/ImageUpload'
import ReviewData from './add/Review'

import ServerResponse from '../ErrorDialog/ServerResponse'

const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
});

const cookies = new Cookies();
const steps = ['Info em PT', 'Info em EN', 'Upload de Images', 'Confirmar']

class AddShopItemDialog extends Component {
    constructor() {
        super();
        this.state = {
            activeStep: 0,
            name_PT: '',
            name_EN: '',
            dimensions: '',
            materials_PT: '',
            materials_EN: '',
            origin: '',
            price: '0.00',
            description_PT: '',
            description_EN: '',
            category: 'mobiliario',
            online: true,
            delivery_time: '0',
            portrait: null,
            sliderImage1: null,
            sliderImage2: null,
            sliderImage3: null,

            validForm: false,
            feedbackMessage: '',
            feedbackSubMessage: ''
        }
    }

    getStepContent(step) {
        switch (step) {
            case 0:
                return <InfoPT defaultData={this.state} updateMainState={this.updateState} />;
            case 1:
                return <InfoEN defaultData={this.state} updateMainState={this.updateState} />;
            case 2:
                return <ImageUpload updateMainState={this.updateState} />;
            case 3:
                return <ReviewData data={this.state} updateMainState={this.updateState} />;
            default:
                throw new Error('Unknown step')
        }
    }

    updateState = (property, value) => {
        this.setState({ [property]: value })
    }

    submitInfoToServer = () => {

        let formData = new FormData();
        formData.set('name_PT', this.state.name_PT);
        formData.set('name_EN', this.state.name_EN);
        formData.set('dimensions', this.state.dimensions);
        formData.set('materials_PT', this.state.materials_PT);
        formData.set('materials_EN', this.state.materials_EN);
        formData.set('origin', this.state.origin);
        formData.set('price', this.state.price);
        formData.set('description_PT', this.state.description_PT);
        formData.set('description_EN', this.state.description_EN);
        formData.set('category', this.state.category);
        formData.set('online', this.state.online);
        formData.set('delivery_time', this.state.delivery_time);
        formData.append('portrait', this.state.portrait[0]);
        formData.append('sliderImage1', this.state.sliderImage1[0]);
        formData.append('sliderImage2', this.state.sliderImage2[0]);
        formData.append('sliderImage3', this.state.sliderImage3[0]);

        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/store_items`, formData, {
            headers: {
                'apikey': cookies.get('key'),
                'accept': 'application/json',
                'Accept-Language': 'en-US,en;q=0.8',
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
            }
        })
            .then((res) => {
                this.setState({ 
                    feedbackMessage: `Item ${this.state.name_PT} adicionado com sucesso.`,
                    feedbackSubMessage: `Mensagem do servidor: ${res.data}` 
                })
                this.handleNext();
            }, (err) => {
                console.log(err);
                this.setState({ 
                    feedbackMessage: `Erro ao adicionar Item, se o erro percistir contacte o administrador do servidor.`,
                    feedbackSubMessage: `Mensage do servidor: ${err.response ? err.response.data : err}`
                })
                this.handleNext();
            });
    }

    handleNext = () => {
        this.state.activeStep === steps.length - 2 ?
            this.setState({
                validForm: true,
                activeStep: this.state.activeStep + 1,
            }) :
            this.setState({
                validForm: false,
                activeStep: this.state.activeStep + 1,
            })

    }
    handleBack = () => {
        this.updateState('validForm', true)
        this.setState({
            activeStep: this.state.activeStep - 1
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
            <CssBaseline />
            <AppBar position="absolute" color="default" className={classes.appBar}>
            </AppBar>
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h4" align="center">
                        Adicionar Novo Item 
                    </Typography>
                    <Stepper activeStep={this.state.activeStep} className={classes.stepper}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <React.Fragment>
                        {this.state.activeStep === steps.length ? (  
                                <ServerResponse message={this.state.feedbackMessage} submessage={this.state.feedbackSubMessage} />
                        ) : (
                            <React.Fragment>
                                {this.getStepContent(this.state.activeStep)}
                                <div className={classes.buttons}>
                                    {this.state.activeStep !== 0 && (
                                        <Button onClick={this.handleBack} className={classes.button}>
                                            Anterior
                                        </Button>
                                    )}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={this.state.activeStep === steps.length - 1 ? this.submitInfoToServer : this.handleNext}
                                        className={classes.button}
                                        disabled={this.state.validForm ? false : true}
                                    >
                                        {this.state.activeStep === steps.length - 1 ? 'Submeter' : 'Seguinte'}
                                    </Button>
                                </div>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                </Paper>
            </main>
        </React.Fragment>
        )
    }
}

export default withStyles(styles)(AddShopItemDialog)

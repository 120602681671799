import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { AppBar, Paper, Stepper, Step, StepLabel, Button, Typography, Grid, TextField } from '@material-ui/core/';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ListItem, ListItemText, List, ListItemIcon } from '@material-ui/core';
import { InputLabel, Select, MenuItem, Divider, InputAdornment, IconButton } from '@material-ui/core';
import { Dialog, DialogContent, DialogActions, Box, Fab } from '@material-ui/core'
import DateFnsUtils from '@date-io/date-fns';
import axios from 'axios'
import Cookies from 'universal-cookie'
import moment from 'moment';
import ContactsIcon from '@material-ui/icons/PermContactCalendar'
import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'
import CircularProgress from '@material-ui/core/CircularProgress';


import ServerResponse from '../ErrorDialog/ServerResponse'

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	layout: {
		width: 'auto',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
			width: 600,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
			marginTop: theme.spacing(6),
			marginBottom: theme.spacing(6),
			padding: theme.spacing(3),
		},
	},
	stepper: {
		padding: theme.spacing(3, 0, 5),
	},
	buttons: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	button: {
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(1),
	},
}));

const useStyles_review = makeStyles((theme) => ({
	listItem: {
		padding: theme.spacing(1, 0),
	},
	total: {
		fontWeight: 700,
	},
	title: {
		marginTop: theme.spacing(2),
	},
}));

const useStyles_clientList = makeStyles(() => ({
	clientList: {
		maxHeight: '350px',
		overflowY: 'scroll'
	},
	clientsCards: {
		margin: '10px 25px 10px 25px'
	}
}))

const steps = ['Editar Info', 'Confirmar Info'];

let globalState = null;

const cookies = new Cookies();

function ReviewData() {
	const classes = useStyles_review();
	const data = globalState;

	return (
		<React.Fragment>
			<Typography variant="h5" gutterBottom>
				Confirme os dados
			</Typography>
			<Divider />
			<List disablePadding>
				<Typography variant="h6">Informação Reserva</Typography>
				<ListItem className={classes.listItem} key={data.cowork}>
					<ListItemText primary="Cowork" />
					<Typography variant="body2">{data.cowork}</Typography>
				</ListItem>
				<ListItem className={classes.listItem} key={data.quantity}>
					<ListItemText primary="Numero de Pessoas" />
					<Typography variant="body2">{data.quantity}</Typography>
				</ListItem>
				<ListItem className={classes.listItem} key={'checkin'}>
					<ListItemText primary="Check In" />
					<Typography variant="body2">{moment(data.checkin).utc().format('YYYY/MM/DD HH:mm')}</Typography>
				</ListItem>
				<ListItem className={classes.listItem} key={'checkout'}>
					<ListItemText primary="Check Out" />
					<Typography variant="body2">{moment(data.checkout).utc().format('YYYY/MM/DD HH:mm')}</Typography>
				</ListItem>
				<Divider />
				<Typography variant="h6">Informação Cliente</Typography>
				<ListItem className={classes.listItem} key={data.client_name}>
					<ListItemText primary="Nome do Cliente" />
					<Typography variant="body2">{data.client_name}</Typography>
				</ListItem>
				<ListItem className={classes.listItem} key={data.client_email}>
					<ListItemText primary="Email do Cliente" />
					<Typography variant="body2">{data.client_email}</Typography>
				</ListItem>
				<ListItem className={classes.listItem} key={data.client_adress}>
					<ListItemText primary="Morada do Cliente" />
					<Typography variant="body2">{data.client_address}</Typography>
				</ListItem>
				<ListItem className={classes.listItem} key={data.client_nif}>
					<ListItemText primary="NIF/Passaporte" />
					<Typography variant="body2">{data.client_nif}</Typography>
				</ListItem>
				<ListItem className={classes.listItem} key={data.client_phone}>
					<ListItemText primary="Nº Telemovel do Cliente" />
					<Typography variant="body2">{data.client_phone}</Typography>
				</ListItem>
			</List>
		</React.Fragment>
	);
}

class FillData extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			cowork: props.data !== undefined ? props.data.cowork : 'Alpedrinha',
			clientsList: props.data !== undefined ? props.data.clientsList : [],
			client_name: '',
			client_email: '',
			client_address: '',
			client_phone: '',
			client_nif: '',
			quantity: '',
			checkin: Date.now(),
			checkout: Date.now(),
			formErrors: { cowork: '', client_name: '', client_email: '', quantity: '', client_phone: '' },
			coworkIsValid: true,
			client_nameIsValid: false,
			client_emailIsValid: false,
			client_phoneIsValid: false,
			quantityIsValid: false,
			formIsValid: false,
			showClientDialog: false
		}
	}

	componentDidMount() {
		globalState = this.state;

		if (this.props.data !== undefined) {
			this.setState({
				checkin: moment(this.props.data.checkIn).minutes(0).add(moment(this.props.data.checkIn).utcOffset(), 'minutes').toISOString(),
				checkout: moment(this.props.data.checkOut).minutes(0).add(moment(this.props.data.checkOut).utcOffset(), 'minutes').toISOString(),
			})
		}

		// this.setState({ cowork: this.props.coworkList[0].title }, () => console.log(this.state))
	}

	handleInput(event) {
		const name = event.target.name;
		const value = event.target.value;
		this.setState({ [name]: value }, () => { this.validateFields(name, value) });
	}

	handleCheckInInput(date) {
		const utcOffset = moment(date).utcOffset()
		const transformedDate = moment(date).minutes(0).add(utcOffset, 'minutes').toISOString()

		this.setState({ checkin: transformedDate }, this.validateForm)
	}

	handleCheckOutInput(date) {
		const utcOffset = moment(date).utcOffset()
		const transformedDate = moment(date).minutes(0).add(utcOffset, 'minutes').toISOString()

		this.setState({ checkout: transformedDate }, this.validateForm)
	}

	fillInDataFromContacts = (data) => {
		this.setState({
			client_name: data.name,
			client_address: data.address,
			client_email: data.email,
			client_phone: data.phone,
			client_nif: data.nif,
			client_nameIsValid: true,
			client_emailIsValid: true,
			client_phoneIsValid: true,
		}, this.validateForm)
	}

	validateFields(fieldName, value) {
		let fieldValidationErrors = this.state.formErrors;
		let coworkIsValid = this.state.coworkIsValid;
		let client_nameIsValid = this.state.client_nameIsValid;
		let client_emailIsValid = this.state.client_emailIsValid;
		let client_phoneIsValid = this.state.client_phoneIsValid;
		let quantityIsValid = this.state.quantityIsValid;

		switch (fieldName) {
			case 'cowork':
				coworkIsValid = new RegExp(/^[a-zA-Z\u00C0-\u00FF\s*]*$/).test(value) && value.length > 4;
				fieldValidationErrors.cowork = coworkIsValid ? '' : 'Nome de Cowork Invalido'
				break;
			case 'client_name':
				client_nameIsValid = new RegExp(/^[a-zA-Z\u00C0-\u00FF]{3,}[\s]{1}[a-zA-Z\u00C0-\u00FF]{1,}$/).test(value) && value.length > 4;
				fieldValidationErrors.client_name = client_nameIsValid ? '' : 'Nome Inválido'
				break;
			case 'client_phone':
				client_phoneIsValid = value.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/) && (value.length >= 9);
				fieldValidationErrors.client_phone = client_phoneIsValid ? '' : 'Nº Telemovel Inválido'
				break;
			case 'client_email':
				client_emailIsValid = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value);
				fieldValidationErrors.client_email = client_emailIsValid ? '' : 'Email inválido'
				break;
			case 'quantity':
				quantityIsValid = value >= 1;
				fieldValidationErrors.quantity = quantityIsValid ? '' : 'Quantidade inválida'
				break;
			default:
				break;
		}


		this.setState({
			formErrors: fieldValidationErrors,
			coworkIsValid: coworkIsValid,
			client_nameIsValid: client_nameIsValid,
			client_emailIsValid: client_emailIsValid,
			client_phoneIsValid: client_phoneIsValid,
			quantityIsValid: quantityIsValid
		}, this.validateForm)

	}

	validateForm() {
		this.setState({
			formIsValid:
				this.state.coworkIsValid &&
				this.state.client_nameIsValid &&
				this.state.client_emailIsValid &&
				this.state.client_phoneIsValid &&
				this.state.quantityIsValid
		}, () => {
			globalState = this.state;
			this.props.setformValid(this.state.formIsValid);
		})
	}

	render() {
		return (
			<React.Fragment>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>
						<InputLabel id="cowork">Cowork</InputLabel>
						<Select
							required
							fullWidth
							labelId="cowork"
							id="cowork"
							name="cowork"
							value={this.state.cowork}
							onChange={(e) => this.handleInput(e)}
							displayEmpty={false}
						>
							{this.props.coworkList.map((cowork) => {
								return <MenuItem key={cowork.id} value={cowork.title} >{cowork.title}</MenuItem>
							})}
						</Select>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							required
							error={!this.state.client_nameIsValid ? true : false}
							helperText={this.state.formErrors.client_name}
							id="client_name"
							name="client_name"
							label="Nome (primeiro e ultimo)"
							fullWidth
							autoComplete="given-name"
							value={this.state.client_name}
							onChange={(e) => this.handleInput(e)}
							InputProps={{
								endAdornment: <InputAdornment position="end">
									<IconButton tabIndex='-1' onClick={() => this.setState({ showClientDialog: true })} >
										<ContactsIcon />
									</IconButton>
								</InputAdornment>,
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<TextField
							id="client_nif"
							name="client_nif"
							label="NIF/Passaporte"
							fullWidth
							value={this.state.client_nif}
							onChange={(e) => this.handleInput(e)}
						/>
					</Grid>
					<Grid item xs={12} sm={9}>
						<TextField
							id="client_address"
							name="client_address"
							label="Morada do cliente"
							fullWidth
							value={this.state.client_address}
							onChange={(e) => this.handleInput(e)}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							required
							error={!this.state.client_emailIsValid ? true : false}
							helperText={this.state.formErrors.client_email}
							id="client_email"
							name="client_email"
							label="Email Cliente"
							fullWidth
							autoComplete="email"
							value={this.state.client_email}
							onChange={(e) => this.handleInput(e)}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							required
							error={!this.state.client_phoneIsValid ? true : false}
							helperText={this.state.formErrors.client_phone}
							id="client_phone"
							name="client_phone"
							label="Nº Telemovel"
							fullWidth
							value={this.state.client_phone}
							onChange={(e) => this.handleInput(e)}
						/>
					</Grid>
					<Grid item xs={12} sm={4}>
						<TextField
							required
							error={!this.state.quantityIsValid ? true : false}
							helperText={this.state.formErrors.quantity}
							id="quantity"
							name="quantity"
							label="Pessoas"
							type='number'

							fullWidth
							value={this.state.quantity}
							onChange={(e) => this.handleInput(e)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDateTimePicker
								variant="inline"
								format="yyyy/MM/dd HH:mm"
								margin="normal"
								name="checkin"
								label="Check In"
								ampm={false}
								minutesStep={0}
								views={["date", "hours"]}
								inputValue={moment(this.state.checkin).utc().format('yyyy/MM/DD HH:mm')}
								onChange={(e, data) => this.handleCheckInInput(data)}
								autoOk
								KeyboardButtonProps={{ 'aria-label': 'change date' }}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
					<Grid item xs={12} sm={6}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDateTimePicker
								variant="inline"
								format="yyyy/MM/dd HH:mm"
								margin="normal"
								name="checkout"
								label="Check Out"
								ampm={false}
								minutesStep={0}
								views={["date", "hours"]}
								inputValue={moment(this.state.checkout).utc().format('yyyy/MM/DD HH:mm')}
								onChange={(e) => this.handleCheckOutInput(e)}
								autoOk
								KeyboardButtonProps={{ 'aria-label': 'change date' }}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
				</Grid>
				<ClientsListDialog
					open={this.state.showClientDialog}
					close={() => this.setState({ showClientDialog: false })}
					clientList={this.props.clientList}
					fillData={this.fillInDataFromContacts}
				/>
			</React.Fragment>
		);
	}
}


export default function AddReservationDialog(props) {
	const classes = useStyles();
	const [activeStep, setActiveStep] = React.useState(0);
	const [validForm, setValidForm] = React.useState(false);
	const [feedbackMessage, setFeedbackMessage] = React.useState('');
	const [feedbackSubMessage, setFeedbackSubMessage] = React.useState('');
	const [submitting, setSubmitting] = React.useState(false)

	const [coworkList, setCoworkList] = React.useState([])
	const [clientsList, setClientsList] = React.useState([])

	useEffect(() => {
		async function fetchCoworks() {
			await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/coworks`, { headers: { 'apikey': cookies.get('key') } })
				.then((res) => {
					setCoworkList(res.data);
				}, (err) => {
					console.log(err)
				});
		}

		async function fetchClients() {
			await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/clients`, { headers: { 'apikey': cookies.get('key') } })
				.then((res) => {
					setClientsList(res.data);
				}, (err) => {
					console.log(err)
				});
		}

		fetchCoworks();
		fetchClients();
	}, [])


	function getStepContent(step) {
		switch (step) {
			case 0:
				return <FillData clientList={clientsList} coworkList={coworkList} data={props.data} setformValid={getFormInfo} />;
			case 1:
				return <ReviewData />;
			default:
				throw new Error('Unknown step');
		}
	}

	const getFormInfo = (info) => {
		setValidForm(info)
	}

	const submitInfoToServer = () => {
		setSubmitting(true)

		axios.post(`${process.env.REACT_APP_SERVER_URL}/api/reservations`, {
			cowork: globalState.cowork,
			client_name: globalState.client_name,
			client_email: globalState.client_email,
			client_address: globalState.client_address,
			client_phone: globalState.client_phone,
			client_nif: globalState.client_nif,
			dates: [{ people: globalState.quantity, checkIn: globalState.checkin, checkOut: globalState.checkout }]
		}, { headers: { 'apikey': cookies.get('key') } })
			.then((res) => {
				setFeedbackMessage('Reserva adicionada com successo')
				setFeedbackSubMessage(`Mensagem do servidor: ${res.data}`);
				setActiveStep(activeStep + 1);
				setSubmitting(false)
			}, (err) => {
				console.log(err)
				setFeedbackMessage(`Não foi possivel criar reserva, se o problema percistir contacte o administrador do servidor, ${err}`)
				setFeedbackSubMessage(`Mensage do servidor: ${err.response ? err.response.data : err}`);
				setActiveStep(activeStep + 1);
				setSubmitting(false)
			});
	}

	const handleNext = () => {
		setActiveStep(activeStep + 1);
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
		setValidForm(false)
	};

	return (
		<React.Fragment>
			<CssBaseline />
			<AppBar position="absolute" color="default" className={classes.appBar}>
			</AppBar>
			<main className={classes.layout}>
				<Paper className={classes.paper}>
					<Typography component="h1" variant="h4" align="center">
						Adicionar Nova Reserva
					</Typography>
					<Stepper activeStep={activeStep} className={classes.stepper}>
						{steps.map((label) => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
					<React.Fragment>
						{activeStep === steps.length ? (
							<ServerResponse message={feedbackMessage} submessage={feedbackSubMessage} />
						) : (
							<React.Fragment>
								{getStepContent(activeStep)}
								<div className={classes.buttons}>
									{activeStep !== 0 && (
										<Button onClick={handleBack} className={classes.button}>
											Anterior
										</Button>
									)}
									{submitting ? <CircularProgress /> :
										<Button
											variant="contained"
											color="primary"
											onClick={activeStep === steps.length - 1 ? submitInfoToServer : handleNext}
											className={classes.button}
											disabled={validForm ? false : true}
										>
											{activeStep === steps.length - 1 ? 'Submeter' : 'Seguinte'}
										</Button>
									}
								</div>
							</React.Fragment>
						)}
					</React.Fragment>
				</Paper>
			</main>
		</React.Fragment>
	);
}

const ClientsListDialog = (props) => {
	const classes = useStyles_clientList();

	const [data, setData] = React.useState([])

	React.useEffect(() => {
		setData(props.clientList);
	}, [props.clientList, props.open])

	const filterData = (string) => {
		if (!string) setData(props.clientList)
		setData(props.clientList.filter(client => client.name.toLowerCase().includes(string.toLowerCase())))
	}

	return (
		<Dialog
			fullWidth={true}
			maxWidth='sm'
			open={props.open}
			onClose={props.close}
			aria-labelledby="max-width-dialog-title"
		>
			<DialogContent>
				<div className={classes.searchDiv}>
					<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
						<SearchIcon style={{ marginRight: '10px' }} />
						<TextField
							onChange={(e) => filterData(e.target.value)}
							id="input-with-sx"
							label="Procurar Cliente"
							variant="standard"
						/>
					</Box>
				</div>
				<Divider style={{ margin: '20px' }} />
				<div className={classes.clientList}>
					{data.map(client => {
						return (
							<Paper elevation={3} key={client.id} className={classes.clientsCards}>
								<ListItem >
									<ListItemText primary={client.name} secondary={client.email} />
									<ListItemIcon>
										<Fab color="primary" size='small' aria-label="add" onClick={() => { props.fillData(client); props.close() }}>
											<AddIcon />
										</Fab>
									</ListItemIcon>
								</ListItem>
							</Paper>
						)
					})}
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.close} color="primary">
					Fechar
				</Button>
			</DialogActions>
		</Dialog>
	)
}
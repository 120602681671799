import React, { Component } from 'react'
import { withStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { DropzoneArea } from 'material-ui-dropzone';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import { Tooltip } from '@material-ui/core'

const styles = () => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        padding: '15px',
        marginBottom: '20px'

    },
    dropzone: {
        width: '300px'
    },
    dropzoneFull: {
        width: '550px'
    },
    title: {
        textAlign: 'center'
    },
});

const theme = createTheme({
    overrides: {
        root: {
            display: 'flex',
        },
        MuiDropzonePreviewList: {
            image: {
                height: '200px'
            }
        },
        MuiGrid: {
            "spacing-xs-8": {
                width: '100%',
                margin: 0,
            },
            "grid-xs-4": {
                maxWidth: 'none',
                flexBasis: '100%'
            },
            item: { padding: '5px !important' }
        },
        /*         MuiDropzoneArea: {
                    texcontainer: {
                        display: 'none'
                    }
                } */
    }
})

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        fontSize: '13px',
        maxWidth: 250
    }
}))(Tooltip)

class ImageUpload extends Component {

    componentDidMount() {
        this.props.updateMainState('validForm', true)
    }

    handleChange(type, file) {
        if (file.length > 0) {
            this.props.updateMainState(type, file);

        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Paper className={classes.paper} elevation={4} >
                    <CustomTooltip title="Tamanho recomendado 1280x720(16:9), Tamanho maximo 2mb" placement="top">
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography className={classes.title} variant="h5" gutterBottom>Image de retrato</Typography>
                            <Typography className={classes.title} variant="h6" gutterBottom>Arraste a imagem ou Clique em baixo</Typography>
                            <MuiThemeProvider theme={theme}>
                                <DropzoneArea
                                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                                    dropzoneText={''}
                                    maxFileSize={2000000}
                                    filesLimit={1}
                                    getFileLimitExceedMessage={() => 'Numero Maximo de ficheiros excedido. Apenas 1 ficheiro é permitido'}
                                    getFileAddedMessage={() => 'Imagem adicionada com sucesso'}
                                    getFileRemovedMessage={() => 'Imagem removida com sucesso'}
                                    getDropRejectMessage={() => 'Ficheiro rejeitado. Apenas imagens são permitidas. Mais propriamente JPEG, PNG, BMP'}
                                    dropzoneClass={classes.dropzoneFull}
                                    onChange={(file) => this.handleChange('portrait', file)}
                                />
                            </MuiThemeProvider>
                        </div>
                    </CustomTooltip>
                </Paper>

                <Paper className={classes.paper} elevation={4} >
                    <CustomTooltip title="Tamanho recomendado 1280x1280(1:1), Tamanho maximo 2mb" placement="top">
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography className={classes.title} variant="h5" gutterBottom>Image de Slider 1</Typography>
                            <Typography className={classes.title} variant="h6" gutterBottom>Arraste a imagem ou Clique em baixo</Typography>
                            <MuiThemeProvider theme={theme}>
                                <DropzoneArea
                                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                                    dropzoneText={''}
                                    maxFileSize={2000000}
                                    filesLimit={1}
                                    getFileLimitExceedMessage={() => 'Numero Maximo de ficheiros excedido. Apenas 1 ficheiro é permitido'}
                                    getFileAddedMessage={() => 'Imagem adicionada com sucesso'}
                                    getFileRemovedMessage={() => 'Imagem removida com sucesso'}
                                    getDropRejectMessage={() => 'Ficheiro rejeitado. Apenas imagens são permitidas. Mais propriamente JPEG, PNG, BMP'}
                                    dropzoneClass={classes.dropzone}
                                    onChange={(file) => this.handleChange('sliderImage1', file)}
                                />
                            </MuiThemeProvider>
                        </div>
                    </CustomTooltip>
                </Paper>
                <Paper className={classes.paper} elevation={4} >
                    <CustomTooltip title="Tamanho recomendado 1280x1280(1:1), Tamanho maximo 2mb" placement="top">
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography className={classes.title} variant="h5" gutterBottom>Image de Slider 2</Typography>
                            <Typography className={classes.title} variant="h6" gutterBottom>Arraste a imagem ou Clique em baixo</Typography>
                            <MuiThemeProvider theme={theme}>
                                <DropzoneArea
                                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                                    dropzoneText={''}
                                    maxFileSize={2000000}
                                    filesLimit={1}
                                    getFileLimitExceedMessage={() => 'Numero Maximo de ficheiros excedido. Apenas 1 ficheiro é permitido'}
                                    getFileAddedMessage={() => 'Imagem adicionada com sucesso'}
                                    getFileRemovedMessage={() => 'Imagem removida com sucesso'}
                                    getDropRejectMessage={() => 'Ficheiro rejeitado. Apenas imagens são permitidas. Mais propriamente JPEG, PNG, BMP'}
                                    dropzoneClass={classes.dropzone}
                                    onChange={(file) => this.handleChange('sliderImage2', file)}
                                />
                            </MuiThemeProvider>
                        </div>
                    </CustomTooltip>
                </Paper>

                <Paper className={classes.paper} elevation={4} >
                    <CustomTooltip title="Tamanho recomendado 1280x1280(1:1), Tamanho maximo 2mb" placement="top">
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography className={classes.title} variant="h5" gutterBottom>Image de Slider 3</Typography>
                            <Typography className={classes.title} variant="h6" gutterBottom>Arraste a imagem ou Clique em baixo</Typography>
                            <MuiThemeProvider theme={theme}>
                                <DropzoneArea
                                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                                    dropzoneText={''}
                                    maxFileSize={2000000}
                                    filesLimit={1}
                                    getFileLimitExceedMessage={() => 'Numero Maximo de ficheiros excedido. Apenas 1 ficheiro é permitido'}
                                    getFileAddedMessage={() => 'Imagem adicionada com sucesso'}
                                    getFileRemovedMessage={() => 'Imagem removida com sucesso'}
                                    getDropRejectMessage={() => 'Ficheiro rejeitado. Apenas imagens são permitidas. Mais propriamente JPEG, PNG, BMP'}
                                    dropzoneClass={classes.dropzone}
                                    onChange={(file) => this.handleChange('sliderImage3', file)}
                                />
                            </MuiThemeProvider>
                        </div>
                    </CustomTooltip>
                </Paper>

                <Paper className={classes.paper} elevation={4} >
                    <CustomTooltip title="Tamanho recomendado 1280x1280(1:1), Tamanho maximo 2mb" placement="top">
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography className={classes.title} variant="h5" gutterBottom>Planta da Area</Typography>
                            <Typography className={classes.title} variant="h6" gutterBottom>Arraste a imagem ou Clique em baixo</Typography>
                            <MuiThemeProvider theme={theme}>
                                <DropzoneArea
                                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                                    dropzoneText={''}
                                    maxFileSize={2000000}
                                    filesLimit={1}
                                    getFileLimitExceedMessage={() => 'Numero Maximo de ficheiros excedido. Apenas 1 ficheiro é permitido'}
                                    getFileAddedMessage={() => 'Imagem adicionada com sucesso'}
                                    getFileRemovedMessage={() => 'Imagem removida com sucesso'}
                                    getDropRejectMessage={() => 'Ficheiro rejeitado. Apenas imagens são permitidas. Mais propriamente JPEG, PNG, BMP'}
                                    dropzoneClass={classes.dropzone}
                                    onChange={(file) => this.handleChange('blueprint', file)}
                                />
                            </MuiThemeProvider>
                        </div>
                    </CustomTooltip>
                </Paper>
            </div >
        )
    }
}

export default withStyles(styles)(ImageUpload)


import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import moment from 'moment';

import { CirclePicker } from 'react-color'
import { InputLabel, Select, MenuItem, TextField } from '@material-ui/core';


export default class Dates extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: props.defaultData.title,
            start: props.defaultData.start,
            end: props.defaultData.end,
            frequency: props.defaultData.frequency,
            interval: props.defaultData.interval,
            until: props.defaultData.until,
            repeatType: props.defaultData.repeatType,
            color: props.defaultData.color,
            formErrors: { interval: '', until: '' },
            intervalIsValid: true,
            untilIsValid: true,
            formIsValid: true,
        }
    }

    componentDidMount() {
        this.validateFields()
    }

    setRespectiveUntil = () => {
        switch (this.state.frequency) {
            case 'DAILY': this.setState({ until: 31 }, () => this.props.updateMainState('until', 31))
                break;
            case 'WEEKLY': this.setState({ until: 52 }, () => this.props.updateMainState('until', 52))
                break;
            case 'MONTHLY': this.setState({ until: 12 }, () => this.props.updateMainState('until', 12))
                break;
            case 'YEARLY': this.setState({ until: 5 }, () => this.props.updateMainState('until', 5))
                break;
            default: return;
        }
    }

    handleStartDateInput(date) {
        const utcOffset = moment(date).utcOffset()
		const transformedDate = moment(date).minutes(0).add(utcOffset, 'minutes').toISOString()

        this.props.updateMainState('start', transformedDate);
        this.setState({ start: transformedDate }, this.validateFields)
    }

    handleEndDateInput(date) {
        const utcOffset = moment(date).utcOffset()
		const transformedDate = moment(date).minutes(0).add(utcOffset, 'minutes').toISOString()

        this.props.updateMainState('end', transformedDate);
        this.setState({ end: transformedDate }, this.validateFields)
    }

    handleColorInput(color, event) {
        this.props.updateMainState('color', color.hex)
        this.setState({ color: color.hex }, this.validateFields)
    }

    handleInput(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.props.updateMainState(name, value);
        this.setState({ [name]: value }, () => { this.validateFields(name, value) });
    }

    validateFields(fieldName, value) {
        let intervalIsValid = this.state.intervalIsValid;
        let fieldValidationErrors = this.state.formErrors;
        let untilIsValid = this.state.untilIsValid;

        switch (fieldName) {
            case 'interval':
                intervalIsValid = value >= 1;
                fieldValidationErrors.interval = intervalIsValid ? '' : 'Intervalo Inválido'
                break;
            case 'until': 
                untilIsValid = value >= 1;
                fieldValidationErrors.until = untilIsValid ? '' : 'Inválido'
            break;
            case 'frequency': this.setRespectiveUntil();
                break;
            default: ;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            intervalIsValid: intervalIsValid,
            untilIsValid: untilIsValid
        }, this.validateForm)
    }

    validateForm() {
        this.setState({
            formIsValid:
                this.state.intervalIsValid &&
                this.state.untilIsValid
        }, () => {
            this.props.updateMainState('validForm', this.state.formIsValid);
        })
    }

    render() {
        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                                variant="inline"
                                format="yyyy/MM/dd HH:mm"
                                margin="normal"
                                name="start"
                                label="Data Inicio"
                                ampm={false}
                                minutesStep={0}
								views={["date", "hours"]}
                                inputValue={moment(this.state.start).utc().format('yyyy/MM/DD HH:mm')}
                                onChange={(e) => this.handleStartDateInput(e)}
                                autoOk
                                KeyboardButtonProps={{ 'aria-label': 'change date' }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                                variant="inline"
                                format="yyyy/MM/dd HH:mm"
                                margin="normal"
                                name="end"
                                label="Data Fim"
                                ampm={false}
                                minutesStep={0}
								views={["date", "hours"]}
                                inputValue={moment(this.state.end).utc().format('yyyy/MM/DD HH:mm')}
                                onChange={(e) => this.handleEndDateInput(e)}
                                autoOk
                                KeyboardButtonProps={{ 'aria-label': 'change date' }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <InputLabel id="frequency">Repetir</InputLabel>
                        <Select
                            required
                            fullWidth
                            labelId="frequency"
                            id="frequency"
                            name="frequency"
                            value={this.state.frequency}
                            onChange={(e) => this.handleInput(e)}
                            displayEmpty
                        >
                            <MenuItem value='NEVER'>Nunca</MenuItem>
                            <MenuItem value='DAILY'>Diário</MenuItem>
                            <MenuItem value='WEEKLY'>Semanal</MenuItem>
                            <MenuItem value='MONTHLY'>Mensal</MenuItem>
                            <MenuItem value='YEARLY'>Anual</MenuItem>
                        </Select>
                    </Grid>
                    {this.state.frequency !== 'NEVER' && (
                        <>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    error={!this.state.intervalIsValid ? true : false}
                                    helperText={this.state.formErrors.interval}
                                    id="interval"
                                    name="interval"
                                    label="Intervalo"
                                    type='number'
                                    fullWidth
                                    defaultValue={this.state.interval}
                                    onChange={(e) => this.handleInput(e)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    error={!this.state.untilIsValid ? true : false}
                                    helperText={this.state.formErrors.until}
                                    id="until"
                                    name="until"
                                    label="Nº Vezes"
                                    type='number'
                                    fullWidth
                                    value={this.state.until}
                                    onChange={(e) => this.handleInput(e)}
                                />
                            </Grid>
                            {this.state.frequency === 'MONTHLY' && (
                                <Grid item xs={12} sm={4}>
                                    <InputLabel id="repeatType">Tipo de Repetição</InputLabel>
                                    <Select
                                        required
                                        fullWidth
                                        labelId="repeatType"
                                        id="repeatType"
                                        name="repeatType"
                                        value={this.state.repeatType}
                                        onChange={(e) => this.handleInput(e)}
                                    >
                                        <MenuItem value='day'>Por dia</MenuItem>
                                        <MenuItem value='weekday'>Por dia da semana</MenuItem>
                                    </Select>
                                </Grid>
                            )}
                        </>
                    )}
                    <Grid item xs={12} sm={12}>
                        <InputLabel style={{ marginBottom: '15px' }} id="frequency">Cor da tarefa</InputLabel>
                        <div style={{
                            borderRadius: '20px',
                            height: '25px',
                            width: '80%',
                            backgroundColor: this.state.color,
                            marginBottom: '15px',
                            color: 'white',
                            lineHeight: '25px',
                            textIndent: '10px',
                            fontSize: '16px'
                        }}>{this.state.title}</div>
                        <CirclePicker
                            width='100%'
                            onChangeComplete={(color, event) => this.handleColorInput(color, event)}
                            colors={["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#00bcd4", "#009688", "#4caf50", "#ffc107", "#ff9800", "#795548", "#607d8b"]}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}
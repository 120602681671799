import * as React from 'react';

import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'


const TableHeaderCell = withStyles(theme => ({
  root: {
    fontWeight: 'bold',
    fontSize: '16px',
    textAlign: 'center'
  }
}))(TableCell)

const TableBodyCell = withStyles(theme => ({
  root: {
    textAlign: 'center',
    padding: '10px 14px 10px 14px'
  }
}))(TableCell)


export default function ReservationsDashboard(props) {

  if (props.data === undefined) {
    return '';
  }

  props.data.map(item => {
    item.checkIn = moment(item.checkIn).format('YYYY/MM/DD HH:mm');
    item.checkOut = moment(item.checkOut).format('YYYY/MM/DD HH:mm')
    return '';
  })

  const rows = props.data;

  return (
    <React.Fragment>
      <Typography variant="h6" component="h2">
        Reservas Recentes
      </Typography>
      <Table style={{ marginBottom: '20px' }} size="small">
        <TableHead>
          <TableRow>
            <TableHeaderCell>Cowork</TableHeaderCell>
            <TableHeaderCell>Nome Cliente</TableHeaderCell>
            <TableHeaderCell>Pessoas</TableHeaderCell>
            <TableHeaderCell>Check In</TableHeaderCell>
            <TableHeaderCell>Check Out</TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableBodyCell>{row.cowork}</TableBodyCell>
              <TableBodyCell>{row.client_name}</TableBodyCell>
              <TableBodyCell>{row.quantity}</TableBodyCell>
              <TableBodyCell>{row.checkIn}</TableBodyCell>
              <TableBodyCell>{row.checkOut}</TableBodyCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Link to="/app/reservations" style={{ paddingTop: '25px', color: 'blue' }} >
        Ver mais reservas
      </Link>
    </React.Fragment>
  );
}
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import axios from 'axios'
import Cookies from 'universal-cookie'

import Info from './add/Info'
import Dates from './add/Dates'
import ReviewData from './add/Review'
import ServerResponse from '../ErrorDialog/ServerResponse'

const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
});

const cookies = new Cookies();
const steps = ['Info', 'Datas', 'Confirmar']

class AddOtherEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            title: '',
            cowork: props.data.cowork,
            start: props.data.start,
            end: props.data.end,
            description: '',
            color: '#F44E3B',
            frequency: 'NEVER',
            interval: 1,
            repeatType: 'day',
            until: 1,
            validForm: false,
            feedbackMessage: '',
            feedbackSubMessage: ''
        }
    }

    getStepContent(step) {
        switch (step) {
            case 0:
                return <Info defaultData={this.state} updateMainState={this.updateState} />;
            case 1:
                return <Dates defaultData={this.state} updateMainState={this.updateState} />;
            case 2:
                return <ReviewData data={this.state} updateMainState={this.updateState} />;
            default:
                throw new Error('Unknown step')
        }
    }

    updateState = (property, value) => {
        this.setState({ [property]: value })
    }

    submitInfoToServer = () => {

        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/calendar_events/`, {
            title: this.state.title,
            cowork: this.state.cowork,
            description: this.state.description,
            checkIn: this.state.start,
            checkOut: this.state.end,
            color: this.state.color,
            frequency: this.state.frequency,
            interval: this.state.interval,
            until: this.state.until,
            repeatType: this.state.repeatType
        } , {
            headers: {
                'apikey': cookies.get('key'),
            }
        })
            .then((res) => {
                this.setState({
                    feedbackMessage: `Tarefa ${this.state.title} adicionado com sucesso.`,
                    feedbackSubMessage: `Mensagem do servidor: ${res.data}`
                })
                this.handleNext();
            }, (err) => {
                console.log(err);
                this.setState({
                    feedbackMessage: `Erro ao adicionar tarefa, se o erro percistir contacte o administrador do servidor.`,
                    feedbackSubMessage: `Mensage do servidor: ${err.response ? err.response.data : err}`
                })
                this.handleNext();
            });
    }

    handleNext = () => {
        this.state.activeStep === steps.length - 2 ?
            this.setState({
                validForm: true,
                activeStep: this.state.activeStep + 1,
            }) :
            this.setState({
                validForm: false,
                activeStep: this.state.activeStep + 1,
            })

    }
    handleBack = () => {
        this.setState({
            validForm: true,
            activeStep: this.state.activeStep - 1
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <CssBaseline />
                <AppBar position="absolute" color="default" className={classes.appBar}>
                </AppBar>
                <main className={classes.layout}>
                    <Paper className={classes.paper}>
                        <Typography component="h1" variant="h4" align="center">
                            Adicionar Nova Tarefa
                        </Typography>
                        <Stepper activeStep={this.state.activeStep} className={classes.stepper}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <React.Fragment>
                            {this.state.activeStep === steps.length ? (
                                <ServerResponse message={this.state.feedbackMessage} submessage={this.state.feedbackSubMessage} />
                            ) : (
                                <React.Fragment>
                                    {this.getStepContent(this.state.activeStep)}
                                    <div className={classes.buttons}>
                                        {this.state.activeStep !== 0 && (
                                            <Button onClick={this.handleBack} className={classes.button}>
                                                Anterior
                                            </Button>
                                        )}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.state.activeStep === steps.length - 1 ? this.submitInfoToServer : this.handleNext}
                                            className={classes.button}
                                            disabled={this.state.validForm ? false : true}
                                        >
                                            {this.state.activeStep === steps.length - 1 ? 'Submeter' : 'Seguinte'}
                                        </Button>
                                    </div>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    </Paper>
                </main>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(AddOtherEvent)

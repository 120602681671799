import React, { useState, useEffect } from 'react'
import Cookies from 'universal-cookie'
import { Redirect } from 'react-router-dom';
import axios from 'axios'
import { Link } from 'react-router-dom';

import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import { IconButton } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import PerfilIcon from '@material-ui/icons/PersonOutline';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/core/styles';

import { useIdleTimer } from 'react-idle-timer'

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));


function TopBar() {

    const cookies = new Cookies();
    const [logout, setLogout] = useState(false);

    const stringToColor = (string) => {

        if (string === undefined) string = 'No User'

        let hash = 0;
        let i;

        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.substr(-2);
        }

        return color

    }

    const getFirstLetterFromFirstAndLastName = (name) => {

        if (name === undefined) name = 'No User'

        let firstLetterFirstName = '';
        let firstLetterLastName = '';

        if (name.split(' ')[1] === undefined) {
            firstLetterFirstName = name[0];
            firstLetterLastName = name[1];
        } else {
            firstLetterFirstName = name.split(' ')[0][0];
            firstLetterLastName = name.split(' ')[1][0];
        }

        return `${firstLetterFirstName}${firstLetterLastName}`
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {

        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/ping`, { userID: cookies.get('userID'), time: new Date() })

        let pingTimer = setInterval(async () => {
            await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/ping`, { userID: cookies.get('userID'), time: new Date() })
        }, 240000)

        return () => {
            clearInterval(pingTimer);
        }
    }, [])


    const handleLogout = () => {

        axios.post(`${process.env.REACT_APP_SERVER_URL}/user/logout`, {
            userID: cookies.get('userID')
        }).then((res) => {
            setAnchorEl(null);
            setLogout(true);
        }, (err) => {
            setAnchorEl(null);
        });
    }

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * 10,
        onIdle: handleLogout,

        debounce: 1500,
    })


    return (

        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} style={{ backgroundImage: 'linear-gradient(to right, rgba(63,81,181,1), rgba(28,38,96,1))' }}>
            <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }} >
                <Typography variant="h6" noWrap component="div">
                    Bem vindo, {cookies.get('user')}
                </Typography>
                <div>
                    <IconButton color="inherit" id="userOptions" onClick={handleClick}>
                        <Avatar style={{ backgroundColor: stringToColor(cookies.get('user')) }}>{getFirstLetterFromFirstAndLastName(cookies.get('user'))}</Avatar>
                    </IconButton>
                    <StyledMenu
                        id="userOptions"
                        MenuListProps={{
                            'aria-labelledby': 'fade-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                    >
                        <Link to="/app/profile" style={{ textDecoration: 'none', color: "black" }}>
                            <MenuItem onClick={handleClose}>
                                <ListItemIcon >
                                    <PerfilIcon fontSize="medium" />
                                </ListItemIcon>
                                Perfil
                            </MenuItem>
                        </Link>
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon>
                                <LogoutIcon fontSize="medium" />
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    </StyledMenu>
                </div>
            </Toolbar>
            {logout && (<Redirect to="/" />)}
        </AppBar>

    )
}

export default TopBar

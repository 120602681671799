import React, { useState, useEffect } from 'react'
import axios from 'axios'
import moment from 'moment'

import MUIDataTable from 'mui-datatables'
import Cookies from 'universal-cookie'

import sortArray from 'sort-array'

import { FormGroup, FormLabel, TextField, ListItem, ListItemAvatar, Avatar, ListItemText, Divider, List } from '@material-ui/core';
import { createTheme, MuiThemeProvider, makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContentText, DialogContent, DialogTitle, Card, CssBaseline, CardHeader, CardContent } from '@material-ui/core';


import SeatsIcon from '@material-ui/icons/EventSeat'
import PersonIcon from '@material-ui/icons/Person'
import EmailIcon from '@material-ui/icons/Email'
import CoworkIcon from '@material-ui/icons/House'
import CheckOutIcon from '@material-ui/icons/Alarm'
import AddedIcon from '@material-ui/icons/ControlPoint'
import ChangedIcon from '@material-ui/icons/RotateLeft'
import FolderShared from '@material-ui/icons/FolderShared'
import VpnKey from '@material-ui/icons/VpnKey'

import FeedBackDialog from '../ErrorDialog/FeedBackDialog';
import EditReservationMain from './EditReservationMain'

const cookies = new Cookies();

const getMuiTheme = () => createTheme({
    overrides: {
        MUIDataTableBodyCell: {
            root: {
                textAlign: 'center'
            }
        },
        MUIDataTableHeadCell: {
            root: {
                textAlign: 'center',
                paddingLeft: '30px'
            },
            contentWrapper: {
                display: 'unset'
            }
        },
        MuiButton: {
            root: {
                padding: 0
            },
            text: {
                padding: 0
            }
        }
    }
})

const useStyles = makeStyles((theme) => ({
    outterDiv: {
        height: '100%'
    },
    calendar: {
        backgroundColor: 'white',
        height: '92%',
    },
    selection: {
        height: '8%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    navButtons: {
        letterSpacing: 0,
        border: '1px solid rgba(0, 0, 0, 0.2)',
        boxShadow: 'inset 0px 3px 5px rgba(0, 0, 0, 0.13)'

    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    card: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '20px',
        width: '100%'

    },
    header: {
        width: '100%',
        height: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    contentCard: {
        overflowWrap: 'anywhere',
        marginBottom: '0px',
        width: '100%'
    },
    listRow: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center'
    },
    listitems: {
        display: 'flex',
        width: '50%'
    },
    avatarList: {
        display: 'flex',
        alignItems: 'center',
    },
    cardActions: {
        justifyContent: 'space-between',
        padding: '8px 20px 8px 20px'
    },
    calendarAvatar: {
        backgroundColor: 'rgb(187,222,251)',
        color: 'rgb(30,136,229)'
    }
}));

const stringToColor = (string) => {

    if (string === undefined) string = 'No User'

    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    return color
}

export default function ReservationTable(props) {

    const [selectedRowData, setSelectedRowData] = useState(null);
    const [data, setData] = useState([]);
    const [showInfoDialog, setShowInfoDialog] = useState(false)

    useEffect(() => {
        setData(sortArray(props.data, { by: 'id', order: 'desc' }))
    }, [props.data])

    useEffect(() => {
        if (selectedRowData) setShowInfoDialog(true)
    }, [selectedRowData])

    const columns = [
        {
            name: 'id',
            label: 'ID',
            options: { filter: false }
        },
        {
            name: 'cowork',
            label: 'Cowork',
            options: { filter: true }
        },
        {
            name: 'client_name',
            label: 'Nome Cliente',
            options: { filter: false }
        },
        {
            name: 'client_email',
            label: 'Email Cliente',
            options: { filter: false }
        },
        {
            name: 'quantity',
            label: 'Pessoas',
            options: { searchable: false, filterType: 'textField' }
        },
        {
            name: 'checkIn',
            label: 'Check In',
            options: {
                filter: true,
                filterType: 'custom',
                searchable: false,
                customFilterListOptions: {
                    render: v => {
                        if (v[0] && v[1]) {
                            return [`Data Inicio: ${v[0]}`, `Data Fim: ${v[1]}`];
                        } else if (v[0]) {
                            return `Data Inicio: ${v[0]}`;
                        } else if (v[1]) {
                            return `Data Fim: ${v[1]}`;
                        }
                        return [];
                    },
                    update: (filterList, filterPos, index) => {
                        if (filterPos === 0) {
                            filterList[index].splice(filterPos, 1, '');
                        } else if (filterPos === 1) {
                            filterList[index].splice(filterPos, 1);
                        } else if (filterPos === -1) {
                            filterList[index] = [];
                        }

                        return filterList;
                    },
                },
                filterOptions: {
                    names: [],
                    logic(date, filters) {
                        if (filters[0] && filters[1]) {
                            return date < filters[0] || date > filters[1];
                        } else if (filters[0]) {
                            return date < filters[0];
                        } else if (filters[1]) {
                            return date > filters[1];
                        }
                        return false;
                    },
                    display: (filterList, onChange, index, column) => (
                        <div>
                            <FormLabel>Check In</FormLabel>
                            <FormGroup row>
                                <TextField
                                    label='Data inicio'
                                    value={filterList[index][0] || ''}
                                    onChange={event => {
                                        filterList[index][0] = event.target.value;
                                        onChange(filterList[index], index, column)
                                    }}
                                    style={{ width: '45%', marginRight: '5%' }}
                                />
                                <TextField
                                    label='Data Fim'
                                    value={filterList[index][1] || ''}
                                    onChange={event => {
                                        filterList[index][1] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}
                                    style={{ width: '45%' }}
                                />
                            </FormGroup>
                        </div>
                    ),
                },
            },
        },
        {
            name: 'checkOut',
            label: 'Check Out',
            options: {
                filter: true,
                filterType: 'custom',
                searchable: false,
                customFilterListOptions: {
                    render: v => {
                        if (v[0] && v[1]) {
                            return [`Data Inicio: ${v[0]}`, `Data Fim: ${v[1]}`];
                        } else if (v[0]) {
                            return `Data Inicio: ${v[0]}`;
                        } else if (v[1]) {
                            return `Data Fim: ${v[1]}`;
                        }
                        return [];
                    },
                    update: (filterList, filterPos, index) => {
                        if (filterPos === 0) {
                            filterList[index].splice(filterPos, 1, '');
                        } else if (filterPos === 1) {
                            filterList[index].splice(filterPos, 1);
                        } else if (filterPos === -1) {
                            filterList[index] = [];
                        }

                        return filterList;
                    },
                },
                filterOptions: {
                    names: [],
                    logic(date, filters) {
                        if (filters[0] && filters[1]) {
                            return date < filters[0] || date > filters[1];
                        } else if (filters[0]) {
                            return date < filters[0];
                        } else if (filters[1]) {
                            return date > filters[1];
                        }
                        return false;
                    },
                    display: (filterList, onChange, index, column) => (
                        <div>
                            <FormLabel>Check Out</FormLabel>
                            <FormGroup row>
                                <TextField
                                    label='Data inicio'
                                    value={filterList[index][0] || ''}
                                    onChange={event => {
                                        filterList[index][0] = event.target.value;
                                        onChange(filterList[index], index, column)
                                    }}
                                    style={{ width: '45%', marginRight: '5%' }}
                                />
                                <TextField
                                    label='Data Fim'
                                    value={filterList[index][1] || ''}
                                    onChange={event => {
                                        filterList[index][1] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}
                                    style={{ width: '45%' }}
                                />
                            </FormGroup>
                        </div>
                    ),
                },
            },
        },
        {
            name: 'lock_key',
            label: 'Código',
            options: { searchable: true, filter: false, customBodyRender: (value) => <p>{`${value ? value : 'N/A'}`}</p>, }
        },
        {
            name: 'share_info',
            label: 'Partilha Dados',
            options: { searchable: false, filter: false, customBodyRender: (value) => <p>{`${value ? 'Sim' : 'Não'}`}</p>, }
        },
    ]

    const options = {
        filter: true,
        filterType: 'multiselect',
        responsive: 'standard',
        tableBodyHeight: '450px',
        tableBodyMaxHeigth: '',
        selectableRows: 'none',
        rowsPerPageOptions: [10, 25, 50, 100],
        onRowClick: item => setSelectedRowData({
            id: item[0],
            cowork: item[1],
            client_name: item[2],
            client_email: item[3],
            quantity: item[4],
            checkIn: item[5],
            checkOut: item[6],
            lock_key: item[7],
            share_info: item[8].props.children
        }),
        textLabels: {
            body: {
                noMatch: "Sem resultados",
                toolTip: "Ordenar",
                columnHeaderToolTip: column => `Ordenar por ${column.label}`
            },
            pagination: {
                next: 'Proxima',
                previous: 'Anterior',
                rowsPerPage: 'Linhas por pagina',
                displayRows: 'de'
            },
            toolbar: {
                search: 'Procurar',
                downloadCsv: 'Download CSV',
                print: 'Imprimir',
                viewColumns: 'Ver Colunas',
                filterTable: 'Filtrar Tabela'
            },
            filter: {
                all: 'ALL',
                title: 'FILTROS',
                reset: 'RESET'
            },
            viewColumns: {
                title: 'Mostrar Colunas',
                titleAria: 'Mostrar/Esconder Colunas',
            }
        }
    }

    return (
        <>
            <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title={"Reservas de Coworks"}
                    data={data}
                    columns={columns}
                    options={options}
                />
            </MuiThemeProvider>
            <ReservationInfoDialog event={selectedRowData} open={showInfoDialog} close={() => setShowInfoDialog(false)} refresh={props.refreshData} />
        </>
    )
}

const ReservationInfoDialog = (props) => {
    const classes = useStyles();

    const [triggerEdit, setTriggerEdit] = useState(false);
    const [triggerDelete, setTriggerDelete] = useState(false);
    const [feedbackDialog, setFeedbackDialog] = useState(false);
    const [feedbackTitle, setFeedbackTitle] = useState('');
    const [feedbackContent, setFeedbackContent] = useState('')

    const handleDelete = () => {
        axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/reservations/${props.event.id}`, { headers: { 'apikey': cookies.get('key') } })
            .then((res) => {
                handleDeleteClose();
                setFeedbackDialog(true);
                setFeedbackTitle('Sucesso')
                setFeedbackContent(`Reserva foi eliminada`)
            }, (err) => {
                console.log(err);
                setFeedbackDialog(true);
                setFeedbackTitle('Erro')
                setFeedbackContent(`Não foi possivel comunicar com o servidor. Se o problema persitir contacte o administrador do servidor. ${err}`)
            });
    }

    const handleDeleteClose = () => {
        setTriggerDelete(false);
        props.refresh();
    }

    const handleCloseEditModal = () => {
        setTriggerEdit(false);
        props.close();
        props.refresh();
    }

    return (
        <div>
            {props.event && (
                <Dialog
                    fullWidth={true}
                    maxWidth='sm'
                    open={props.open}
                    onClose={() => props.close()}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogContent>
                        <CssBaseline />
                        <Card elevation={3} className={classes.card}>
                            <CardHeader className={classes.header}
                                avatar={
                                    <Avatar style={{ backgroundColor: stringToColor(props.event.client_name) }}>
                                        {props.event.client_name[0].toUpperCase()}
                                    </Avatar>
                                }
                                title={`Reserva de ${props.event.client_name}`}
                                subheader={props.event.cowork}
                            />
                        </Card>
                        <div style={{ display: 'flex' }}>
                            <Card elevation={3} className={classes.contentCard} >
                                <CardContent className={classes.cardContent}>
                                    <List style={{ paddingBottom: '0' }}>
                                        <ListItem className={classes.listRow}>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <PersonIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Nome" secondary={props.event.client_name} />
                                            </div>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <EmailIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Email" secondary={props.event.client_email} />
                                            </div>
                                        </ListItem>
                                        <Divider light />
                                        <ListItem className={classes.listRow}>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <CoworkIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Cowork" secondary={props.event.cowork} />
                                            </div>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <SeatsIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Numero de Pessoas" secondary={props.event.quantity} />
                                            </div>
                                        </ListItem>
                                        <Divider light />
                                        <ListItem className={classes.listRow}>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <CheckOutIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Data de Entrada" secondary={props.event.checkIn} />
                                            </div>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <CheckOutIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Data de Saida" secondary={props.event.checkOut} />
                                            </div>
                                        </ListItem>
                                        <Divider light />
                                        <ListItem className={classes.listRow}>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <AddedIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Data de Criação" secondary={moment(props.event.createdAt).format('YYYY-MM-DD HH:mm')} />
                                            </div>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <ChangedIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Ultima Alteração" secondary={moment(props.event.updatedAt).format('YYYY-MM-DD HH:mm')} />
                                            </div>
                                        </ListItem>
                                        <ListItem className={classes.listRow}>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <VpnKey />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Código Entrada" secondary={props.event.lock_key ? props.event.lock_key : 'N/A'} />
                                            </div>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <FolderShared />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Partilha de Dados" secondary={props.event.share_info} />
                                            </div>
                                        </ListItem>
                                    </List>
                                </CardContent>
                            </Card>
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.cardActions}>
                        <div>
                            <Button style={{ marginRight: '15px' }} color='primary' onClick={() => setTriggerEdit(true)}>Editar</Button>
                            <Button color='primary' onClick={() => setTriggerDelete(true)}>Eliminar</Button>
                        </div>
                        <Button onClick={() => props.close()} color="primary">
                            Fechar
                        </Button>
                    </DialogActions>
                    <FeedBackDialog
                        open={feedbackDialog}
                        close={() => { setFeedbackDialog(false); props.close() }}
                        title={feedbackTitle}
                        content={feedbackContent} />
                    <Dialog
                        open={triggerDelete}
                        onClose={handleDeleteClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Tem a certesa que pretende eliminar este registo"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Se eliminar esta reserva não poderá reverter este porcesso. Tem a certesa?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDeleteClose} color="primary" autoFocus>
                                Cancelar
                            </Button>
                            <Button onClick={handleDelete} color="primary">
                                Eliminar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        fullWidth={true}
                        maxWidth='md'
                        open={triggerEdit}
                        onClose={handleCloseEditModal}
                        aria-labelledby="max-width-dialog-title"
                    >
                        <DialogContent>
                            <EditReservationMain data={props.event} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseEditModal} color="primary">
                                Fechar
                            </Button>
                        </DialogActions>
                    </Dialog>

                </Dialog>
            )}
        </div>
    )
}
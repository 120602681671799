import React, { useState } from 'react'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import CardMedia from '@material-ui/core/CardMedia';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { Tooltip } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core'
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge'

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SiteIcon from '@material-ui/icons/Language';

import axios from 'axios'
import Cookies from 'universal-cookie'

import EditPartnerDialog from './EditPartnerDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        '& > *': {

        },
    },
    card: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
    },
    header: {
        width: '100%',
        height: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '2px'

    },
    cardContent: {
        padding: 15,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    cardActions: {
        justifyContent: 'space-around',
        paddingBottom: '5px',
        paddingTop: '0px'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    media: {
        height: '350px',
        width: '100%',
    },
    description: {
        whiteSpace: 'pre-line'
    },
    active_badge: {
        backgroundColor: 'green',
        transform: 'scale(0.7)',
        marginRight: '10px',
        cursor: 'pointer',
    },
    inactive_badge: {
        backgroundColor: 'rgb(192,0,0)',
        transform: 'scale(0.7)',
        marginRight: '10px',
        cursor: 'pointer'
    }
}));

const cookies = new Cookies();

function PartnersCard(props) {
    const classes = useStyles();

    const [expanded, setExpanded] = React.useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [feedbackDialog, setFeedbackDialog] = useState(false);
    const [feedbackTitle, setFeedbackTitle] = useState('');
    const [feedbackContent, setFeedbackContent] = useState('');

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleDetelePartner = () => {

        axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/partnerships/${props.currentSelectedPartner.id}`, { headers: { 'apikey': cookies.get('key') } })
            .then((res) => {
                setFeedbackDialog(true);
                setFeedbackTitle('Sucesso')
                setFeedbackContent(`O Parceiro ${props.currentSelectedPartner.name} foi eliminado com sucesso.`);
            }, (err) => {
                console.log(err)
                setFeedbackDialog(true);
                setFeedbackTitle('Erro')
                setFeedbackContent(`Não foi possivel eliminar o parceiro ${props.currentSelectedPartner.name}. Se o problema persitir contacte o administrador do servidor ${err}`)
            });
    }

    const changeOnline = () => {
        let formData = new FormData();
        formData.set('id', props.currentSelectedPartner.id);
        formData.set('online', !props.currentSelectedPartner.online)

        axios.put(`${process.env.REACT_APP_SERVER_URL}/api/partnerships/${props.currentSelectedPartner.id}`, formData, { headers: { 'apikey': cookies.get('key') } })
            .then((res) => {
                setFeedbackDialog(true);
                setFeedbackTitle('Sucesso')
                setFeedbackContent(`O Item ${props.currentSelectedPartner.name} está agora ${props.currentSelectedPartner.online ? 'Offline' : 'Online'}`);
            }, (err) => {
                console.log(err)
                setFeedbackDialog(true);
                setFeedbackTitle('Erro')
                setFeedbackContent(`Não foi possivel alterar o cowork ${props.currentSelectedPartner.name}. Se o problema persitir contacte o administrador do servidor`)
            });
    }

    const handleCloseDialog = () => {
        setDeleteDialog(false);
        setFeedbackDialog(false);
        props.refreshPartners();
    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
        if (newWindow) newWindow.opener = null
    }

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <CardMedia
                    className={classes.media}
                    image={`${process.env.REACT_APP_SERVER_URL}/resources/images/uploads/partners/${props.currentSelectedPartner.portrait}`}
                    title="partner_protrait"
                    component="img"
                    alt="partner portrait"
                    height="140"

                />
                <CardHeader className={classes.header}
                    action={
                        <Tooltip title={props.currentSelectedPartner.online ? 'Online' : 'Offline'} placement='right' >
                            <Badge
                                invisible={false}
                                classes={props.currentSelectedPartner.online ? { badge: classes.active_badge } : { badge: classes.inactive_badge }}
                                onClick={() => changeOnline()}
                            />
                        </Tooltip>
                    }
                    title={props.currentSelectedPartner.name}
                    subheader={`${props.currentSelectedPartner.location} - ${props.currentSelectedPartner.profession_PT}`}

                />
                <CardActions disableSpacing style={{ width: '100%', padding: '0px 8px 8px 8px' }}>
                    <Tooltip title='Website' placement='bottom'>
                        <IconButton aria-label="add to favorites" component='a' onClick={() => openInNewTab(props.currentSelectedPartner.webpage)}>
                            <SiteIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Editar' placement='bottom'>
                        <IconButton aria-label="add to favorites" onClick={() => setEditDialog(true)}>
                            <EditIcon data={props.currentSelectedPartner} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title='Eliminar' placement='bottom' >
                        <IconButton aria-label="share" onClick={() => setDeleteDialog(true)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={expanded ? 'Ver Menos' : 'Ver Mais'} placement='bottom'>
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded,
                            })}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </Tooltip>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent className={classes.cardContent}>
                        <CardMedia
                            style={{ marginBottom: '15px' }}
                            image={`${process.env.REACT_APP_SERVER_URL}/resources/images/uploads/partners/${props.currentSelectedPartner.full_image}`}
                            title="background Image"
                            component="img"
                            alt="background Image"

                        />
                        <Typography className={classes.description} align='center' variant="body2" color="textSecondary" component="p">
                            {props.currentSelectedPartner.description_PT}
                        </Typography>
                        <Tooltip title={expanded ? 'Ver Menos' : 'Ver Mais'} placement='bottom'>
                            <IconButton
                                className={clsx(classes.expand, {
                                    [classes.expandOpen]: expanded,
                                })}
                                style={{ marginLeft: '0' }}
                                onClick={handleExpandClick}
                                aria-expanded={expanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </Tooltip>
                    </CardContent>
                </Collapse>
            </Card>
            <Dialog
                open={deleteDialog}
                onClose={() => setDeleteDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Tem a certeza que pretende eliminar este registo"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Se eliminar este parceiro não poderá reverter este porcesso. Tem a certeza?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialog(false)} color="primary" autoFocus>
                        Cancelar
                    </Button>
                    <Button onClick={handleDetelePartner} color="primary">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth='md'
                open={editDialog}
                onClose={() => setEditDialog(false)}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent>
                    <EditPartnerDialog data={props.currentSelectedPartner} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setEditDialog(false); props.refreshPartners() }} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={feedbackDialog}
                onClose={() => setFeedbackDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="confirmation-dialog-title">{feedbackTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirmation-dialog-description">
                        {feedbackContent}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default PartnersCard

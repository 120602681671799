import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List';
import axios from 'axios'
import Cookies from 'universal-cookie'
import moment from 'moment';
import ServerResponse from '../ErrorDialog/ServerResponse'
import { InputLabel, Select, MenuItem } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	layout: {
		width: 'auto',
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
			width: 600,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	paper: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
			marginTop: theme.spacing(6),
			marginBottom: theme.spacing(6),
			padding: theme.spacing(3),
		},
	},
	stepper: {
		padding: theme.spacing(3, 0, 5),
	},
	buttons: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	button: {
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(1),
	},
}));

const useStyles_review = makeStyles((theme) => ({
	listItem: {
		padding: theme.spacing(1, 0),
	},
	total: {
		fontWeight: 700,
	},
	title: {
		marginTop: theme.spacing(2),
	},
}));

const steps = ['Editar Info', 'Confirmar Info'];

const cookies = new Cookies();

let globalState = null;

function ReviewData(props) {
	const classes = useStyles_review();

	const data = globalState === null ? props.data : globalState;

	return (
		<React.Fragment>
			<Typography variant="h6" gutterBottom>
				Confirme os dados
			</Typography>
			<List disablePadding>
				<ListItem className={classes.listItem} key={data.cowork}>
					<ListItemText primary="Cowork" />
					<Typography variant="body2">{data.cowork}</Typography>
				</ListItem>
				<ListItem className={classes.listItem} key={data.client_name}>
					<ListItemText primary="Nome do Cliente" />
					<Typography variant="body2">{data.client_name}</Typography>
				</ListItem>
				<ListItem className={classes.listItem} key={data.client_email}>
					<ListItemText primary="Email do Cliente" />
					<Typography variant="body2">{data.client_email}</Typography>
				</ListItem>
				<ListItem className={classes.listItem} key={data.quantity}>
					<ListItemText primary="Numero de Pessoas" />
					<Typography variant="body2">{data.quantity}</Typography>
				</ListItem>
				<ListItem className={classes.listItem} key={'checkin'}>
					<ListItemText primary="Check In" />
					<Typography variant="body2">{moment(data.checkin).utc().format('YYYY/MM/DD HH:mm')}</Typography>
				</ListItem>
				<ListItem className={classes.listItem} key={'checkout'}>
					<ListItemText primary="Check Out" />
					<Typography variant="body2">{moment(data.checkout).utc().format('YYYY/MM/DD HH:mm')}</Typography>
				</ListItem>
			</List>
		</React.Fragment>
	);
}

class FillData extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			id: props.data.id,
			cowork: props.data.cowork,
			client_name: props.data.client_name,
			client_email: props.data.client_email,
			quantity: props.data.quantity,
			checkin: moment(props.data.checkin).minutes(0).add(moment(props.data.checkin).utcOffset(), 'minutes').toISOString(),
			checkout: moment(props.data.checkout).minutes(0).add(moment(props.data.checkout).utcOffset(), 'minutes').toISOString(),
			formErrors: { cowork: '', client_name: '', client_email: '', quantity: '' },
			coworkIsValid: true,
			client_nameIsValid: true,
			client_emailIsValid: true,
			quantityIsValid: true,
			formIsValid: true,
		}
	}


	handleInput(event) {
		const name = event.target.name;
		const value = event.target.value;
		this.setState({ [name]: value }, () => { this.validateFields(name, value) });
	}

	handleCheckInInput(date) {
		const utcOffset = moment(date).utcOffset()
		const transformedDate = moment(date).minutes(0).add(utcOffset, 'minutes').toISOString()
		this.setState({ checkin: transformedDate }, this.validateForm)
	}

	handleCheckOutInput(date) {
		const utcOffset = moment(date).utcOffset()
		const transformedDate = moment(date).minutes(0).add(utcOffset, 'minutes').toISOString()
		this.setState({ checkout: transformedDate }, this.validateForm)
	}

	validateFields(fieldName, value) {
		let fieldValidationErrors = this.state.formErrors;
		let coworkIsValid = this.state.coworkIsValid;
		let client_nameIsValid = this.state.client_nameIsValid;
		let client_emailIsValid = this.state.client_emailIsValid;
		let quantityIsValid = this.state.quantityIsValid;

		switch (fieldName) {
			case 'cowork':
				coworkIsValid = new RegExp(/^[a-zA-Z\u00C0-\u00FF\s*]*$/).test(value) && value.length > 4;
				fieldValidationErrors.cowork = coworkIsValid ? '' : 'Nome de Cowork Invalido'
				break;
			case 'client_name':
				client_nameIsValid = new RegExp(/^[a-zA-Z\u00C0-\u00FF]{3,}[\s]{1}[a-zA-Z\u00C0-\u00FF]{1,}$/).test(value) && value.length > 4;
				fieldValidationErrors.client_name = client_nameIsValid ? '' : 'Nome Inválido'
				break;
			case 'client_email':
				client_emailIsValid = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value);
				fieldValidationErrors.client_email = client_emailIsValid ? '' : 'Email inválido'
				break;
			case 'quantity':
				quantityIsValid = value >= 1;
				fieldValidationErrors.quantity = quantityIsValid ? '' : 'Quantia inválida'
				break;
			default:
				break;
		}


		this.setState({
			formErrors: fieldValidationErrors,
			coworkIsValid: coworkIsValid,
			client_nameIsValid: client_nameIsValid,
			client_emailIsValid: client_emailIsValid,
			quantityIsValid: quantityIsValid
		}, this.validateForm)

	}

	validateForm() {
		this.setState({
			formIsValid:
				this.state.coworkIsValid &&
				this.state.client_nameIsValid &&
				this.state.client_emailIsValid &&
				this.state.quantityIsValid
		}, () => {
			globalState = this.state;
			this.props.setformValid(this.state.formIsValid)
		})
	}

	render() {
		return (
			<React.Fragment>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>
						<TextField
							required
							disabled
							error={!this.state.client_nameIsValid ? true : false}
							helperText={'O nome só pode ser alterado no cliente'}
							id="client_name"
							name="client_name"
							label="Nome Cliente (primeiro e ultimo)"
							fullWidth
							autoComplete="given-name"
							defaultValue={this.state.client_name}
							onChange={(e) => this.handleInput(e)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							required
							disabled
							error={!this.state.client_emailIsValid ? true : false}
							helperText={'O email só pode ser alterado no cliente'}
							id="client_email"
							name="client_email"
							label="Email Cliente"
							fullWidth
							autoComplete="email"
							defaultValue={this.state.client_email}
							onChange={(e) => this.handleInput(e)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<InputLabel id="cowork">Cowork</InputLabel>
						<Select
							required
							fullWidth
							labelId="cowork"
							id="cowork"
							name="cowork"
							value={this.state.cowork}
							onChange={(e) => this.handleInput(e)}
							displayEmpty={false}
						>
							{this.props.coworkList.map((cowork) => {
								return <MenuItem key={cowork.id} value={cowork.title} >{cowork.title}</MenuItem>
							})}
						</Select>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							required
							error={!this.state.quantityIsValid ? true : false}
							helperText={this.state.formErrors.quantity}
							id="quantity"
							name="quantity"
							label="Pessoas"
							type='number'
							fullWidth
							defaultValue={this.state.quantity}
							onChange={(e) => this.handleInput(e)}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDateTimePicker
								variant="inline"
								format="yyyy/MM/dd HH:mm"
								margin="normal"
								id="check In Picker"
								label="Check In"
								ampm={false}
								minutesStep={0}
								views={["date", "hours"]}
								inputValue={moment(this.state.checkin).utc().format('yyyy/MM/DD HH:mm')}
								onChange={(e) => this.handleCheckInInput(e)}
								autoOk
								KeyboardButtonProps={{ 'aria-label': 'change date' }}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
					<Grid item xs={12} sm={6}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDateTimePicker
								variant="inline"
								format="yyyy/MM/dd HH:mm"
								margin="normal"
								id="check Out Picker"
								label="Check Out"
								ampm={false}
								minutesStep={0}
								views={["date", "hours"]}
								inputValue={moment(this.state.checkout).utc().format('yyyy/MM/DD HH:mm')}
								onChange={(e) => this.handleCheckOutInput(e)}
								autoOk
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
							/>
						</MuiPickersUtilsProvider>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	}
}


export default function EditReservationMain(props) {
	const classes = useStyles();
	const [activeStep, setActiveStep] = useState(0);
	const [validForm, setValidForm] = useState(false);
	const [feedbackMessage, setFeedbackMessage] = useState('')
	const [feedbackSubMessage, setFeedbackSubMessage] = useState('');
	const [coworkList, setCoworkList] = React.useState([])

	useEffect(() => {
		axios.get(`${process.env.REACT_APP_SERVER_URL}/api/coworks`, { headers: { 'apikey': cookies.get('key') } })
			.then((res) => {
				setCoworkList(res.data)
			}, (err) => {
				console.log(err)
			});
	}, [])

	let state = {
		id: props.data.id,
		cowork: props.data.cowork,
		client_name: props.data.client_name,
		client_email: props.data.client_email,
		quantity: props.data.quantity,
		checkin: props.data.checkIn,
		checkout: props.data.checkOut,
	}

	function getStepContent(step) {
		switch (step) {
			case 0:
				return <FillData data={state} coworkList={coworkList} setformValid={getFormInfo} />;
			case 1:
				return <ReviewData data={state} />;
			default:
				throw new Error('Unknown step');
		}
	}

	const getFormInfo = (info) => {
		setValidForm(info)
	}

	const submitInfoToServer = () => {

		axios.put(`${process.env.REACT_APP_SERVER_URL}/api/reservations/${globalState.id}`, {
			id: globalState.id,
			cowork: globalState.cowork,
			client_name: globalState.client_name,
			client_email: globalState.client_email,
			quantity: globalState.quantity,
			checkIn: globalState.checkin,
			checkOut: globalState.checkout
		}, { headers: { 'apikey': cookies.get('key') } })
			.then((res) => {
				setFeedbackMessage('Reserva editada com sucesso')
				setFeedbackSubMessage(`Mensagem do servidor: ${res.data}`);
				setActiveStep(activeStep + 1);
			}, (err) => {
				console.log(err)
				setFeedbackMessage(`Não foi possivel criar reserva, se o problema percistir contacte o administrador do servidor, ${err}`)
				setFeedbackSubMessage(`Mensage do servidor: ${err.response ? err.response.data : err}`);
				setActiveStep(activeStep + 1);
			});

	}

	const handleNext = () => {
		setActiveStep(activeStep + 1);
	};

	const handleBack = () => {
		setActiveStep(activeStep - 1);
	};

	return (
		<React.Fragment>
			<CssBaseline />
			<AppBar position="absolute" color="default" className={classes.appBar}>
			</AppBar>
			<main className={classes.layout}>
				<Paper className={classes.paper}>
					<Typography component="h1" variant="h4" align="center">
						Editar Reserva
					</Typography>
					<Stepper activeStep={activeStep} className={classes.stepper}>
						{steps.map((label) => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
					<React.Fragment>
						{activeStep === steps.length ? (
							<ServerResponse message={feedbackMessage} submessage={feedbackSubMessage} />
						) : (
							<React.Fragment>
								{getStepContent(activeStep)}
								<div className={classes.buttons}>
									{activeStep !== 0 && (
										<Button onClick={handleBack} className={classes.button}>
											Anterior
										</Button>
									)}
									<Button
										variant="contained"
										color="primary"
										onClick={activeStep === steps.length - 1 ? submitInfoToServer : handleNext}
										className={classes.button}
										disabled={validForm ? false : true}
									>
										{activeStep === steps.length - 1 ? 'Submeter' : 'Seguinte'}
									</Button>
								</div>
							</React.Fragment>
						)}
					</React.Fragment>
				</Paper>
			</main>
		</React.Fragment>
	);
}
import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

export default class ServerResponse extends Component {

    render() {
        return (
            <div>
                <React.Fragment>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <Typography align='center' variant="h5" gutterBottom>
                                {this.props.message}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography align='center' variant="subtitle1" gutterBottom>
                                {this.props.submessage}
                            </Typography>
                        </Grid>
                    </Grid>
                </React.Fragment>
            </div>
        )
    }
}

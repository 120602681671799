import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import axios from 'axios'
import Cookies from 'universal-cookie'
import FormData from 'form-data'
import { getAmenities } from '../Data/Amenities'

import InfoPT from './edit/InfoPT'
import InfoEN from './edit/InfoEN'
import ImageUpload from './edit/ImageUpload'
import Review from './edit/Review'
import ServerResponse from '../ErrorDialog/ServerResponse'

const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
});

const cookies = new Cookies();
const steps = ['Info em PT', 'Info em EN', 'Upload de Images', 'Confirmar']

class EditCoworkDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            id: this.props.data.id,
            title: this.props.data.title,
            location: this.props.data.location,
            short_description_PT: this.props.data.short_description_PT,
            short_description_EN: this.props.data.short_description_EN,
            full_description_PT: this.props.data.full_description_PT,
            full_description_EN: this.props.data.full_description_EN,
            capacity: this.props.data.capacity,
            vrUrl: this.props.data.vrUrl,
            online: this.props.data.online,
            address: this.props.data.address,
            coordinates: this.props.data.coordinates,
            amenities: getAmenities(this.props.data.amenities),
            tncPDFpt: null,
            tncPDFen: null,
            portrait: null,
            sliderImage1: null,
            sliderImage2: null,
            sliderImage3: null,
            blueprint: null,
            validForm: true,
            feedbackMessage: '',
            feedbackSubMessage: ''
        }
    }

    getStepContent(step) {
        switch (step) {
            case 0:
                return <InfoPT defaultData={this.state} updateMainState={this.updateState} />;
            case 1:
                return <InfoEN defaultData={this.state} updateMainState={this.updateState} />;
            case 2:
                return <ImageUpload updateMainState={this.updateState} />;
            case 3:
                return <Review data={this.state} updateMainState={this.updateState} />;
            default:
                throw new Error('Unknown step')
        }
    }

    updateState = (property, value) => {
        this.setState({ [property]: value })
    }

    submitInfoToServer = () => {

        const amenities = this.state.amenities.map(a => a.id)

        let formData = new FormData();
        formData.set('id', this.state.id);
        formData.set('title', this.state.title);
        formData.set('location', this.state.location);
        formData.set('short_description_PT', this.state.short_description_PT);
        formData.set('short_description_EN', this.state.short_description_EN);
        formData.set('full_description_PT', this.state.full_description_PT);
        formData.set('full_description_EN', this.state.full_description_EN);
        formData.set('capacity', this.state.capacity);
        formData.set('vrUrl', this.state.vrUrl);
        formData.set('online', this.state.online);
        formData.set('address', this.state.address);
        formData.set('coordinates', this.state.coordinates);
        formData.set('amenities', amenities.join(','));
        if (this.state.portrait !== null) formData.append('portrait', this.state.portrait[0]);
        if (this.state.sliderImage1 !== null) formData.append('sliderImage1', this.state.sliderImage1[0]);
        if (this.state.sliderImage2 !== null) formData.append('sliderImage2', this.state.sliderImage2[0]);
        if (this.state.sliderImage3 !== null) formData.append('sliderImage3', this.state.sliderImage3[0]);
        if (this.state.blueprint !== null) formData.append('blueprint', this.state.blueprint[0])
        if (this.state.tncPDFpt !== null) formData.append('tncPDFpt', this.state.tncPDFpt)
        if (this.state.tncPDFen !== null) formData.append('tncPDFen', this.state.tncPDFen)

        axios.put(`${process.env.REACT_APP_SERVER_URL}/api/coworks/${this.state.id}`, formData, {
            headers: {
                'apikey': cookies.get('key'),
                'accept': 'application/json',
                'Accept-Language': 'en-US,en;q=0.8',
                'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
            }
        })
            .then((res) => {
                this.setState({
                    feedbackMessage: `Cowork ${this.state.title} editado com sucesso.`,
                    feedbackSubMessage: `Mensagem do servidor: ${res.data}`
                })
                this.handleNext();
            }, (err) => {
                console.log(err);
                this.setState({
                    feedbackMessage: `Erro ao editar cowork, se o erro percistir contacte o administrador do servidor.`,
                    feedbackSubMessage: `Mensage do servidor: ${err.response ? err.response.data : err}`
                })
                this.handleNext();
            });
    }

    handleNext = () => {
        this.setState({
            activeStep: this.state.activeStep + 1,
        })

    }
    handleBack = () => {
        this.setState({
            activeStep: this.state.activeStep - 1
        })
    }

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <CssBaseline />
                <AppBar position="absolute" color="default" className={classes.appBar}>
                </AppBar>
                <main className={classes.layout}>
                    <Paper className={classes.paper}>
                        <Typography component="h1" variant="h4" align="center">
                            Editar Cowork
                        </Typography>
                        <Stepper activeStep={this.state.activeStep} className={classes.stepper}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <React.Fragment>
                            {this.state.activeStep === steps.length ? (
                                <ServerResponse message={this.state.feedbackMessage} submessage={this.state.feedbackSubMessage} />
                            ) : (
                                <React.Fragment>
                                    {this.getStepContent(this.state.activeStep)}
                                    <div className={classes.buttons}>
                                        {this.state.activeStep !== 0 && (
                                            <Button onClick={this.handleBack} className={classes.button}>
                                                Anterior
                                            </Button>
                                        )}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.state.activeStep === steps.length - 1 ? this.submitInfoToServer : this.handleNext}
                                            className={classes.button}
                                            disabled={this.state.validForm ? false : true}
                                        >
                                            {this.state.activeStep === steps.length - 1 ? 'Submeter' : 'Seguinte'}
                                        </Button>
                                    </div>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    </Paper>
                </main>
            </React.Fragment>
        )
    }
}

export default withStyles(styles)(EditCoworkDialog)
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List';
import axios from 'axios'
import Cookies from 'universal-cookie'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import { IconButton } from '@material-ui/core';

import ServerResponse from '../ErrorDialog/ServerResponse'

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));

const useStyles_review = makeStyles((theme) => ({
    listItem: {
        padding: theme.spacing(1, 0),
    },
    total: {
        fontWeight: 700,
    },
    title: {
        marginTop: theme.spacing(2),
    },
}));

const steps = ['Adicionar Info', 'Confirmar Info'];

let globalState = null;

const cookies = new Cookies();

function ReviewData() {
    const classes = useStyles_review();

    const data = globalState;

    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Confirme os dados
            </Typography>
            <List disablePadding>
                <ListItem className={classes.listItem} key={data.username}>
                    <ListItemText primary="Nome" />
                    <Typography variant="body2">{data.username}</Typography>
                </ListItem>
                <ListItem className={classes.listItem} key={data.email}>
                    <ListItemText primary="Email" />
                    <Typography variant="body2">{data.email}</Typography>
                </ListItem>
                <ListItem className={classes.listItem} key={data.userType}>
                    <ListItemText primary="Privilégios" />
                    <Typography variant="body2">{data.userType}</Typography>
                </ListItem>
            </List>
        </React.Fragment>
    );
}

class FillData extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            email: '',
            password: '',
            userType: 'User',
            confirmPassword: '',
            formErrors: { username: '', email: '', password: '', confirmPassword: '' },
            usernameIsValid: false,
            emailIsValid: false,
            passwordIsValid: false,
            confirmPasswordIsValid: false,
            formIsValid: false,
            showPassword1: false,
            showPassword2: false,
        }
    }

    componentDidMount() {
        globalState = this.state
    }

    handleInput(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({ [name]: value }, () => { this.validateFields(name, value) });
    }

    validateFields(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let usernameIsValid = this.state.usernameIsValid;
        let emailIsValid = this.state.emailIsValid;
        let passwordIsValid = this.state.passwordIsValid;
        let confirmPasswordIsValid = this.state.confirmPasswordIsValid;

        switch (fieldName) {
            case 'username':
                usernameIsValid = new RegExp(/^[a-zA-Z\u00C0-\u00FF]{3,}[\s]{1}[a-zA-Z\u00C0-\u00FF]{1,}$/).test(value) && value.length > 4;
                fieldValidationErrors.username = usernameIsValid ? '' : 'Nome Invalido'
                break;
            case 'email':
                emailIsValid = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value);
                fieldValidationErrors.email = emailIsValid ? '' : 'Nome Inválido'
                break;
            case 'password':
                passwordIsValid = new RegExp(/^(?=(.*[a-z]){3,})(?=(.*[A-Z]){2,})(?=(.*[0-9]){2,}).{8,}$/).test(value);
                fieldValidationErrors.password = passwordIsValid ? '' : 'Password deve conter no minimo 2 maiúsculas, 2 números, 3 minúsculas e mais de 8 caracteres;'
                break;
            case 'confirmPassword':
                confirmPasswordIsValid = this.state.password === this.state.confirmPassword;
                fieldValidationErrors.confirmPassword = confirmPasswordIsValid ? '' : 'Password não são coincidem'
                break;
            default:
                break;
        }


        this.setState({
            formErrors: fieldValidationErrors,
            usernameIsValid: usernameIsValid,
            emailIsValid: emailIsValid,
            passwordIsValid: passwordIsValid,
            confirmPasswordIsValid: confirmPasswordIsValid
        }, this.validateForm)

    }

    validateForm() {
        this.setState({
            formIsValid:
                this.state.usernameIsValid &&
                this.state.emailIsValid &&
                this.state.passwordIsValid &&
                this.state.confirmPasswordIsValid
        }, () => {
            globalState = this.state;
            this.props.setformValid(this.state.formIsValid);
        })
    }

    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    render() {
        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={5}>
                        <TextField
                            required
                            autoFocus
                            error={!this.state.usernameIsValid ? true : false}
                            helperText={this.state.formErrors.username}
                            id="username"
                            name="username"
                            label="Nome (primeiro e último)"
                            fullWidth
                            type="text"
                            defaultValue={this.state.username}
                            autoComplete='given-name'
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            error={!this.state.emailIsValid ? true : false}
                            helperText={this.state.formErrors.email}
                            id="email"
                            name="email"
                            label="Email"
                            type='email'
                            fullWidth
                            autoComplete="email"
                            defaultValue={this.state.email}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <InputLabel id="userType">Privilégios</InputLabel>
                        <Select
                            required
                            fullWidth
                            helperText='asd'
                            labelId="userType"
                            id="userType"
                            name="userType"
                            value={this.state.userType}
                            onChange={(e) => this.handleInput(e)}
                        >
                            <MenuItem value='User'>User</MenuItem>
                            <MenuItem value='Admin'>Admin</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            error={!this.state.passwordIsValid ? true : false}
                            helperText={this.state.formErrors.password}
                            id="password"
                            name="password"
                            label="Password"
                            type={this.state.showPassword1 ? 'text' : 'password'}
                            fullWidth
                            defaultValue={this.state.password}
                            onChange={(e) => this.handleInput(e)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton tabIndex='-1' onClick={() => this.setState({ showPassword1: !this.state.showPassword1 })} >
                                        {this.state.showPassword1 ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>,
                            }}

                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            error={!this.state.confirmPasswordIsValid ? true : false}
                            helperText={this.state.formErrors.confirmPassword}
                            id="confirmPassword"
                            name="confirmPassword"
                            label="Confirme Password"
                            type={this.state.showPassword2 ? 'text' : 'password'}
                            fullWidth
                            defaultValue={this.state.confirmPassword}
                            onChange={(e) => this.handleInput(e)}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton tabIndex='-1' onClick={() => this.setState({ showPassword2: !this.state.showPassword2 })} >
                                        {this.state.showPassword2 ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>,
                            }}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}


export default function AddUserDialog(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [validForm, setValidForm] = React.useState(false);
    const [feedbackMessage, setFeedbackMessage] = React.useState('')
    const [feedbackSubMessage, setFeedbackSubMessage] = React.useState('')

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <FillData setformValid={getFormInfo} />;
            case 1:
                return <ReviewData />;
            default:
                throw new Error('Unknown step');
        }
    }

    const getFormInfo = (info) => {
        setValidForm(info)
    }

    const submitInfoToServer = () => {

        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/users`, {
            username: globalState.username,
            password: globalState.password,
            email: globalState.email,
            userType: globalState.userType,
        }, { headers: { 'apikey': cookies.get('key') } })
            .then((res) => {
                setFeedbackMessage(`User ${globalState.username} adicionado com sucesso.`)
                setFeedbackSubMessage(`Mensagem do servidor: ${res.data}`)
                setActiveStep(activeStep + 1);
            }, (err) => {
                console.log(err)
                setFeedbackMessage(`Não foi possivel adicionar o usuario, se o problema percistir contacte o administrador do servidor.`)
                setFeedbackSubMessage(`Mensage do servidor: ${err.response ? err.response.data : err}`)
                setActiveStep(activeStep + 1);
            });
    }

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <AppBar position="absolute" color="default" className={classes.appBar}>
            </AppBar>
            <main className={classes.layout}>
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h4" align="center">
                        Adicionar Novo User
                    </Typography>
                    <Stepper activeStep={activeStep} className={classes.stepper}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <React.Fragment>
                        {activeStep === steps.length ? (
                            <ServerResponse message={feedbackMessage} submessage={feedbackSubMessage} />
                        ) : (
                            <React.Fragment>
                                {getStepContent(activeStep)}
                                <div className={classes.buttons}>
                                    {activeStep !== 0 && (
                                        <Button onClick={handleBack} className={classes.button}>
                                            Anterior
                                        </Button>
                                    )}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={activeStep === steps.length - 1 ? submitInfoToServer : handleNext}
                                        className={classes.button}
                                        disabled={validForm ? false : true}
                                    >
                                        {activeStep === steps.length - 1 ? 'Submeter' : 'Seguinte'}
                                    </Button>
                                </div>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                </Paper>
            </main>
        </React.Fragment>
    );
}
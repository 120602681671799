import React from 'react'

import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List';

export default class ReviewData extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: props.data.name,
            location: props.data.location,
            webpage: props.data.webpage,
            online: props.data.online,
            description_PT: props.data.description_PT,
            description_EN: props.data.description_EN,
            profession_PT: props.data.profession_PT,
            profession_EN: props.data.profession_EN,
            portrait: props.data.portrait,
            full_image: props.data.full_image,
        }
    }

    componentDidMount() {
        this.props.updateMainState('formValid', true);
    }

    transformString(string, maxChar, insertDots) {
        return string.slice(0, maxChar) + (((string.length > maxChar) && insertDots) ? '...' : '');
    }

    render() {
        return (
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    Confirme os dados
                </Typography>
                <List disablePadding>
                    <ListItem key={this.state.name}>
                        <ListItemText primary="Nome" />
                        <Typography variant="body2">{this.state.name}</Typography>
                    </ListItem>
                    <ListItem key={this.state.location}>
                        <ListItemText primary="Locatização" />
                        <Typography variant="body2">{this.state.location}</Typography>
                    </ListItem>
                    <ListItem key={this.state.webpage}>
                        <ListItemText primary="Webpage" />
                        <Typography variant="body2">{this.state.webpage}</Typography>
                    </ListItem>
                    <ListItem key={this.state.online}>
                        <ListItemText primary="Online?" />
                        <Typography variant="body2">{this.state.online ? 'Sim' : 'Não'}</Typography>
                    </ListItem>
                    <ListItem key={this.state.profession_PT}>
                        <ListItemText primary="Profissão" />
                        <Typography variant="body2">{this.state.profession_PT}</Typography>
                    </ListItem>
                    <ListItem key={this.state.profession_EN}>
                        <ListItemText primary="Profession" />
                        <Typography variant="body2">{this.state.profession_EN}</Typography>
                    </ListItem>
                    <ListItem key={this.transformString(this.state.description_PT, 5, true)}>
                        <ListItemText primary="Descrição" />
                        <Typography variant="body2">{this.transformString(this.state.description_PT, 15, true)}</Typography>
                    </ListItem>
                    <ListItem key={this.transformString(this.state.description_EN, 5, true)}>
                        <ListItemText primary="Description" />
                        <Typography variant="body2">{this.transformString(this.state.description_EN, 15, true)}</Typography>
                    </ListItem>
                    <ListItem key={this.state.portrait[0].size}>
                        <ListItemText primary="Image Retrato" />
                        <Typography variant="body2">{this.state.portrait[0].name}</Typography>
                    </ListItem>
                    <ListItem key={this.state.full_image[0].size}>
                        <ListItemText primary="Imagem De fundo" />
                        <Typography variant="body2">{this.state.full_image[0].name}</Typography>
                    </ListItem>
                </List>
            </React.Fragment>
        );
    }
}
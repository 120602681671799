import React, { useState, useEffect } from 'react';
import moment from 'moment'
import axios from 'axios'
import Cookies from 'universal-cookie';

import FeedBackDialog from '../ErrorDialog/FeedBackDialog';
import EditReservationMain from './EditReservationMain';
import AddReservationDialog from './AddReservationDialog';

import Slide from '@material-ui/core/Slide';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { DialogTitle, DialogContentText } from '@material-ui/core';
import { Button, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import CssBaseline from '@material-ui/core/CssBaseline';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';

import ReservationIcon from '@material-ui/icons/House'
import OtherEventIcon from '@material-ui/icons/Build'
import SeatsIcon from '@material-ui/icons/EventSeat'
import PersonIcon from '@material-ui/icons/Person'
import EmailIcon from '@material-ui/icons/Email'
import CoworkIcon from '@material-ui/icons/House'
import CheckOutIcon from '@material-ui/icons/Alarm'
import AddedIcon from '@material-ui/icons/ControlPoint'
import ChangedIcon from '@material-ui/icons/RotateLeft'
import { RRule } from 'rrule';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import './calendar.css'
import 'moment/locale/pt'
import AddOtherEvent from '../calendarEvents/AddOtherEvent';
import EditOtherEvent from '../calendarEvents/EditOtherEvent';

const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
    outterDiv: {
        height: '100%'
    },
    calendar: {
        backgroundColor: 'white',
        height: '92%',
    },
    selection: {
        height: '8%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    navButtons: {
        letterSpacing: 0,
        border: '1px solid rgba(0, 0, 0, 0.2)',
        boxShadow: 'inset 0px 3px 5px rgba(0, 0, 0, 0.13)'

    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    card: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '20px',
        width: '100%'

    },
    header: {
        width: '100%',
        height: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    contentCard: {
        overflowWrap: 'anywhere',
        marginBottom: '0px',
        width: '100%'
    },
    listRow: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center'
    },
    listitems: {
        display: 'flex',
        width: '50%'
    },
    avatarList: {
        display: 'flex',
        alignItems: 'center',
    },
    cardActions: {
        justifyContent: 'space-between',
        padding: '8px 20px 8px 20px'
    },
    calendarAvatar: {
        backgroundColor: 'rgb(187,222,251)',
        color: 'rgb(30,136,229)'
    }
}));

const localizer = momentLocalizer(moment);

let formats = {
    timeGutterFormat: (date, culture, localizer) =>
        localizer.format(date, 'HH:mm', culture),
    eventTimeRangeFormat: ({ start, end }, culture, localizer) => {
        let s = localizer.format(start, 'HH:mm', culture);
        let e = localizer.format(end, 'HH:mm', culture);
        return `${s} - ${e}`;
    },
    agendaTimeRangeFormat: ({ start, end }, culture, localizer) => {
        let s = localizer.format(start, 'HH:mm', culture);
        let e = localizer.format(end, 'HH:mm', culture);
        return `${s} - ${e}`;
    },
    dayRangeHeaderFormat: ({ start, end }, culture, localizer) => {
        let s = localizer.format(start, 'MMM DD', culture);
        let e = localizer.format(end, 'MMM DD', culture);
        return `${s} - ${e}`;
    }
}

const messages = {
    date: 'Data',
    time: 'Tempo',
    event: 'Evento',
    allDay: 'Todo o Dia',
    week: 'Semana',
    work_week: 'Dias Utéis',
    day: 'Dia',
    month: 'Mês',
    previous: 'Anterior',
    next: 'Seguinte',
    yesterday: 'Ontem',
    tomorrow: 'Amanha',
    today: 'Hoje',
    agenda: 'Agenda',
    noEventsInRange: 'Não existem eventos nestes dias.',
    showMore: function showMore(total) {
        return "+ " + total + " mais";
    }
};

const stringToColor = (string) => {

    if (string === undefined) string = 'No User'

    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    return color
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


export default function ReservationCalendar(props) {
    const classes = useStyles();
    const data = {};
    const otherEvents = {};
    const recuringEvents = {};

    const [coworks, setCoworks] = useState([])
    const [selectedData, setSelectedData] = useState([])
    const [addReservationData, setAddReservationData] = useState('')
    const [addOtherData, setAddOtherData] = useState('')
    const [activeCowork, setActiveCowork] = useState('')
    const [selectedEvent, setSelectedEvent] = useState();
    const [selectedOtherEvent, setSelectedOtherEvent] = useState();

    const [triggerMenu, setTriggerMenu] = useState(false);
    const [triggerAddReservationDialog, setTriggerAddReservationDialog] = useState(false);
    const [triggerViewReservation, setTriggerViewReservation] = useState(false);
    const [triggerViewOtherEvent, setTriggerViewOtherEvent] = useState(false);
    const [triggerAddOtherEventDialog, setTriggerAddOtherEventDialog] = useState(false);

    const eventStyleGetter = (event, start, end, isSelected) => {
        let backgroundColor = event.client_email !== undefined ? 'rgb(49,116,173)' : event.color;
        var style = {
            backgroundColor: backgroundColor,
            borderRadius: '15px',
            opacity: 0.9,
            color: 'white',
        };
        return {
            // style: style
            className: 'custom-event', style
        };
    }

    const buildRRule = (event) => {

        switch (event.frequency) {
            case 'DAILY': return {
                dtstart: new Date(event.checkIn),
                freq: RRule.DAILY,
                interval: event.interval,
                count: event.until
            };
            case 'WEEKLY': return {
                dtstart: new Date(event.checkIn),
                freq: RRule.WEEKLY,
                byweekday: new Date(event.checkIn).getDay() - 1, //Monday is 0
                interval: event.interval,
                count: event.until
            };
            case 'MONTHLY':
                if (event.repeatType === 'day') {
                    return {
                        dtstart: new Date(event.checkIn),
                        freq: RRule.MONTHLY,
                        bymonthday: new Date(event.checkIn).getDate(),
                        interval: event.interval,
                        count: event.until
                    }
                } else {
                    return {
                        dtstart: new Date(event.checkIn),
                        freq: RRule.MONTHLY,
                        bysetpos: 1,
                        byweekday: new Date(event.checkIn).getDay() - 1,
                        interval: event.interval,
                        count: event.until
                    }
                }
            case 'YEARLY': return {
                dtstart: new Date(event.checkIn),
                freq: RRule.YEARLY,
                interval: event.interval,
                count: event.until
            };
            default: return '';
        }
    }

    useEffect(() => {
        setCoworks([...new Set(props.data.map(item => item.cowork.replace(/\s/g, '')))]);
    }, [props.data])

    useEffect(() => {
        const tempArr = [...props.data];
        const tempOtherArr = [...props.otherEvents]
        const rRuleArray = [];

        coworks.forEach((elem, index) => {
            data[elem] = tempArr.filter(item => item.cowork.replace(/\s/g, '') === elem)
            otherEvents[elem] = tempOtherArr.filter(item => item.cowork.replace(/\s/g, '') === elem && item.frequency === 'NEVER');
            recuringEvents[elem] = tempOtherArr.filter(item => item.cowork.replace(/\s/g, '') === elem && item.frequency !== 'NEVER');
            recuringEvents[elem].forEach((item) => {
                item.timeDiference = moment(item.checkOut).diff(moment(item.checkIn), 'minutes')
                rRuleArray.push(new RRule(buildRRule(item)).all())
            })
        })

        // remove the first element since this one already exists from the database
        rRuleArray.forEach(item => {
            item.shift();
        })

        let i = 0;
        Object.keys(recuringEvents).forEach(cowork => {
            recuringEvents[cowork].forEach(event => {
                rRuleArray[i].forEach(arrElem => {
                    recuringEvents[cowork].push({
                        id: event.id,
                        title: event.title,
                        checkIn: arrElem,
                        checkOut: moment(arrElem).add(event.timeDiference, 'minutes'),
                        color: event.color,
                        cowork: event.cowork,
                        frequency: event.frequency,
                        interval: event.interval,
                        createdAt: event.createdAt,
                        updatedAt: event.updatedAt,
                        description: event.description,
                    })
                })
                i++; //Visto que rRuleArray é um array e recuringEvents é um objecto com arrays, a let i indica quantos elementos ja percorreu para poder comparar com o rRuleArr
            })
        })
    })

    const setSelectedCowork = (event, cowork) => {
        setActiveCowork(cowork)
        if (data[cowork] === undefined) {
            setSelectedData([])
            return;
        }
        setSelectedData([...data[cowork], ...otherEvents[cowork], ...recuringEvents[cowork]])
    }

    const splitStringByCapitalLetter = (string) => {
        if(string.match(/[A-Z][a-z]+|[0-9]+/g)) {
            return string.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
        }
        return string;
    }

    const closeEventDialog = () => {
        setTriggerViewReservation(false);
        setTriggerViewOtherEvent(false);
        props.refreshData();
    }

    //not the best but the only way i found to refresh the calendar after adding or editing events
    const refreshCalendar = () => {
        setActiveCowork('')
        setSelectedData([])
        props.refreshData();
    }

    const calendarClickMenu = event => {
        if (activeCowork) {
            setAddOtherData({
                cowork: splitStringByCapitalLetter(activeCowork),
                start: event.start,
                end: event.end
            });
            setAddReservationData({
                cowork: splitStringByCapitalLetter(activeCowork),
                checkIn: event.start,
                checkOut: event.end
            })
            setTriggerMenu(true)
        }
    }

    const handleEventClick = (event) => {
        if (event.client_name !== undefined) {
            setSelectedEvent(event)
            setTriggerViewReservation(true);
            return;
        }
        setSelectedOtherEvent(event)
        setTriggerViewOtherEvent(true);
    }

    return (
        <div className={classes.outterDiv}>
            <div className={classes.selection}>
                <ToggleButtonGroup
                    value={activeCowork}
                    exclusive
                    onChange={setSelectedCowork}
                    aria-label='selected cowork'
                >
                    {coworks.map(item => {
                        return (
                            <ToggleButton
                                className={classes.navButtons}
                                key={item}
                                value={item}
                                aria-label={item}>{splitStringByCapitalLetter(item)}
                            </ToggleButton>
                        )
                    })}
                </ToggleButtonGroup>
            </div>
            <div className={classes.calendar}>
                <Calendar
                    localizer={localizer}
                    events={selectedData}
                    selectable
                    popup
                    timeslots={2}
                    step={60}
                    onSelectSlot={calendarClickMenu}
                    startAccessor={({ checkIn }) => new Date(moment(checkIn))}
                    showMultiDayTimes={true}
                    endAccessor={({ checkOut }) => new Date(moment(checkOut))}
                    tooltipAccessor={(e) => `${e.client_name !== undefined ? `Reserva de ${e.client_name}, ${e.quantity} Pessoas`: e.title} (${moment(e.checkIn).format('DD-MMM  HH:mm')}) - (${moment(e.checkOut).format('DD-MMM  HH:mm')})`}
                    titleAccessor={(e) => { return e.client_name !== undefined ? `Reserva de ${e.client_name}, ${e.quantity} Pessoas` : e.title }}
                    onSelectEvent={handleEventClick}
                    formats={formats}
                    messages={messages}
                    eventPropGetter={eventStyleGetter}
                    style={{ height: '100%' }}
                />
            </div>
            <Dialog
                maxWidth='xs'
                open={triggerMenu}
                onClose={() => setTriggerMenu(false)}
                TransitionComponent={Transition}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle style={{ textAlign: 'center', paddingBottom: '0px' }}>Crie um evento</DialogTitle>
                <DialogContent style={{ paddingTop: '0px' }}>
                    <List>
                        <ListItem button onClick={() => { setTriggerAddReservationDialog(true); setTriggerMenu(false) }}>
                            <ListItemAvatar>
                                <Avatar className={classes.calendarAvatar}>
                                    <ReservationIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary='Reserva' />
                        </ListItem>
                        {/* <ListItem button onClick={() => { setTriggerAddOtherEventDialog(true); setTriggerMenu(false) }}>
                            <ListItemAvatar>
                                <Avatar className={classes.calendarAvatar}>
                                    <OtherEventIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary='Outro Evento' />
                        </ListItem> */}
                    </List>
                </DialogContent>
            </Dialog>
            <EventDialog triggerEventDialog={triggerViewReservation} close={closeEventDialog} event={selectedEvent} refresh={refreshCalendar} />
            <OtherEventDialog triggerOtherEventDialog={triggerViewOtherEvent} close={closeEventDialog} event={selectedOtherEvent} refresh={refreshCalendar} />
            <Dialog
                fullWidth={true}
                maxWidth='md'
                open={triggerAddReservationDialog}
                onClose={() => setTriggerAddReservationDialog(false)}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent>
                    <AddReservationDialog data={addReservationData} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setTriggerAddReservationDialog(false); refreshCalendar() }} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth='md'
                open={triggerAddOtherEventDialog}
                onClose={() => setTriggerAddOtherEventDialog(false)}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent>
                    <AddOtherEvent data={addOtherData} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setTriggerAddOtherEventDialog(false); refreshCalendar() }} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const OtherEventDialog = props => {
    const classes = useStyles();

    const [triggerEdit, setTriggerEdit] = useState(false);
    const [triggerDelete, setTriggerDelete] = useState(false);
    const [feedbackDialog, setFeedbackDialog] = useState(false);
    const [feedbackTitle, setFeedbackTitle] = useState('');
    const [feedbackContent, setFeedbackContent] = useState('')

    const translateFrequency = (string) => {
        switch (string) {
            case 'DAILY': return 'Diáriamente';
            case 'WEEKLY': return 'Semanalmente';
            case 'MONTHLY': return 'Mensalmente';
            case 'YEARLY': return 'Anualmente';
            default: return 'Nunca'
        }
    }

    const handleDelete = () => {
        axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/calendar_events/${props.event.id}`, { headers: { 'apikey': cookies.get('key') } })
            .then((res) => {
                handleDeleteClose();
                setFeedbackDialog(true);
                setFeedbackTitle('Sucesso')
                setFeedbackContent(`Evento foi eliminado`)
            }, (err) => {
                console.log(err);
                setFeedbackDialog(true);
                setFeedbackTitle('Erro')
                setFeedbackContent(`Não foi possivel comunicar com o servidor. Se o problema persitir contacte o administrador do servidor. ${err.response}`)
            });
    }

    const handleDeleteClose = () => {
        setTriggerDelete(false);
        props.refresh();
    }

    const handleCloseEditModal = () => {
        setTriggerEdit(false);
        props.close();
        props.refresh();
    }

    return (
        <div>
            {props.event && (
                <Dialog
                    fullWidth={true}
                    maxWidth='sm'
                    open={props.triggerOtherEventDialog}
                    onClose={() => props.close()}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogContent>
                        <CssBaseline />
                        <Card elevation={3} className={classes.card}>
                            <CardHeader className={classes.header}
                                avatar={
                                    <Avatar style={{ backgroundColor: props.event.color }}>
                                        {props.event.title[0].toUpperCase()}
                                    </Avatar>
                                }
                                title={props.event.title}
                                subheader={props.event.cowork}
                            />
                        </Card>
                        <div style={{ display: 'flex' }}>
                            <Card elevation={3} className={classes.contentCard} >
                                <CardContent className={classes.cardContent}>
                                    <List style={{ paddingBottom: '0' }}>
                                        <ListItem className={classes.listRow}>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <PersonIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Titulo" secondary={props.event.title} />
                                            </div>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <CoworkIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Cowork" secondary={props.event.cowork} />
                                            </div>
                                        </ListItem>
                                        <Divider light />
                                        <ListItem className={classes.listRow}>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <ChangedIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Repetir" secondary={translateFrequency(props.event.frequency)} />
                                            </div>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <CheckOutIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Intervalo" secondary={props.event.frequency === 'NEVER' ? '' : props.event.interval} />
                                            </div>
                                        </ListItem>
                                        <Divider light />
                                        <ListItem className={classes.listRow}>
                                            <ListItemText primary="Descrição" secondary={props.event.description} />
                                        </ListItem>
                                    </List>
                                </CardContent>
                            </Card>
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.cardActions}>
                        <div>
                            <Button style={{ marginRight: '15px' }} color='primary' onClick={() => setTriggerEdit(true)}>Editar</Button>
                            <Button color='primary' onClick={() => setTriggerDelete(true)}>Eliminar</Button>
                        </div>
                        <Button onClick={() => props.close()} color="primary">
                            Fechar
                        </Button>
                    </DialogActions>
                    <FeedBackDialog
                        open={feedbackDialog}
                        close={() => { setFeedbackDialog(false); props.close() }}
                        title={feedbackTitle}
                        content={feedbackContent} />
                    <Dialog
                        open={triggerDelete}
                        onClose={handleDeleteClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Tem a certeza que pretende eliminar este registo"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Se eliminar esta reserva não poderá reverter este porcesso. Tem a certeza?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDeleteClose} color="primary" autoFocus>
                                Cancelar
                            </Button>
                            <Button onClick={handleDelete} color="primary">
                                Eliminar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        fullWidth={true}
                        maxWidth='md'
                        open={triggerEdit}
                        onClose={handleCloseEditModal}
                        aria-labelledby="max-width-dialog-title"
                    >
                        <DialogContent>
                            <EditOtherEvent data={props.event} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseEditModal} color="primary">
                                Fechar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Dialog>
            )}
        </div>
    )
}

const EventDialog = (props) => {
    const classes = useStyles();

    const [triggerEdit, setTriggerEdit] = useState(false);
    const [triggerDelete, setTriggerDelete] = useState(false);
    const [feedbackDialog, setFeedbackDialog] = useState(false);
    const [feedbackTitle, setFeedbackTitle] = useState('');
    const [feedbackContent, setFeedbackContent] = useState('')

    const handleDelete = () => {
        axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/reservations/${props.event.id}`, { headers: { 'apikey': cookies.get('key') } })
            .then((res) => {
                handleDeleteClose();
                setFeedbackDialog(true);
                setFeedbackTitle('Sucesso')
                setFeedbackContent(`Reserva foi eliminada`)
            }, (err) => {
                console.log(err);
                setFeedbackDialog(true);
                setFeedbackTitle('Erro')
                setFeedbackContent(`Não foi possivel comunicar com o servidor. Se o problema persitir contacte o administrador do servidor. ${err}`)
            });
    }

    const handleDeleteClose = () => {
        setTriggerDelete(false);
        props.refresh();
    }

    const handleCloseEditModal = () => {
        setTriggerEdit(false);
        props.close();
        props.refresh();
    }

    return (
        <div>
            {props.event && (
                <Dialog
                    fullWidth={true}
                    maxWidth='sm'
                    open={props.triggerEventDialog}
                    onClose={() => props.close()}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogContent>
                        <CssBaseline />
                        <Card elevation={3} className={classes.card}>
                            <CardHeader className={classes.header}
                                avatar={
                                    <Avatar style={{ backgroundColor: stringToColor(props.event.client_name) }}>
                                        {props.event.client_name[0].toUpperCase()}
                                    </Avatar>
                                }
                                title={`Reserva de ${props.event.client_name}`}
                                subheader={props.event.cowork}
                            />
                        </Card>
                        <div style={{ display: 'flex' }}>
                            <Card elevation={3} className={classes.contentCard} >
                                <CardContent className={classes.cardContent}>
                                    <List style={{ paddingBottom: '0' }}>
                                        <ListItem className={classes.listRow}>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <PersonIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Nome" secondary={props.event.client_name} />
                                            </div>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <EmailIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Email" secondary={props.event.client_email} />
                                            </div>
                                        </ListItem>
                                        <Divider light />
                                        <ListItem className={classes.listRow}>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <CoworkIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Cowork" secondary={props.event.cowork} />
                                            </div>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <SeatsIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Numero de Pessoas" secondary={props.event.quantity} />
                                            </div>
                                        </ListItem>
                                        <Divider light />
                                        <ListItem className={classes.listRow}>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <CheckOutIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Data de Entrada" secondary={props.event.checkIn} />
                                            </div>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <CheckOutIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Data de Saida" secondary={props.event.checkOut} />
                                            </div>
                                        </ListItem>
                                        <Divider light />
                                        <ListItem className={classes.listRow}>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <AddedIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Data de Criação" secondary={moment(props.event.createdAt).format('YYYY-MM-DD HH:mm')} />
                                            </div>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <ChangedIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Ultima Alteração" secondary={moment(props.event.updatedAt).format('YYYY-MM-DD HH:mm')} />
                                            </div>
                                        </ListItem>
                                    </List>
                                </CardContent>
                            </Card>
                        </div>
                    </DialogContent>
                    <DialogActions className={classes.cardActions}>
                        <div>
                            <Button style={{ marginRight: '15px' }} color='primary' onClick={() => setTriggerEdit(true)}>Editar</Button>
                            <Button color='primary' onClick={() => setTriggerDelete(true)}>Eliminar</Button>
                        </div>
                        <Button onClick={() => props.close()} color="primary">
                            Fechar
                        </Button>
                    </DialogActions>
                    <FeedBackDialog
                        open={feedbackDialog}
                        close={() => { setFeedbackDialog(false); props.close() }}
                        title={feedbackTitle}
                        content={feedbackContent} />
                    <Dialog
                        open={triggerDelete}
                        onClose={handleDeleteClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{"Tem a certesa que pretende eliminar este registo"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Se eliminar esta reserva não poderá reverter este porcesso. Tem a certesa?
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDeleteClose} color="primary" autoFocus>
                                Cancelar
                            </Button>
                            <Button onClick={handleDelete} color="primary">
                                Eliminar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        fullWidth={true}
                        maxWidth='md'
                        open={triggerEdit}
                        onClose={handleCloseEditModal}
                        aria-labelledby="max-width-dialog-title"
                    >
                        <DialogContent>
                            <EditReservationMain data={props.event} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseEditModal} color="primary">
                                Fechar
                            </Button>
                        </DialogActions>
                    </Dialog>

                </Dialog>
            )}
        </div>
    )
}
import React, { useState, useEffect } from 'react'
import MUIDataTable from 'mui-datatables'
import { FormGroup, FormLabel, TextField, Card, CardHeader, CardContent, CssBaseline } from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Button, Avatar, ListItemText, ListItemAvatar, ListItem, List, Divider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import CoworkIcon from '@material-ui/icons/House'
import CheckOutIcon from '@material-ui/icons/Alarm'
import SeatsIcon from '@material-ui/icons/EventSeat'
import PersonIcon from '@material-ui/icons/Person'
import EmailIcon from '@material-ui/icons/Email'
import Rating from '@material-ui/lab/Rating';

const getMuiTheme = () => createTheme({
    overrides: {
        MUIDataTableBodyCell: {
            root: {
                textAlign: 'center'
            }
        },
        MUIDataTableHeadCell: {
            root: {
                textAlign: 'center',
                paddingLeft: '30px'
            },
            contentWrapper: {
                display: 'unset'
            }
        },
        MuiButton: {
            root: {
                padding: 0
            },
            text: {
                padding: 0
            }
        }
    }
})

const useStyles = makeStyles((theme) => ({
    outterDiv: {
        height: '100%'
    },
    calendar: {
        backgroundColor: 'white',
        height: '92%',
    },
    selection: {
        height: '8%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    navButtons: {
        letterSpacing: 0,
        border: '1px solid rgba(0, 0, 0, 0.2)',
        boxShadow: 'inset 0px 3px 5px rgba(0, 0, 0, 0.13)'

    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    card: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '20px',
        width: '100%'

    },
    header: {
        width: '100%',
        height: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    contentCard: {
        overflowWrap: 'anywhere',
        marginBottom: '0px',
        width: '100%'
    },
    listRow: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center'
    },
    listitems: {
        display: 'flex',
        width: '50%'
    },
    avatarList: {
        display: 'flex',
        alignItems: 'center',
    },
    cardActions: {
        justifyContent: 'space-between',
        padding: '8px 20px 8px 20px'
    },
    calendarAvatar: {
        backgroundColor: 'rgb(187,222,251)',
        color: 'rgb(30,136,229)'
    }
}));

const stringToColor = (string) => {

    if (string === undefined) string = 'No User'

    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    return color
}

export default function ReservationTable(props) {

    const classes = useStyles();

    const [selectedRowData, setSelectedRowData] = useState(null);
    const [openInfoDialog, setOpenInfoDialog] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        setData(props.data)
    }, [props.data])

    useEffect(() => {
        if (selectedRowData !== null) setOpenInfoDialog(true);
    }, [selectedRowData])

    const setActiveInfo = (item) => {
        setSelectedRowData(data.filter(feedback => feedback.id === item[0])[0])
    }

    const columns = [
        {
            name: 'id',
            label: 'ID',
            options: { filter: false }
        },
        {
            name: 'reservation_id',
            label: 'ID Reserva',
            options: { filter: true, searchable: false, filterType: 'textField' }
        },
        {
            name: 'client_name',
            label: 'Nome Cliente',
            options: { filter: true, filterType: 'textField' }
        },
        {
            name: 'client_email',
            label: 'Email Cliente',
            options: { filter: true, filterType: 'textField' }
        },
        {
            name: 'cowork',
            label: 'Cowork',
            options: { searchable: true }
        },
        {
            name: 'rating',
            label: 'Rating',
            options: { searchable: false }
        },
        {
            name: 'checkIn',
            label: 'Check In',
            options: {
                filter: true,
                filterType: 'custom',
                searchable: false,
                customFilterListOptions: {
                    render: v => {
                        if (v[0] && v[1]) {
                            return [`Data Inicio: ${v[0]}`, `Data Fim: ${v[1]}`];
                        } else if (v[0]) {
                            return `Data Inicio: ${v[0]}`;
                        } else if (v[1]) {
                            return `Data Fim: ${v[1]}`;
                        }
                        return [];
                    },
                    update: (filterList, filterPos, index) => {
                        if (filterPos === 0) {
                            filterList[index].splice(filterPos, 1, '');
                        } else if (filterPos === 1) {
                            filterList[index].splice(filterPos, 1);
                        } else if (filterPos === -1) {
                            filterList[index] = [];
                        }

                        return filterList;
                    },
                },
                filterOptions: {
                    names: [],
                    logic(date, filters) {
                        if (filters[0] && filters[1]) {
                            return date < filters[0] || date > filters[1];
                        } else if (filters[0]) {
                            return date < filters[0];
                        } else if (filters[1]) {
                            return date > filters[1];
                        }
                        return false;
                    },
                    display: (filterList, onChange, index, column) => (
                        <div>
                            <FormLabel>Check In</FormLabel>
                            <FormGroup row>
                                <TextField
                                    label='Data inicio'
                                    value={filterList[index][0] || ''}
                                    onChange={event => {
                                        filterList[index][0] = event.target.value;
                                        onChange(filterList[index], index, column)
                                    }}
                                    style={{ width: '45%', marginRight: '5%' }}
                                />
                                <TextField
                                    label='Data Fim'
                                    value={filterList[index][1] || ''}
                                    onChange={event => {
                                        filterList[index][1] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}
                                    style={{ width: '45%' }}
                                />
                            </FormGroup>
                        </div>
                    ),
                },
            },
        },
        {
            name: 'checkOut',
            label: 'Check Out',
            options: {
                filter: true,
                filterType: 'custom',
                searchable: false,
                customFilterListOptions: {
                    render: v => {
                        if (v[0] && v[1]) {
                            return [`Data Inicio: ${v[0]}`, `Data Fim: ${v[1]}`];
                        } else if (v[0]) {
                            return `Data Inicio: ${v[0]}`;
                        } else if (v[1]) {
                            return `Data Fim: ${v[1]}`;
                        }
                        return [];
                    },
                    update: (filterList, filterPos, index) => {
                        if (filterPos === 0) {
                            filterList[index].splice(filterPos, 1, '');
                        } else if (filterPos === 1) {
                            filterList[index].splice(filterPos, 1);
                        } else if (filterPos === -1) {
                            filterList[index] = [];
                        }

                        return filterList;
                    },
                },
                filterOptions: {
                    names: [],
                    logic(date, filters) {
                        if (filters[0] && filters[1]) {
                            return date < filters[0] || date > filters[1];
                        } else if (filters[0]) {
                            return date < filters[0];
                        } else if (filters[1]) {
                            return date > filters[1];
                        }
                        return false;
                    },
                    display: (filterList, onChange, index, column) => (
                        <div>
                            <FormLabel>Check Out</FormLabel>
                            <FormGroup row>
                                <TextField
                                    label='Data inicio'
                                    value={filterList[index][0] || ''}
                                    onChange={event => {
                                        filterList[index][0] = event.target.value;
                                        onChange(filterList[index], index, column)
                                    }}
                                    style={{ width: '45%', marginRight: '5%' }}
                                />
                                <TextField
                                    label='Data Fim'
                                    value={filterList[index][1] || ''}
                                    onChange={event => {
                                        filterList[index][1] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}
                                    style={{ width: '45%' }}
                                />
                            </FormGroup>
                        </div>
                    ),
                },
            },
        },
    ]

    const options = {
        filter: true,
        filterType: 'multiselect',
        responsive: 'standard',
        tableBodyHeight: '450px',
        tableBodyMaxHeigth: '',
        selectableRows: 'none',
        rowsPerPageOptions: [10, 25, 50, 100],
        onRowClick: item => setActiveInfo(item),
        textLabels: {
            body: {
                noMatch: "Sem resultados",
                toolTip: "Ordenar",
                columnHeaderToolTip: column => `Ordenar por ${column.label}`
            },
            pagination: {
                next: 'Proxima',
                previous: 'Anterior',
                rowsPerPage: 'Linhas por pagina',
                displayRows: 'de'
            },
            toolbar: {
                search: 'Procurar',
                downloadCsv: 'Download CSV',
                print: 'Imprimir',
                viewColumns: 'Ver Colunas',
                filterTable: 'Filtrar Tabela'
            },
            filter: {
                all: 'ALL',
                title: 'FILTROS',
                reset: 'RESET'
            },
            viewColumns: {
                title: 'Mostrar Colunas',
                titleAria: 'Mostrar/Esconder Colunas',
            }
        }
    }

    return (
        <>
            <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title={"Feedback de Clientes"}
                    data={data}
                    columns={columns}
                    options={options}
                />
            </MuiThemeProvider>
            {selectedRowData !== null ? (
                <Dialog
                    fullWidth={true}
                    maxWidth='md'
                    open={openInfoDialog}
                    onClose={() => { setOpenInfoDialog(false); setSelectedRowData(null) }}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogContent>
                        <CssBaseline />
                        <Card elevation={3} className={classes.card}>
                            <CardHeader className={classes.header}
                                avatar={
                                    <Avatar style={{ backgroundColor: stringToColor(selectedRowData.client_name) }}>
                                        {selectedRowData.client_name[0].toUpperCase()}
                                    </Avatar>
                                }
                                title={`Feedback de ${selectedRowData.client_name}`}
                                subheader={selectedRowData.cowork}
                            />
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Rating style={{ marginRight: '25px' }} precision={0.5} name="read-only" value={selectedRowData.rating} readOnly />
                                <p style={{ marginRight: '25px' }}>{getRatingText(1, selectedRowData.rating)}</p>
                            </div>
                        </Card>
                        <div style={{ display: 'flex' }}>
                            <Card elevation={3} className={classes.contentCard} >
                                <CardContent className={classes.cardContent}>
                                    <List style={{ paddingBottom: '0' }}>
                                        <ListItem className={classes.listRow}>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <PersonIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Nome" secondary={selectedRowData.client_name} />
                                            </div>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <EmailIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Email" secondary={selectedRowData.client_email} />
                                            </div>
                                        </ListItem>
                                        <Divider light />
                                        <ListItem className={classes.listRow}>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <CoworkIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Cowork" secondary={selectedRowData.cowork} />
                                            </div>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <SeatsIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Numero de Pessoas" secondary={selectedRowData.quantity} />
                                            </div>
                                        </ListItem>
                                        <Divider light />
                                        <ListItem className={classes.listRow}>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <CheckOutIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Data de Entrada" secondary={selectedRowData.checkIn} />
                                            </div>
                                            <div className={classes.listitems}>
                                                <ListItemAvatar className={classes.avatarList}>
                                                    <Avatar className={classes.calendarAvatar}>
                                                        <CheckOutIcon />
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary="Data de Saida" secondary={selectedRowData.checkOut} />
                                            </div>
                                        </ListItem>
                                        <Divider light />
                                        <ListItem className={classes.listRow}>
                                            <ListItemText
                                                style={{ whiteSpace: 'pre-wrap', marginBottom: '15px' }}
                                                primary="Como foi a sua experiência no geral?"
                                                secondary={selectedRowData.firstMessage}
                                            />
                                        </ListItem>
                                        <Divider light />
                                        <ListItem className={classes.listRow}>
                                            <ListItemText
                                                style={{ whiteSpace: 'pre-wrap', marginBottom: '15px' }}
                                                primary="Pensando na sua experiência de trabalho no espaço de Cooperativa Cowork @Aldeias de Montanha tem algum comentário sobre como poderíamos melhorar a sua experiência numa próxima visita?"
                                                secondary={selectedRowData.secondMessage}
                                            />
                                        </ListItem>
                                        <Divider light />
                                        <ListItem className={classes.listRow}>
                                            <ListItemText
                                                style={{ whiteSpace: 'pre-wrap', marginBottom: '15px' }}
                                                primary="Considera que teve tudo o que precisava no espaço de cowork? Se não, o que acrescentaria neste espaço (ex. eletrodomésticos, impressoras...)?"
                                                secondary={selectedRowData.thirdMessage}
                                            />
                                        </ListItem>
                                        <Divider light />
                                        <ListItem className={classes.listRow}>
                                            <ListItemText
                                                style={{ whiteSpace: 'pre-wrap', marginBottom: '15px' }}
                                                primary="Qual é a probabilidade de voltar a trabalhar neste cowork novamente?"
                                                secondary={<div style={{ display: 'flex' }}><Rating style={{ marginRight: '25px' }} name="read-only" value={selectedRowData.rating2} readOnly /><p>{getRatingText(2, selectedRowData.rating2)}</p> </div>}
                                            />
                                        </ListItem>
                                        <Divider light />
                                        <Divider light />
                                        <ListItem className={classes.listRow}>
                                            <ListItemText
                                                style={{ whiteSpace: 'pre-wrap', marginBottom: '15px' }}
                                                primary="Qual é a probabilidade de recomendar o espaço Cooperativa Cowork @Aldeias de Montanha a um amigo ou familiar?"
                                                secondary={<div style={{ display: 'flex' }}><Rating style={{ marginRight: '25px' }} name="read-only" value={selectedRowData.rating3} readOnly /><p>{getRatingText(3, selectedRowData.rating3)}</p> </div>}
                                            />
                                        </ListItem>
                                        <Divider light />
                                        <Divider light />
                                        <ListItem className={classes.listRow}>
                                            <ListItemText
                                                style={{ whiteSpace: 'pre-wrap', marginBottom: '15px' }}
                                                primary="Como classifica a qualidade da internet e dos demais dispositivos disponíveis?"
                                                secondary={<div style={{ display: 'flex' }}><Rating style={{ marginRight: '25px' }} name="read-only" value={selectedRowData.rating4} readOnly /><p>{getRatingText(4, selectedRowData.rating4)}</p> </div>}
                                            />
                                        </ListItem>
                                        <Divider light />
                                    </List>
                                </CardContent>
                            </Card>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setOpenInfoDialog(false); setSelectedRowData(null) }} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : ''}
        </>
    )
}

function getRatingText(question, rating) {
    if (question === 1) {
        switch (rating) {
            case 0.5: return "Horrivel";
            case 1: return "Muito Má";
            case 1.5: return "Má";
            case 2: return "Não muito Boa";
            case 2.5: return "Mais ou menos";
            case 3: return "Boa";
            case 3.5: return "Muito Boa";
            case 4: return "Adorei";
            case 4.5: return "Excelente";
            case 5: return "Magnífico";
            default: return ""
        }
    }

    if (question === 2 || question === 3) {
        switch (rating) {
            case 1: return "nenhuma";
            case 2: return "Pouco Provável";
            case 3: return "Provável";
            case 4: return "Muito Provavel";
            case 5: return "Certamente";
            default: return ""
        }
    }

    if (question === 4) {
        switch (rating) {
            case 1: return "Muio Má";
            case 2: return "Má";
            case 3: return "Razoavel";
            case 4: return "Boa";
            case 5: return "Muito Boa";
            default: return ""
        }
    }
}
import './App.css';
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'

import TopBar from './components/TopBar'
import SideMenu from './components/sidePanel/SideMenu'
import DashboardPage from './pages/Dashboard'
import CoworksPage from './pages/Coworks'
import PartnersPage from './pages/Partners'
import ReservationsPage from './pages/Reservations'
import UsersPage from './pages/Users'
import StorePage from './pages/Store'
import ClientsPage from './pages/Clients'
import Analytics from './pages/Analytics'
import Feedback from './pages/Feedbacks'
import Help from './pages/Help'

import Cookies from 'universal-cookie'
import Profile from './pages/Profile';

const cookies = new Cookies();

function PrivateRoute({ children, auth, ...rest }) {
  return (
    <Route {...rest} render={() => auth ? (
      children
    ) : (
      <Redirect to="/login" />
    )} />
  );
}

function App() {

  let auth = cookies.get('auth') === undefined ? false : true;

  return (
    <div>
      {auth && <TopBar />}
      <div style={{ display: 'flex' }}>
        {auth && <SideMenu />}
        <Switch>
          <PrivateRoute path="/app/dashboard" auth={auth}>
            <DashboardPage />
          </PrivateRoute>
          <PrivateRoute path="/app/coworks" auth={auth}>
            <CoworksPage />
          </PrivateRoute>
          <PrivateRoute path="/app/partners" auth={auth}>
            <PartnersPage />
          </PrivateRoute>
          <PrivateRoute path="/app/reservations" auth={auth}>
            <ReservationsPage />
          </PrivateRoute>
          <PrivateRoute path="/app/store" auth={auth}>
            <StorePage />
          </PrivateRoute>
          <PrivateRoute path="/app/users" auth={auth}>
            <UsersPage />
          </PrivateRoute>
          <PrivateRoute path="/app/profile" auth={auth}>
            <Profile />
          </PrivateRoute>
          <PrivateRoute path="/app/clients" auth={auth}>
            <ClientsPage />
          </PrivateRoute>
          <PrivateRoute path="/app/feedback" auth={auth}>
            <Feedback />
          </PrivateRoute>
          <PrivateRoute path="/app/help" auth={auth}>
            <Help />
          </PrivateRoute>
          <PrivateRoute path="/app/analytics" auth={auth}>
            <Analytics />
          </PrivateRoute>
          <Route exact >
            <Redirect to="/login" />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default App

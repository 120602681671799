import React, { useState, useEffect } from 'react'
import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add'
import { Tooltip } from '@material-ui/core'
import axios from 'axios'
import Cookies from 'universal-cookie'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from '@material-ui/core'

import FeedBackDialog from '../components/ErrorDialog/FeedBackDialog';
import CoworkCard from '../components/cowoks/CoworkCard'
import AddCoworkDialog from '../components/cowoks/AddCoworkDialog';

import './css/Coworks.css'

const cookies = new Cookies();

function Coworks() {

    const [activeUser, setActiveUser] = useState('');
    const [triggerAddDialog, setTriggerAddDialog] = useState(false);
    const [triggerFetch, setTriggerFetch] = useState(false);
    const [coworksData, setCoworksData] = useState([]);

    const [feedbackDialog, setFeedbackDialog] = useState(false);
    const [feedbackTitle, setFeedbackTitle] = useState('');
    const [feedbackContent, setFeedbackContent] = useState('')

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}/api/coworks`, { headers: { 'apikey': cookies.get('key') } })
            .then((res) => { 
                setCoworksData(res.data);
            }, (err) => {
                console.log(err)
                setFeedbackDialog(true);
                setFeedbackTitle('Erro')
                setFeedbackContent(`Não foi possivel comunicar com o servidor. Se o problema persitir contacte o administrador do servidor. ${err}`)
            });
    }, [triggerFetch])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}/api/users/${cookies.get('userID')}`, { headers: { 'apikey': cookies.get('key') } })
            .then((res) => {
                setActiveUser(res.data)
            }, (err) => {
                console.log(err);
                setFeedbackDialog(true);
                setFeedbackTitle('Erro')
                setFeedbackContent(`Não foi possivel comunicar com o servidor. Se o problema persitir contacte o administrador do servidor. ${err}`)
            });
    }, [triggerFetch])

    const refresh = () => {
        setTriggerFetch(!triggerFetch);
    }

    return (
        <div className="cowork-container">
            <div className="cowork-content">
                <div className="util-field-cowork">
                    <div className='cowork-title'>Coworks</div>
                        <Tooltip title="Adicionar novo cowork" placement="left">
                            <Fab style={{ marginRight: '15px' }} color="primary" aria-label="add" onClick={() => setTriggerAddDialog(true)}>
                                <AddIcon />
                            </Fab>
                        </Tooltip>
                </div>
                <div className="bottom-cowork-container">
                    {coworksData.map(cowork => {
                        return (
                            <CoworkCard cowork={cowork} key={cowork.id} activeUser={activeUser} refreshCowork={refresh} />
                        )
                    })}
                </div>
            </div>
            <Dialog
                fullWidth={true}
                maxWidth='md'
                open={triggerAddDialog}
                onClose={() => setTriggerAddDialog(false)}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent>
                    <AddCoworkDialog data={coworksData}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setTriggerAddDialog(false); refresh(); }} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
            <FeedBackDialog
                open={feedbackDialog}
                close={() => setFeedbackDialog(false)}
                title={feedbackTitle}
                content={feedbackContent} />
        </div >
    )
}

export default Coworks

import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

export default class InfoEN extends Component {

    constructor(props) {
        super(props);
        this.state = {
            description_EN: props.defaultData.description_EN,
            profession_EN: props.defaultData.profession_EN,
            formErrors: { description_PT: '', profession_PT: '' },
            description_ENIsValid: true,
            profession_ENIsValid: true,
            formIsValid: true,
        }
    }

    handleInput(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.props.updateMainState(name, value);
        this.setState({ [name]: value }, () => { this.validateFields(name, value) });
    }

    validateFields(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let description_ENIsValid = this.state.description_ENIsValid;
        let profession_ENIsValid = this.state.profession_ENIsValid;

        switch (fieldName) {
            case 'description_EN':
                description_ENIsValid = value.length > 1;
                fieldValidationErrors.description_EN = description_ENIsValid ? '' : 'Deve escrever uma descrição'
                break;
            case 'profession_EN':
                profession_ENIsValid = new RegExp(/^[a-zA-Z\u00C0-\u00FF\s*]*$/).test(value);
                fieldValidationErrors.profession_EN = profession_ENIsValid ? '' : 'Profissão inválida'
                break;
            default:
                break;
        }


        this.setState({
            formErrors: fieldValidationErrors,
            description_ENIsValid: description_ENIsValid,
            profession_ENIsValid: profession_ENIsValid
        }, this.validateForm)

    }

    validateForm() {
        this.setState({
            formIsValid:
                this.state.description_ENIsValid &&
                this.state.profession_ENIsValid
        }, () => {
            this.props.updateMainState('validForm', this.state.formIsValid);
        })
    }

    render() {
        return (
            <React.Fragment>
                <Grid container spacing={3}>   
                    <Grid item xs={12} sm={5}>
                        <TextField
                            required
                            error={!this.state.profession_ENIsValid ? true : false}
                            helperText={this.state.formErrors.profession_EN}
                            id="profession_EN"
                            name="profession_EN"
                            label="Profession"
                            type='text'
                            fullWidth
                            defaultValue={this.state.profession_EN}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            required
                            multiline
                            rows={8}
                            error={!this.state.description_ENIsValid ? true : false}
                            helperText={this.state.formErrors.description_EN}
                            id="description_EN"
                            name="description_EN"
                            label="Description"
                            type='text'
                            fullWidth
                            defaultValue={this.state.description_EN}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}
import React from 'react'
import MUIDataTable from 'mui-datatables'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { FormGroup, FormLabel, TextField } from '@material-ui/core';

function ShopItemsTable(props) {

    const getMuiTheme = () => createTheme({
        overrides: {
            MUIDataTableBodyCell: {
                root: {
                    textAlign: 'center'
                }
            },
            MUIDataTableHeadCell: {
                root: {
                    textAlign: 'center',
                    paddingLeft: '30px'
                },
                contentWrapper: {
                    display: 'unset'
                }
            },
            MuiButton: {
                root: {
                    padding: 0
                },
                text: {
                    padding: 0
                }
            }
        }
    })

    const handleRowClick = (rowData, rowMeta) => {
        props.getCurrentData(props.data[rowMeta.dataIndex]);
    }

    const transformString = (string) => {
        switch (string) {
            case 'mobiliario': return 'Mobiliário';
            case 'iluminaria': return 'Iluminária';
            case 'tapecaria': return 'Tapeçaria';
            case 'decoracao': return 'Decoração';
            default: return 'Not a valid string'
        }
    }

    const columns = [
        {
            name: 'id',
            label: 'ID',
            options: { filter: false }
        },
        {
            name: 'name_PT',
            label: 'Nome',
            options: { filter: false }
        },
        {
            name: 'materials_PT',
            label: 'Material',
            options: { filter: true }
        },
        {
            name: 'price',
            label: 'Preço',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value + ' €'
                },
                searchable: false,
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: v => {
                        if (v[0] && v[1]) {
                            return [`Min: ${v[0]}`, `Max: ${v[1]}`];
                        } else if (v[0]) {
                            return `Min: ${v[0]}`;
                        } else if (v[1]) {
                            return `Max: ${v[1]}`;
                        }
                        return [];
                    },
                    update: (filterList, filterPos, index) => {
                        if (filterPos === 0) {
                            filterList[index].splice(filterPos, 1, '');
                        } else if (filterPos === 1) {
                            filterList[index].splice(filterPos, 1);
                        } else if (filterPos === -1) {
                            filterList[index] = [];
                        }

                        return filterList;
                    },
                },
                filterOptions: {
                    names: [],
                    logic(value, filters) {
                        if (filters[0] && filters[1]) {
                            return value <= filters[0] || value >= filters[1];
                        } else if (filters[0]) {
                            return value <= filters[0];
                        } else if (filters[1]) {
                            return value >= filters[1];
                        }
                        return false;
                    },
                    display: (filterList, onChange, index, column) => (
                        <div>
                            <FormLabel>Preço</FormLabel>
                            <FormGroup row>
                                <TextField
                                    label='Min'
                                    value={filterList[index][0] || ''}
                                    onChange={event => {
                                        filterList[index][0] = event.target.value;
                                        onChange(filterList[index], index, column)
                                    }}
                                    style={{ width: '25%', marginRight: '5%' }}
                                />
                                <TextField
                                    label='Max'
                                    value={filterList[index][1] || ''}
                                    onChange={event => {
                                        filterList[index][1] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}
                                    style={{ width: '25%' }}
                                />
                            </FormGroup>
                        </div>
                    ),
                },
            },
        },
        {
            name: 'delivery_time',
            label: 'Entrega',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value + ' Dias'
                },
                searchable: false,
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: v => {
                        if (v[0] && v[1]) {
                            return [`Min: ${v[0]}`, `Max: ${v[1]}`];
                        } else if (v[0]) {
                            return `Min: ${v[0]}`;
                        } else if (v[1]) {
                            return `Max: ${v[1]}`;
                        }
                        return [];
                    },
                    update: (filterList, filterPos, index) => {
                        if (filterPos === 0) {
                            filterList[index].splice(filterPos, 1, '');
                        } else if (filterPos === 1) {
                            filterList[index].splice(filterPos, 1);
                        } else if (filterPos === -1) {
                            filterList[index] = [];
                        }

                        return filterList;
                    },
                },
                filterOptions: {
                    names: [],
                    logic(value, filters) {
                        if (filters[0] && filters[1]) {
                            return value <= filters[0] || value >= filters[1];
                        } else if (filters[0]) {
                            return value <= filters[0];
                        } else if (filters[1]) {
                            return value >= filters[1];
                        }
                        return false;
                    },
                    display: (filterList, onChange, index, column) => (
                        <div>
                            <FormLabel>Tempo de Entrega (Dias)</FormLabel>
                            <FormGroup row>
                                <TextField
                                    label='Min'
                                    value={filterList[index][0] || ''}
                                    onChange={event => {
                                        filterList[index][0] = event.target.value;
                                        onChange(filterList[index], index, column)
                                    }}
                                    style={{ width: '25%', marginRight: '5%' }}
                                />
                                <TextField
                                    label='Max'
                                    value={filterList[index][1] || ''}
                                    onChange={event => {
                                        filterList[index][1] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}
                                    style={{ width: '25%' }}
                                />
                            </FormGroup>
                        </div>
                    ),
                },
            }
        },
        {
            name: 'category',
            label: 'Categoria',
            options: {
                searchable: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return transformString(value);
                },
                filterType: 'checkbox'
            }
        }
    ]

    const options = {
        filter: true,
        filterType: 'textField',
        responsive: 'standard',
        tableBodyHeight: '450px',
        tableBodyMaxHeigth: '',
        selectableRows: 'none',
        rowsPerPageOptions: [10, 25, 50, 100],
        onRowClick: handleRowClick,
        textLabels: {
            body: {
                noMatch: "Sem resultados",
                toolTip: "Ordenar",
                columnHeaderToolTip: column => `Ordenar por ${column.label}`
            },
            pagination: {
                next: 'Proxima',
                previous: 'Anterior',
                rowsPerPage: 'Linhas por pagina',
                displayRows: 'de'
            },
            toolbar: {
                search: 'Procurar',
                downloadCsv: 'Download CSV',
                print: 'Imprimir',
                viewColumns: 'Ver Colunas',
                filterTable: 'Filtrar Tabela'
            },
            filter: {
                all: 'ALL',
                title: 'FILTROS',
                reset: 'RESET'
            },
            viewColumns: {
                title: 'Mostrar Colunas',
                titleAria: 'Mostrar/Esconder Colunas',
            }
        }
    }

    return (
        <>
            <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title={"Parceiros Individuais"}
                    data={props.data}
                    columns={columns}
                    options={options}
                />
            </MuiThemeProvider>
        </>
    )
}

export default ShopItemsTable
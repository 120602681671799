import React, { Component } from 'react'
import { MenuItem, Tooltip, Button, InputLabel, Select, TextField, Grid } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { amenities } from '../../Data/Amenities';

export default class InfoPT extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: props.defaultData.title,
            location: props.defaultData.location,
            short_description_PT: props.defaultData.short_description_PT,
            full_description_PT: props.defaultData.full_description_PT,
            capacity: props.defaultData.capacity,
            vrUrl: props.defaultData.vrUrl,
            online: props.defaultData.online,
            address: props.defaultData.address,
            coordinates: props.defaultData.coordinates,
            amenities: props.defaultData.amenities,
            formErrors: { title: '', location: '', short_description_PT: '', full_description_PT: '', vrUrl: '', capacity: '', coordinates: '', address: '' },
            titleIsValid: true,
            locationIsValid: true,
            vrUrlIsValid: true,
            capacityIsValid: true,
            tncPDFpt: props.defaultData.tncPDFpt,
            short_description_PTIsValid: true,
            full_description_PTIsValid: true,
            addressIsValid: true,
            coordinatesIsValid: true,
            formIsValid: true,
        }
    }

    handlePDFUpload = (event) => {
        this.props.updateMainState('tncPDFpt', event.target.files[0]);
        this.setState({ tncPDFpt: event.target.files[0] }, () => this.validateForm())
    }

    handleAutocompleteInput = (value) => {
        this.props.updateMainState('amenities', value)
        this.setState({ amenities: value })
    }

    handleInput(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.props.updateMainState(name, value);
        this.setState({ [name]: value }, () => { this.validateFields(name, value) });
    }

    validateFields(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let titleIsValid = this.state.titleIsValid;
        let vrUrlIsValid = this.state.vrUrlIsValid;
        let locationIsValid = this.state.locationIsValid;
        let capacityIsValid = this.state.capacityIsValid;
        let addressIsValid = this.state.addressIsValid;
        let coordinatesIsValid = this.state.coordinatesIsValid;
        let short_description_PTIsValid = this.state.short_description_PTIsValid;
        let full_description_PTIsValid = this.state.full_description_PTIsValid;

        switch (fieldName) {
            case 'title':
                titleIsValid = new RegExp(/^[a-zA-Z\u00C0-\u00FF\s*]*$/).test(value) && value.length > 4
                fieldValidationErrors.title = titleIsValid ? '' : 'Nome Invalido'
                break;
            case 'vrUrl':
                vrUrlIsValid = new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/).test(value) && value.length > 1;
                fieldValidationErrors.vrUrl = vrUrlIsValid ? '' : 'Webpage URL Invalido, só podem ser adicionados URL começados por http:// ou https://. Ex: https://meuwebsite.com'
                break;
            case 'location':
                locationIsValid = new RegExp(/^[a-zA-Z\u00C0-\u00FF\s*]*$/).test(value) && value.length > 1;
                fieldValidationErrors.location = locationIsValid ? '' : 'Localização Inválida'
                break;
            case 'capacity':
                capacityIsValid = value >= 1;
                fieldValidationErrors.capacity = capacityIsValid ? '' : 'Capacidade de Lotação inválida'
                break;
            case 'address':
                addressIsValid = value.length > 5;
                fieldValidationErrors.address = addressIsValid ? '' : 'Morada Inválida'
                break;
            case 'coordinates':
                coordinatesIsValid = new RegExp(/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/).test(value)
                fieldValidationErrors.coordinates = coordinatesIsValid ? '' : 'Coordenadas Inválidas'
                break;
            case 'short_description_PT':
                short_description_PTIsValid = value.length > 1;
                fieldValidationErrors.short_description_PT = short_description_PTIsValid ? '' : 'Deve escrever uma descrição'
                break;
            case 'full_description_PT':
                full_description_PTIsValid = value.length > 1;
                fieldValidationErrors.full_description_PT = full_description_PTIsValid ? '' : 'Deve escrever uma descrição'
                break;
            default:
                break;
        }


        this.setState({
            formErrors: fieldValidationErrors,
            titleIsValid: titleIsValid,
            vrUrlIsValid: vrUrlIsValid,
            capacityIsValid: capacityIsValid,
            locationIsValid: locationIsValid,
            coordinatesIsValid: coordinatesIsValid,
            addressIsValid: addressIsValid,
            short_description_PTIsValid: short_description_PTIsValid,
            full_description_PTIsValid: full_description_PTIsValid
        }, this.validateForm)

    }

    validateForm() {
        this.setState({
            formIsValid:
                this.state.titleIsValid &&
                this.state.locationIsValid &&
                this.state.vrUrlIsValid &&
                this.state.capacityIsValid &&
                this.state.addressIsValid &&
                this.state.coordinatesIsValid &&
                this.state.short_description_PTIsValid &&
                this.state.full_description_PTIsValid
        }, () => {
            this.props.updateMainState('validForm', this.state.formIsValid);
        })
    }

    render() {
        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            error={!this.state.titleIsValid ? true : false}
                            helperText='Nome não pode ser alterado'
                            disabled
                            id="title"
                            name="title"
                            label="Nome do cowork"
                            fullWidth
                            type="text"
                            defaultValue={this.state.title}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputLabel id="location">Localização</InputLabel>
                        <Select
                            required
                            fullWidth
                            labelId="location"
                            id="location"
                            name="location"
                            value={this.state.location}
                            onChange={(e) => this.handleInput(e)}
                            displayEmpty
                        >
                            <MenuItem value='Celorico da Beira'>Celorico da Beira</MenuItem>
                            <MenuItem value='Guarda'>Guarda</MenuItem>
                            <MenuItem value='Fornos de Algodres'>Fornos de Algodres</MenuItem>
                            <MenuItem value='Oliveira do Hospital'>Oliveira do Hospital</MenuItem>
                            <MenuItem value='Seia'>Seia</MenuItem>
                            <MenuItem value='Gouveia'>Gouveia</MenuItem>
                            <MenuItem value='Manteigas'>Manteigas</MenuItem>
                            <MenuItem value='Covilhã'>Covilhã</MenuItem>
                            <MenuItem value='Fundão'>Fundão</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            required
                            error={!this.state.vrUrlIsValid ? true : false}
                            helperText={this.state.formErrors.vrUrl}
                            id="vrUtl"
                            name="vrUrl"
                            label="360 View URL"
                            type='text'
                            fullWidth
                            defaultValue={this.state.vrUrl}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            required
                            error={!this.state.capacityIsValid ? true : false}
                            helperText={this.state.formErrors.capacity}
                            id="capacity"
                            name="capacity"
                            label="Lotação"
                            type='number'
                            fullWidth
                            defaultValue={this.state.capacity}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <InputLabel id="online">Disponivel?</InputLabel>
                        <Select
                            required
                            fullWidth
                            labelId="online"
                            id="online"
                            name="online"
                            value={this.state.online}
                            onChange={(e) => this.handleInput(e)}
                        >
                            <MenuItem value={true}>Sim</MenuItem>
                            <MenuItem value={false}>Não</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ paddingRight: '0' }}>
                        <TextField
                            required
                            id="tnc"
                            name="tnc"
                            label="Termos de utilização"
                            type='text'
                            fullWidth
                            value={this.state.tncPDFpt === null ? 'Deixar vazio se não quiser alterar' : this.state.tncPDFpt.name}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={2} style={{ marginBottom: '-12px', padding: '0', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Tooltip title='Upload PDF dos termos de utilização'>
                            <label htmlFor="upload-photo">
                                <input
                                    style={{ display: 'none' }}
                                    id="upload-photo"
                                    name="upload-photo"
                                    type="file"
                                    accept='application/pdf'
                                    onChange={e => this.handlePDFUpload(e)}
                                />
                                <Button size='small' color="primary" variant="contained" component="span">
                                    <Add />
                                </Button>
                            </label>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            required
                            error={!this.state.addressIsValid ? true : false}
                            helperText={this.state.formErrors.address}
                            id="address"
                            name="address"
                            label="Morada"
                            type='text'
                            fullWidth
                            defaultValue={this.state.address}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            error={!this.state.coordinatesIsValid ? true : false}
                            helperText={this.state.formErrors.coordinates}
                            id="coordinates"
                            name="coordinates"
                            label="Coordenadas"
                            type='text'
                            placeholder='+32.30642, -122.61458'
                            fullWidth
                            defaultValue={this.state.coordinates}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            multiple
                            sx={{ width: '50%' }}
                            id="amenities"
                            options={amenities}
                            disableCloseOnSelect
                            limitTags={1}
                            openOnFocus
                            value={this.state.amenities}
                            onChange={(e, newValue) => this.handleAutocompleteInput(newValue)}
                            getOptionLabel={(option) => option.namePT}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderInput={(params) => (
                                <TextField fullWidth {...params} label='Comodidades' variant='standard' color='secondary' />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            variant='outlined'
                            required
                            multiline
                            rows={3}
                            error={!this.state.short_description_PTIsValid ? true : false}
                            helperText={this.state.formErrors.short_description_PT}
                            id="short_description_PT"
                            name="short_description_PT"
                            label="Descrição curta (200 caracteres Max)"
                            type='text'
                            inputProps={{ maxLength: 200 }}
                            fullWidth
                            defaultValue={this.state.short_description_PT}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            variant='outlined'
                            required
                            multiline
                            rows={10}
                            error={!this.state.full_description_PTIsValid ? true : false}
                            helperText={this.state.formErrors.full_description_PT}
                            id="full_description_PT"
                            name="full_description_PT"
                            label="Descrição completa"
                            type='text'
                            fullWidth
                            defaultValue={this.state.full_description_PT}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}
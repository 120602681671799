import React from 'react';
import { Doughnut } from 'react-chartjs-2'


const splitString = (arr) => {
    arr.forEach((item, index) => {
        if (item.match(/[A-Z][a-z]+|[0-9]+/g)) {
            arr[index] = item.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
        } else {
            arr[index] = item
        }
    });
    return arr;
}

const randomRGB = () => {
    const o = Math.round, r = Math.random, s = 255;
    return 'rgb(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ')';
}

const getColors = (arr) => {
    const arrayOfColors = ['rgb(255,99,132)', 'rgb(75,192,192)', 'rgb(255,205,86)', 'rgb(255,146,37)', 'rgb(54,162,235)', 'rgb(153,102,255)', 'rgb(255,51,51)', 'rgb(46,199,192)', 'rgb(255,215,181)', 'rgb(193,170,160)', 'rgb(227,187,225)', 'rgb(154,255,153)']

    arr.forEach((item, index) => {
        arr[index] = arrayOfColors[index] !== undefined ? arrayOfColors[index] : randomRGB();
    })
    return arr;
}


function Graph(props) {
    const state = {
        labels: splitString(Object.keys(props.data)),
        datasets: [
            {
                backgroundColor: getColors(Object.keys(props.data)),
                data: Object.values(props.data)
            }
        ],
    }

    return (
        <div>
            <Doughnut
                data={state}
                width={420}
                height={250}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: 'right',
                        },
                        title: {
                            display: true,
                            position: 'top',
                            text: ['Reservas por Cowork', ' '],
                            padding: 0
                        }
                    }
                }}
            />
        </div>
    )
}

export default Graph;
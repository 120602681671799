import React from 'react'
import MUIDataTable from 'mui-datatables'
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';

export default function PartnersTable(props) {

    const getMuiTheme = () => createTheme({
        overrides: {
            MUIDataTableBodyCell: {
                root: {
                    textAlign: 'center'
                }
            },
            MUIDataTableHeadCell: {
                root: {
                    textAlign: 'center',
                    paddingLeft: '30px'
                },
                contentWrapper: {
                    display: 'unset'
                }
            },
            MuiButton: {
                root: {
                    padding: 0
                },
                text: {
                    padding: 0
                }
            }
        }
    })

    const handleRowClick = (rowData, rowMeta) => {
        props.getCurrentData(props.data[rowMeta.dataIndex]);
    }

    const columns = [
        {
            name: 'id',
            label: 'ID',
            options: { filter: false }
        },
        {
            name: 'name',
            label: 'Nome',
            options: { filter: true }
        },
        {
            name: 'location',
            label: 'Localização',
            options: { filter: true, filterType:'multiselect' }
        },
        {
            name: 'profession_PT',
            label: 'Profissão',
            options: { filter: true, filterType: 'multiselect' }
        }
    ]

    const options = {
        filter: true,
        filterType: 'textField',
        responsive: 'standard',
        tableBodyHeight: '450px',
        tableBodyMaxHeigth: '',
        selectableRows: 'none',
        rowsPerPageOptions: [10, 25, 50, 100],
        onRowClick: handleRowClick,
        textLabels: {
            body: {
                noMatch: "Sem resultados",
                toolTip: "Ordenar",
                columnHeaderToolTip: column => `Ordenar por ${column.label}`
            },
            pagination: {
                next: 'Proxima',
                previous: 'Anterior',
                rowsPerPage: 'Linhas por pagina',
                displayRows: 'de'
            },
            toolbar: {
                search: 'Procurar',
                downloadCsv: 'Download CSV',
                print: 'Imprimir',
                viewColumns: 'Ver Colunas',
                filterTable: 'Filtrar Tabela'
            },
            filter: {
                all: 'ALL',
                title: 'FILTROS',
                reset: 'RESET'
            },
            viewColumns: {
                title: 'Mostrar Colunas',
                titleAria: 'Mostrar/Esconder Colunas',
            }
        }
    }

    return (
        <>
            <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title={"Parceiros Individuais"}
                    data={props.data}
                    columns={columns}
                    options={options}
                />
            </MuiThemeProvider>
        </>
    )
}
import React, { Component } from 'react'
import axios from 'axios'
import { Redirect, Link } from 'react-router-dom'

import { MuiThemeProvider, createTheme, withStyles } from '@material-ui/core/styles';
import { TextField, Button, CircularProgress, Dialog, DialogContent, Typography, DialogActions, IconButton, InputAdornment, Box, Container, CssBaseline } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons';

import logo from '../assets/login/home.png'
import './css/PassReset.css'

let timeout;

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        width: '100px',
        height: 'auto'
    },
    form: {
        width: '400px',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
        width: '100%'

    },
    box: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(2, 0, 2)
    },
    backButton: {
        width: '300px'
    },
});

const theme = createTheme({
    overrides: {
        MuiOutlinedInput: {
            notchedOutline: {
                borderColor: '#1eb02d'
            }
        },
        MuiFormLabel: {
            root: {
                color: '#cccccc',
                '&$focused': {
                    color: '#181048'
                }
            }
        }
    }
})


class PassResetPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submiting: false,
            fireRedirect: false,
            userEmail: '',
            secret: '',
            messagePT: '',
            openSnackbar: false,
            formValid: false,
            formErrors: { newpass: '', confirmpass: '' },
            newpass: '',
            confirmpass: '',
            newpassValid: false,
            confirmpassValid: false,
            openDialog: false,
            showPassword: false,
            showPassword1: false
        }
    }

    componentDidMount() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        if (urlParams.get('email') === null || urlParams.get('secret') === null) {
            this.setState({ fireRedirect: true });
            return;
        }

        axios.get(`${process.env.REACT_APP_SERVER_URL}/api/user/getbyemail/${urlParams.get('email')}`)
            .then(res => {
                if (res.data[0].passRecoveryKey !== urlParams.get('secret')) {
                    this.setState({ fireRedirect: true });
                }
            }, err => {
                this.setState({ fireRedirect: true });
            })

        this.setState({
            userEmail: urlParams.get('email'),
            secret: urlParams.get('secret')
        })
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ submiting: true })

        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/resetpassword`, {
            email: this.state.userEmail,
            password: event.target[0].value,
            secretkey: this.state.secret
        }).then(res => {
            this.setState({
                submiting: false,
                openDialog: true,
                messagePT: ' Password Alterada com sucesso, irá receber um email de confirmação e será redirecionado para a pagina de login'
            })
        }, err => {
            console.log(err)
            this.setState({
                submiting: false,
                openDialog: true,
                messagePT: 'Erro, tente novamente mais tarde'
            })
        });
    }

    handleInput(event) {

        if (timeout) clearTimeout(timeout);

        const name = event.target.name;
        const value = event.target.value;

        this.setState({ [name]: value }, () => {
            timeout = setTimeout(() => {
                this.validateFields(name, value);
            }, 200)
        });
    }

    validateFields(fieldName, value) {

        let fieldValidationErrors = this.state.formErrors;
        let newpassValid = this.state.newpassValid;
        let confirmpassValid = this.state.confirmpassValid;

        switch (fieldName) {
            case 'newpass':
                this.setState({ confirmpass: '' })
                confirmpassValid = false
                newpassValid = new RegExp(/^(?=(.*[a-z]){3,})(?=(.*[A-Z]){2,})(?=(.*[0-9]){2,}).{8,}$/).test(value);
                fieldValidationErrors.newpass = newpassValid ? '' : 'Password deve conter no minimo 2 maiúsculas, 2 números, 3 minúsculas e mais de 8 caracteres';
                break;
            case 'confirmpass':
                confirmpassValid = value === this.state.newpass;
                fieldValidationErrors.confirmpass = confirmpassValid ? '' : 'Passwords não são coincidem'
                break;
            default:
                break
        }
        this.setState({
            formErrors: fieldValidationErrors,
            newpassValid: newpassValid,
            confirmpassValid: confirmpassValid,
        }, () => this.validateForm())
    }

    validateForm() {
        this.setState({
            formValid:
                this.state.newpassValid &&
                this.state.confirmpassValid
        })
    }

    render() {

        const { classes } = this.props;

        return (
            <Container component="main" maxWidth="md">
                <CssBaseline />
                <div className={classes.paper}>
                    <Link to="/"><img src={logo} alt="" className={classes.avatar} style={{ marginBottom: '20px' }} /></Link>

                    <Typography style={{ marginTop: '10px', textAlign: 'center' }}> Defina a sua nova Password </Typography>
                    <form className={classes.form} onSubmit={this.handleSubmit}>
                        <MuiThemeProvider theme={theme}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                variant='outlined'
                                id="newpass"
                                label='Nova Password'
                                name="newpass"
                                type={this.state.showPassword ? 'text' : 'password'}
                                autoComplete="current-password"
                                value={this.state.newpass}
                                onChange={(e) => this.handleInput(e)}
                                error={!this.state.newpassValid ? true : false}
                                helperText={this.state.formErrors.newpass}
                                onCopy={this.preventEvent}
                                onCut={this.preventEvent}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                tabIndex='-1'
                                                aria-label='toggle password visibility'
                                                onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                                            >
                                                {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <TextField
                                margin="normal"
                                variant='outlined'
                                required
                                fullWidth
                                name="confirmpass"
                                label='Confirme a sua nova Password'
                                type={this.state.showPassword1 ? 'text' : 'password'}
                                id="confirmpass"
                                autoComplete="current-password"
                                onChange={(e) => this.handleInput(e)}
                                value={this.state.confirmpass}
                                error={!this.state.confirmpassValid ? true : false}
                                helperText={this.state.formErrors.confirmpass}
                                onCopy={this.preventEvent}
                                onCut={this.preventEvent}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton
                                                tabIndex='-1'
                                                aria-label='toggle password visibility'
                                                onClick={() => this.setState({ showPassword1: !this.state.showPassword1 })}
                                            >
                                                {this.state.showPassword1 ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </MuiThemeProvider>
                        {this.state.submiting ? <CircularProgress color="secondary" style={{ marginTop: '25px', position: 'relative', left: '50%' }} /> : (
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={!this.state.formValid}
                            >
                                Submeter
                            </Button>
                        )}
                    </form>
                </div>
                <Dialog
                    maxWidth='md'
                    open={this.state.openDialog}
                    onClose={() => this.setState({ fireRedirect: true })}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogContent>
                        <Typography variant='subtitle1'>{this.state.messagePT}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ fireRedirect: true })} color="secondary">
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>
                {this.state.fireRedirect ? (<Redirect to='/' />) : ''}
            </Container>
        )
    }
}

export default withStyles(styles)(PassResetPage)
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List';
import axios from 'axios'
import Cookies from 'universal-cookie'
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ServerResponse from '../ErrorDialog/ServerResponse'


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const useStyles_review = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
}));

const steps = ['Editar Info', 'Confirmar Info'];

const cookies = new Cookies();

let globalState = null;

function ReviewData(props) {
  const classes = useStyles_review();

  const data = globalState === null ? props.data : globalState;

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Confirme os dados
      </Typography>
      <List disablePadding>
        <ListItem className={classes.listItem} key={data.username}>
          <ListItemText primary="Nome" />
          <Typography variant="body2">{data.username}</Typography>
        </ListItem>
        <ListItem className={classes.listItem} key={data.email}>
          <ListItemText primary="Email" />
          <Typography variant="body2">{data.email}</Typography>
        </ListItem>
        <ListItem className={classes.listItem} key={data.userType}>
          <ListItemText primary="Privilégios" />
          <Typography variant="body2">{data.userType}</Typography>
        </ListItem>
      </List>
    </React.Fragment>
  );
}

class FillData extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.data.id,
      username: props.data.username,
      email: props.data.email,
      userType: props.data.userType,
      formErrors: { username: '', email: '' },
      usernameIsValid: true,
      emailIsValid: true,
      formIsValid: true,
    }
  }

  handleInput(event) {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({ [name]: value }, () => { this.validateFields(name, value) });
  }

  validateFields(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let usernameIsValid = this.state.usernameIsValid;
    let emailIsValid = this.state.emailIsValid;

    switch (fieldName) {
      case 'username':
        usernameIsValid = new RegExp(/^[a-zA-Z\u00C0-\u00FF]{3,}[\s]{1}[a-zA-Z\u00C0-\u00FF]{1,}$/).test(value) && value.length > 4;
        fieldValidationErrors.username = usernameIsValid ? '' : 'Nome Inválido'
        break;
      case 'email':
        emailIsValid = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value);
        fieldValidationErrors.email = emailIsValid ? '' : 'Email inválido'
        break;
      default:
        break;
    }


    this.setState({
      formErrors: fieldValidationErrors,
      usernameIsValid: usernameIsValid,
      emailIsValid: emailIsValid,
    }, this.validateForm)

  }

  validateForm() {
    this.setState({
      formIsValid:
        this.state.usernameIsValid && this.state.emailIsValid
    }, () => {
      globalState = this.state;
      this.props.setformValid(this.state.formIsValid)
    })
  }

  render() {
    return (
      <React.Fragment>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={5}>
            <TextField
              required
              error={!this.state.usernameIsValid ? true : false}
              helperText='Nome não pode ser alterado'
              disabled
              id="username"
              name="username"
              label="Nome (primeiro e último)"
              fullWidth
              type="text"
              defaultValue={this.state.username}
              autoComplete='given-name'
              onChange={(e) => this.handleInput(e)}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <TextField
              required
              error={!this.state.emailIsValid ? true : false}
              helperText={this.state.formErrors.email}
              id="email"
              name="email"
              label="Email"
              type='email'
              fullWidth
              autoComplete="email"
              defaultValue={this.state.email}
              onChange={(e) => this.handleInput(e)}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <InputLabel id="userType">Privilégios</InputLabel>
            <Select
              required
              fullWidth
              labelId="userType"
              id="userType"
              name="userType"
              value={this.state.userType}
              onChange={(e) => this.handleInput(e)}
            >
              <MenuItem value='User'>User</MenuItem>
              <MenuItem value='Admin'>Admin</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}


export default function EditUserDialog(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [validForm, setValidForm] = React.useState(false);
  const [feedbackMessage, setFeedbackMessage] = React.useState('')
  const [feedbackSubMessage, setFeedbackSubMessage] = React.useState('')

  let state = {
    id: props.data.id,
    username: props.data.username,
    email: props.data.email,
    userType: props.data.userType,
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <FillData data={state} setformValid={getFormInfo} />;
      case 1:
        return <ReviewData data={state} />;
      default:
        throw new Error('Unknown step');
    }
  }

  const getFormInfo = (info) => {
    setValidForm(info)
  }

  const submitInfoToServer = () => {

    axios.put(`${process.env.REACT_APP_SERVER_URL}/api/users/${globalState.id}`, {
      id: globalState.id,
      username: globalState.username,
      email: globalState.email,
      userType: globalState.userType,
    }, { headers: { 'apikey': cookies.get('key') } })
      .then((res) => {
        setFeedbackMessage(`User ${globalState.username} editado com sucesso.`)
        setFeedbackSubMessage(`Mensagem do servidor: ${res.data}`)
        setActiveStep(activeStep + 1);
      }, (err) => {
        console.log(err)
        setFeedbackMessage(`Não foi possivel editar o usuario, se o problema percistir contacte o administrador do servidor, ${err}`)
        setFeedbackSubMessage(`Mensage do servidor: ${err.response ? err.response.data : err}`)
        setActiveStep(activeStep + 1);
      });

  }

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };


  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="absolute" color="default" className={classes.appBar}>
      </AppBar>
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            {`Editar ${props.data.username}`}
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <ServerResponse message={feedbackMessage} submessage={feedbackSubMessage} />
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} className={classes.button}>
                      Anterior
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={activeStep === steps.length - 1 ? submitInfoToServer : handleNext}
                    className={classes.button}
                    disabled={validForm ? false : true}
                  >
                    {activeStep === steps.length - 1 ? 'Submeter' : 'Seguinte'}
                  </Button>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      </main>
    </React.Fragment>
  );
}
import React from 'react'

import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List';

export default class Review extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: props.data.title,
            location: props.data.location,
            vrUrl: props.data.vrUrl,
            capacity: props.data.capacity,
            online: props.data.online,
            address: props.data.address,
            coordinates: props.data.coordinates,
            short_description_PT: props.data.short_description_PT,
            short_description_EN: props.data.short_description_EN,
            full_description_PT: props.data.full_description_PT,
            full_description_EN: props.data.full_description_EN,
            tncPDFpt: props.data.tncPDFpt,
            tncPDFen: props.data.tncPDFen,
            portrait: props.data.portrait,
            sliderImage1: props.data.sliderImage1,
            sliderImage2: props.data.sliderImage2,
            sliderImage3: props.data.sliderImage3,
            blueprint: props.data.blueprint,
        }
    }

    componentDidMount() {
        this.props.updateMainState('formValid', true);
    }

    transformString(string, maxChar, insertDots) {
        return string.slice(0, maxChar) + (((string.length > maxChar) && insertDots) ? '...' : '');
    }

    render() {
        return (
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    Confirme os dados
                </Typography>
                <List disablePadding>
                    <ListItem key={this.state.title}>
                        <ListItemText primary="Nome" />
                        <Typography variant="body2">{this.state.title}</Typography>
                    </ListItem>
                    <ListItem key={this.state.location}>
                        <ListItemText primary="Locatização" />
                        <Typography variant="body2">{this.state.location}</Typography>
                    </ListItem>
                    <ListItem key={this.state.vrUrl}>
                        <ListItemText primary="360 URL" />
                        <Typography variant="body2">{this.transformString(this.state.vrUrl, 30, true)}</Typography>
                    </ListItem>
                    <ListItem key={this.state.capacity}>
                        <ListItemText primary="Lotação Total" />
                        <Typography variant="body2">{this.state.capacity + ' Lugares'}</Typography>
                    </ListItem>
                    <ListItem key={this.state.online}>
                        <ListItemText primary="Online?" />
                        <Typography variant="body2">{this.state.online ? 'Sim' : 'Não'}</Typography>
                    </ListItem>
                    <ListItem key={this.state.address}>
                        <ListItemText primary="Morada" />
                        <Typography variant="body2">{this.state.address}</Typography>
                    </ListItem>
                    <ListItem key={this.state.coordinates}>
                        <ListItemText primary="Coordenadas" />
                        <Typography variant="body2">{this.state.coordinates}</Typography>
                    </ListItem>
                    {this.state.tncPDFpt === null ? '' : (
                        <ListItem key='tncPDFpt'>
                            <ListItemText primary="Termos de utilização em Português" />
                            <Typography variant="body2">{this.state.tncPDFpt.name}</Typography>
                        </ListItem>
                    )}
                    {this.state.tncPDFen === null ? '' : (
                        <ListItem key='tncPDFen'>
                            <ListItemText primary="Termos de utilização em Inglês" />
                            <Typography variant="body2">{this.state.tncPDFen.name}</Typography>
                        </ListItem>
                    )}
                    <ListItem key='shortdescriptionPT'>
                        <ListItemText primary="Descrição curta" />
                        <Typography variant="body2">{this.transformString(this.state.short_description_PT, 25, true)}</Typography>
                    </ListItem>
                    <ListItem key='fulldescriptionPT'>
                        <ListItemText primary="Descrição completa" />
                        <Typography variant="body2">{this.transformString(this.state.full_description_PT, 25, true)}</Typography>
                    </ListItem>
                    <ListItem key='shortdescriptionEN'>
                        <ListItemText primary="Short description" />
                        <Typography variant="body2">{this.transformString(this.state.short_description_EN, 25, true)}</Typography>
                    </ListItem>
                    <ListItem key='fulldescriptionEN'>
                        <ListItemText primary="Full description" />
                        <Typography variant="body2">{this.transformString(this.state.full_description_EN, 25, true)}</Typography>
                    </ListItem>

                    {this.state.portrait === null ? '' : (
                        <ListItem key='portrait'>
                            <ListItemText primary="Imagem Retrato" />
                            <Typography variant="body2">{this.state.portrait[0].name}</Typography>
                        </ListItem>
                    )}
                    {this.state.sliderImage1 === null ? '' : (
                        <ListItem key='imageslider1'>
                            <ListItemText primary="Imagem Slider 1" />
                            <Typography variant="body2">{this.state.sliderImage1[0].name}</Typography>
                        </ListItem>
                    )}
                    {this.state.sliderImage2 === null ? '' : (
                        <ListItem key='imageslider2'>
                            <ListItemText primary="Imagem slider 2" />
                            <Typography variant="body2">{this.state.sliderImage2[0].name}</Typography>
                        </ListItem>
                    )}
                    {this.state.sliderImage3 === null ? '' : (
                        <ListItem key='imageslider3'>
                            <ListItemText primary="Imagem slider 3" />
                            <Typography variant="body2">{this.state.sliderImage3[0].name}</Typography>
                        </ListItem>
                    )}
                    {this.state.blueprint === null ? '' : (
                        <ListItem key='blueprint'>
                            <ListItemText primary="Imagem planta" />
                            <Typography variant="body2">{this.state.blueprint[0].name}</Typography>
                        </ListItem>
                    )}


                </List>
            </React.Fragment>
        );
    }
}
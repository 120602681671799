import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import Login from './pages/Login'
import PassRecovery from './pages/PassRecovery'
import PassResetPage from './pages/PassResetPage'

ReactDOM.render(
  <Router>
    <Switch>
      <Route path="/login" exact component={Login} />
      <Route path="/passwordRecovery" exact component={PassRecovery} />
      <Route path='/resetmypassword' exact component={PassResetPage} />
      <Route path="/app" component={App} />
      <Route exact >
        <Redirect to="/login" />
      </Route>
    </Switch>
  </Router>,
  document.getElementById('root')
);


import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { Tooltip } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core'

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SeeMoreIcon from '@material-ui/icons/Visibility';
import VrLinkIcon from '@material-ui/icons/ThreeDRotation';
import FormData from 'form-data'

import axios from 'axios'
import Cookies from 'universal-cookie'

import EditCoworkDialog from './EditCoworkDialog';
import ViewMoreDialog from './ViewMoreDialog'

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            width: 300,
        },
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '20px'
    },
    header: {
        width: '100%',
        height: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

    },
    cardContent: {
        padding: 0,
    },
    cardActions: {
        width: '100%',
        padding: '0px 20px 0px 20px',
        justifyContent: 'space-between',
        paddingBottom: '5px',
        paddingTop: '0px'
    },
    media: {
        height: 'auto',
        width: '100%',
    },
    description: {
        whiteSpace: 'pre-line',
        textAlign: 'center'
    },
    active_badge: {
        backgroundColor: 'green',
        marginTop: '-10px',
        transform: 'scale(0.7)',
        cursor: 'pointer'
    },
    inactive_badge: {
        backgroundColor: 'rgb(192,0,0)',
        marginTop: '-10px',
        transform: 'scale(0.7)',
        cursor: 'pointer'
    },
    cardDescription: {
        minHeight: '153px'
    }
}));

const cookies = new Cookies();

function CoworkCard(props) {

    const classes = useStyles();

    const [deleteDialog, setDeleteDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [viewMoreDialog, setViewMoreDialog] = useState(false);
    const [feedbackDialog, setFeedbackDialog] = useState(false);
    const [feedbackTitle, setFeedbackTitle] = useState('');
    const [feedbackContent, setFeedbackContent] = useState('');

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
        if (newWindow) newWindow.opener = null
    }

    const handleDeteleCowork = () => {

        axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/coworks/${props.cowork.id}`, { headers: { 'apikey': cookies.get('key') } })
            .then((res) => {
                setFeedbackDialog(true);
                setFeedbackTitle('Sucesso')
                setFeedbackContent(`O cowork ${props.cowork.title} foi eliminado com sucesso.`);
            }, (err) => {
                console.log(err)
                setFeedbackDialog(true);
                setFeedbackTitle('Erro')
                setFeedbackContent(`Não foi possivel eliminar o cowork ${props.cowork.title}. Se o problema persitir contacte o administrador do servidor`)
            });
    }

    const changeOnline = () => {
        let formData = new FormData();
        formData.set('id', props.cowork.id);
        formData.set('online', !props.cowork.online)

        axios.put(`${process.env.REACT_APP_SERVER_URL}/api/coworks/${props.cowork.id}`, formData , { headers: { 'apikey': cookies.get('key') } })
            .then((res) => {
                setFeedbackDialog(true);
                setFeedbackTitle('Sucesso')
                setFeedbackContent(`O cowork ${props.cowork.title} está agora ${!props.cowork.online ? 'Disponivel' : 'Indesponivel'}`);
            }, (err) => {
                console.log(err)
                setFeedbackDialog(true);
                setFeedbackTitle('Erro')
                setFeedbackContent(`Não foi possivel alterar o cowork ${props.cowork.title}. Se o problema persitir contacte o administrador do servidor`)
            });
    }

    const handleCloseDialog = () => {
        setDeleteDialog(false);
        setFeedbackDialog(false);
        props.refreshCowork();
    }

    const stringToColor = (string) => {

        if (string === undefined) string = 'No User'

        let hash = 0;
        let i;

        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.substr(-2);
        }
        return color
    }

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <CardHeader className={classes.header}
                    avatar={
                        <Avatar style={{ backgroundColor: stringToColor(props.cowork.location) }}>
                            {props.cowork.location[0]}
                        </Avatar>
                    }
                    action={
                        <Tooltip title={props.cowork.online ? 'Disponivel' : 'Indisponível'} placement='right' >
                            <Badge 
                            invisible={false} 
                            classes={props.cowork.online ? { badge: classes.active_badge } : { badge: classes.inactive_badge }} 
                            overlap='circular' 
                            onClick={() => changeOnline()}
                            />
                        </Tooltip>
                    }
                    title={props.cowork.title}
                    subheader={props.cowork.location}
                />
                <CardMedia
                    className={classes.media}
                    image={`${process.env.REACT_APP_SERVER_URL}/resources/images/uploads/coworks/${props.cowork.portrait}`}
                    title="Cowork portrait"
                    component="img"
                    alt="Protrait"
                />
                <CardContent className={classes.cardDescription}>
                    <Typography className={classes.description} variant="body2" color="textSecondary" component="p">
                        {props.cowork.short_description_PT}
                    </Typography>
                </CardContent>
                <CardActions disableSpacing className={classes.cardActions}>
                    <div>
                        <Tooltip title="Ver Mais" placement='bottom'>
                            <IconButton aria-label="ver-mais-cowork" onClick={() => setViewMoreDialog(true)}>
                                <SeeMoreIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Ver 360" placement='bottom' >
                            <IconButton aria-label="ver-VR-cowork" onClick={() => openInNewTab(props.cowork.vrUrl)}>
                                <VrLinkIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <Divider orientation="vertical" flexItem />
                    <div>
                        <Tooltip title="Editar Cowork" placement='bottom'>
                            <IconButton aria-label="edit-cowork" onClick={() => setEditDialog(true)}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Eliminar Cowork" placement='bottom'>
                            <IconButton aria-label="delete-cowok" onClick={() => setDeleteDialog(true)}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </CardActions>
            </Card>
            <Dialog
                open={deleteDialog}
                onClose={() => setDeleteDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Tem a certeza que pretende eliminar este registo"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Se eliminar este cowork não poderá reverter este porcesso. Tem a certeza?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialog(false)} color="primary" autoFocus>
                        Cancelar
                    </Button>
                    <Button onClick={handleDeteleCowork} color="primary">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth='md'
                open={editDialog}
                onClose={() => setEditDialog(false)}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent>
                    <EditCoworkDialog data={props.cowork} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setEditDialog(false); props.refreshCowork() }} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={feedbackDialog}
                onClose={() => setFeedbackDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="confirmation-dialog-title">{feedbackTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirmation-dialog-description">
                        {feedbackContent}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth='md'
                open={viewMoreDialog}
                onClose={() => setViewMoreDialog(false)}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent>
                    <ViewMoreDialog data={props.cowork} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setViewMoreDialog(false)} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default CoworkCard

import React, { useState, useEffect } from 'react'
import './css/Reservations.css'
import Paper from '@material-ui/core/Paper';
import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add'
import { Tooltip } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from '@material-ui/core';
import axios from 'axios'
import Cookies from 'universal-cookie'
import moment from 'moment'

import FeedBackDialog from '../components/ErrorDialog/FeedBackDialog';
import ReservationTable from '../components/reservations/ReservationTable'
import AddReservationDialog from '../components/reservations/AddReservationDialog';
import ReservationCalendar from '../components/reservations/ReservationCalendar';

const cookies = new Cookies();

function Reservations(props) {
    const [triggerAddDialog, setTriggerAddDialog] = useState(false);
    const [refreshTrigger, setRefreshTrigger] = useState(false);
    const [data, setData] = useState([])
    const [otherEvents, setOtherEvents] = useState([])

    const [feedbackDialog, setFeedbackDialog] = useState(false);
    const [feedbackTitle, setFeedbackTitle] = useState('');
    const [feedbackContent, setFeedbackContent] = useState('')

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}/api/reservations`, { headers: { 'apikey': cookies.get('key') } })
            .then((res) => {
                res.data.map(item => {
                    item.checkIn = moment(item.checkIn).utc(false).format('YYYY/MM/DD HH:mm');
                    item.checkOut = moment(item.checkOut).utc(false).format('YYYY/MM/DD HH:mm');
                    return '';
                })
                setData(res.data);
            }, (err) => {
                console.log(err);
                setFeedbackDialog(true);
                setFeedbackTitle('Erro')
                setFeedbackContent(`Não foi possivel comunicar com o servidor. Se o problema persitir contacte o administrador do servidor. ${err}`)
            });

            axios.get(`${process.env.REACT_APP_SERVER_URL}/api/calendar_events`, { headers: { 'apikey': cookies.get('key') } })
            .then((res) => {
                res.data.map(item => {
                    item.checkIn = moment(item.checkIn).utc(false).format('YYYY/MM/DD HH:mm');
                    item.checkOut = moment(item.checkOut).utc(false).format('YYYY/MM/DD HH:mm');
                    return '';
                })
                setOtherEvents(res.data);
            }, (err) => {
                console.log(err);
                setFeedbackDialog(true);
                setFeedbackTitle('Erro')
                setFeedbackContent(`Não foi possivel comunicar com o servidor. Se o problema persitir contacte o administrador do servidor. ${err}`)
            });

    }, [refreshTrigger])

    const refreshData = () => {
        setRefreshTrigger(!refreshTrigger)
    }

    return (
        <div className="reservations-container">
            <div className="reservations-content">
                <div className="util-field-reservations">
                    <Tooltip title="Adicionar nova reserva" placement="left">
                        <Fab color="primary" aria-label="add" onClick={() => setTriggerAddDialog(true)}>
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                </div>
                <div className="bottom-reservations-container">
                    <Paper elevation={5} style={{ height: 'fit-content' }} className="dash-paper" sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <ReservationTable data={data} refreshData={refreshData} />
                    </Paper>
                </div>
                <div className="reservation-calendar">
                    <Paper elevation={5} style={{ height: '800px' }} className="dash-paper" sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <ReservationCalendar data={data} otherEvents={otherEvents} refreshData={refreshData} />
                    </Paper>
                </div>
            </div>
            <Dialog
                fullWidth={true}
                maxWidth='md'
                open={triggerAddDialog}
                onClose={() => setTriggerAddDialog(false)}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent>
                    <AddReservationDialog />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setTriggerAddDialog(false); setRefreshTrigger(!refreshTrigger) }} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
            <FeedBackDialog
                open={feedbackDialog}
                close={() => setFeedbackDialog(false)}
                title={feedbackTitle}
                content={feedbackContent} />
        </div >
    )
}

export default Reservations
import React from 'react';
import { Redirect } from 'react-router';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { Avatar } from '@material-ui/core';
import CoworkIcon from '@material-ui/icons/House';
import PartnersIcon from '@material-ui/icons/People';
import UserIcon from '@material-ui/icons/Person';
import StoreIcon from '@material-ui/icons/LocalGroceryStore'

const useStyles = makeStyles({
    card: {
        width: '15vw',
        minWidth: 225,
        height: 120,
        backgroundImage: 'linear-gradient(to right, rgba(63,81,181,1), rgba(28,38,96,1))',
    },
    upperContent: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: 0,
    },
    icon: {
        color: 'rgba(28,38,96,1)',
        fontSize: '32px'
    },
    text: {
        color: 'white'
    }
});

export default function Users(props) {

    const classes = useStyles();
    const [redirect, setRedirect] = React.useState(false)

    const SetIcon = (props) => {
        switch(props.title) {
            case 'Coworks': return <CoworkIcon className={classes.icon} />
            case 'Parceiros Individuais': return <PartnersIcon className={classes.icon} />
            case 'Usuários Backoffice': return <UserIcon className={classes.icon} />
            case 'Items na Loja': return <StoreIcon className={classes.icon} />
            case 'Clientes Cowork': return <PartnersIcon className={classes.icon} />
            default: return '';
        }
    }

    return (
        <Card className={classes.card}>
            <CardActionArea onClick={() => setRedirect(true)}>
                <CardContent className={classes.upperContent}>
                    <div>
                        <Typography variant="subtitle1" component="h2" className={classes.text}>
                            {props.title}
                        </Typography>
                        <Typography variant='h5' component="h1" className={classes.text}>
                            {props.content}
                        </Typography>
                    </div>
                    <Avatar style={{ backgroundColor: 'white' }} >
                        <SetIcon title={props.title} />
                    </Avatar>
                </CardContent>
                <CardContent>
                    <Typography variant='subtitle1' component="h2" className={classes.text}>
                        {props.info}
                    </Typography>
                </CardContent>
            </CardActionArea>
            {redirect && <Redirect to={props.path} />}
        </Card>

    );
}
import React from 'react';
import { Redirect } from 'react-router';
import moment from 'moment'

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { Avatar } from '@material-ui/core';
import PartnersIcon from '@material-ui/icons/People';


const useStyles = makeStyles({
    card: {
        width: '15vw',
        minWidth: 225,
        height: 120,
        backgroundImage: 'linear-gradient(to right, rgba(63,81,181,1), rgba(28,38,96,1))',
    },
    upperContent: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: 0,
    },
    icon: {
        color: 'rgba(28,38,96,1)',
        fontSize: '32px'
    },
    text: {
        color: 'white'
    },
    bottomDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    positive: {
        color: 'rgba(104,255,88,1)',
        marginRight: '-10px'
    },
    neutral: {
        color: 'rgba(255,255,255,1)',
        marginRight: '-10px'
    }
});

export default function Users(props) {

    const classes = useStyles();
    const [redirect, setRedirect] = React.useState(false)

    const countNewClients = () => {
        const clients = props.info.filter(client => moment(client.createdAt).format('DD-MM') === moment(new Date()).format('DD-MM'))

        return clients.length;
    }

    const getPercentageGrow = (nClients, totalClients) => {
        
        let percentage = ((nClients - totalClients) / totalClients * 100) + 100;
        
        return percentage > 0 ? `+${Math.floor(percentage * 100) / 100}%` : `${Math.floor(percentage * 100) / 100}%`
    }

    const newClients = countNewClients();
    const newClientsPercentage = getPercentageGrow(newClients, props.info.length)

    return (
        <Card className={classes.card}>
            <CardActionArea onClick={() => setRedirect(true)}>
                <CardContent className={classes.upperContent}>
                    <div>
                        <Typography variant="subtitle1" component="h2" className={classes.text}>
                            {props.title}
                        </Typography>
                        <Typography variant='h5' component="h1" className={classes.text}>
                            {props.content}
                        </Typography>
                    </div>
                    <Avatar style={{ backgroundColor: 'white' }} >
                        <PartnersIcon className={classes.icon} />
                    </Avatar>
                </CardContent>
                <CardContent>
                    <div className={classes.bottomDiv}>
                        <Typography variant='subtitle1' component="h2" className={classes.text}>
                            Novos Clientes Hoje: {newClients}
                        </Typography>
                        <Typography variant='subtitle1' component="h2" className={ newClients > 0 ? classes.positive : classes.neutral }>
                            {newClientsPercentage}
                        </Typography>
                    </div>

                </CardContent>
            </CardActionArea>
            {redirect && <Redirect to={props.path} />}
        </Card>

    );
}
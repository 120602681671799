import React, { useState, useEffect } from 'react'
import './css/Dashboard.css'
import Paper from '@material-ui/core/Paper';
import axios from 'axios'
import Cookies from 'universal-cookie'
import FeedBackDialog from '../components/ErrorDialog/FeedBackDialog'
import FooterGraph from '../components/dashboard/FooterGraph'

import DashboardCard from '../components/dashboard/DashboardCard'
import DashboardCardClients from '../components/dashboard/DashboardCardClients'
import ReservationsDashboard from '../components/dashboard/ReservationsDashboard'
import Graph from '../components/dashboard/Graph';


const cookies = new Cookies();

function Dashboard() {

    const [data, setData] = useState([])
    const [feedbackDialog, setFeedbackDialog] = useState(false);
    const [feedbackTitle, setFeedbackTitle] = useState('');
    const [feedbackContent, setFeedbackContent] = useState('')

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}/api/dashboard`, { headers: { 'apikey': cookies.get('key') } })
            .then((res) => {
                setData(res.data);
            }, (err) => {
                console.log(err);
                setFeedbackDialog(true);
                setFeedbackTitle('Erro')
                setFeedbackContent(`Não foi possivel comunicar com o servidor. Se o problema persitir contacte o administrador do servidor. ${err}`)
            });
    }, [])


    if (data.clients === undefined) return ''

    return (
        <div className="dashboard-container">
            <div className="dashboard-content">
                <div className="upper-dash-container">
                    <div className="coworks-dash">
                        <DashboardCard title="Coworks" info={`Coworks ativos: ${data.nActiveItems.coworks}`} content={data.nCowork} path="/app/coworks" />
                    </div>
                    <div className="parceiros-dash">
                        <DashboardCard title="Parceiros Individuais" info={`Parceiros Individuais ativos: ${data.nActiveItems.partners}`} content={data.nPartners} path="/app/partners" />
                    </div>
                    <div className="users-dash">
                        <DashboardCard title="Usuários Backoffice" info={`Usuários Backoffice online: ${data.nActiveItems.users}`} content={data.nUsers} path="/app/users" />
                    </div>
                    <div className="store-dash">
                        <DashboardCard title="Items na Loja" info={`Items na Loja ativos: ${data.nActiveItems.store}`} content={data.nStore} path="/app/store" />
                    </div>
                    <div className="client-dash">
                        <DashboardCardClients title="Clientes Cowork" info={data.clients} content={data.clients.length} path="/app/clients" />
                    </div>
                </div>
                <div className="middle-dash-container">
                    <div className='bottom-left-container'>
                        <div className="reservas-dash">
                            <Paper elevation={5} className="dash-paper-reserv" sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                <ReservationsDashboard data={data.lastReservations} />
                            </Paper>
                        </div>
                        <div className="reservas-graph" >
                            <Paper elevation={5} className="graph-reservas-paper" sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                                <Graph data={data.nReservations} />
                            </Paper>
                        </div>
                    </div>
                </div>
                <div className="footer-dash-container" >
                    <Paper elevation={5} className="footer-graph-reservas-paper" sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <FooterGraph data={data.clients} />
                    </Paper>
                </div>
            </div>
            <FeedBackDialog
                open={feedbackDialog}
                close={() => setFeedbackDialog(false)}
                title={feedbackTitle}
                content={feedbackContent} />
        </div >
    )
}

export default Dashboard

import React, { Component } from 'react'
import { Paper } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'

import './css/Analytics.css'

class Analytics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        }
    }

    render() {
        return (
            <>
                {this.state.loading ? (
                    <div className='loading-div'>
                        <CircularProgress />
                        <h1 style={{ paddingLeft: '25px' }}>Loading Data...</h1>
                    </div>
                ) : null}
                <div className="analytics-container">
                    <Paper elevation={5} className="iframe-paper">
                        <iframe
                            title='Analytics'
                            width="100%"
                            height="100%"
                            src="https://datastudio.google.com/embed/reporting/8a763678-8a76-4b16-89b7-a267a0f04748/page/1M"
                            frameBorder="0"
                            style={{ border: '0' }}
                            allowFullScreen={false}
                            onLoad={() => this.setState({ loading: false })}
                        >
                        </iframe>
                    </Paper>
                </div>
            </>
        )
    }
}

export default Analytics

import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';

export default class InfoPT extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name_PT: props.defaultData.name_PT,
            dimensions: props.defaultData.dimensions,
            materials_PT: props.defaultData.materials_PT,
            origin: props.defaultData.origin,
            price: props.defaultData.price,
            description_PT: props.defaultData.description_PT,
            online: props.defaultData.online,
            category: props.defaultData.category,
            delivery_time: props.defaultData.delivery_time,
            formErrors: { name_PT: '', dimensions: '', materials_PT: '', origin: '', description_PT: '', price: '', delivery_time: '' },
            name_PTIsValid: false,
            dimensionsIsValid: false,
            materials_PTIsValid: false,
            originIsValid: false,
            description_PTIsValid: false,
            priceIsvalid: false,
            delivery_timeIsValid: false,
            formIsValid: false,
        }
    }


    handleInput(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.props.updateMainState(name, value);
        this.setState({ [name]: value }, () => { this.validateFields(name, value) });
    }

    validateFields(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let name_PTIsValid = this.state.name_PTIsValid;
        let dimensionsIsValid = this.state.dimensionsIsValid;
        let materials_PTIsValid = this.state.materials_PTIsValid;
        let originIsValid = this.state.originIsValid;
        let description_PTIsValid = this.state.description_PTIsValid;
        let priceIsvalid = this.state.priceIsvalid;
        let delivery_timeIsValid = this.state.delivery_timeIsValid;

        switch (fieldName) {
            case 'name_PT':
                name_PTIsValid = new RegExp(/^[a-zA-Z\u00C0-\u00FF\s*]*$/).test(value) && value.length > 4
                fieldValidationErrors.name_PT = name_PTIsValid ? '' : 'Nome Invalido'
                break;
            case 'dimensions':
                dimensionsIsValid = value.length > 7;
                fieldValidationErrors.dimensions = dimensionsIsValid ? '' : 'Insira dimensões completa'
                break;
            case 'materials_PT':
                materials_PTIsValid = new RegExp(/^[a-zA-Z\u00C0-\u00FF\s*]*$/).test(value) && value.length > 1;
                fieldValidationErrors.materials_PT = materials_PTIsValid ? '' : 'Material Inválido'
                break;
            case 'origin':
                originIsValid = new RegExp(/^[a-zA-Z\u00C0-\u00FF\s*]*$/).test(value) && value.length > 4
                fieldValidationErrors.origin = originIsValid ? '' : 'Origem Inválida'
                break;
            case 'description_PT':
                description_PTIsValid = value.length > 1;
                fieldValidationErrors.description_PT = description_PTIsValid ? '' : 'Deve escrever uma descrição'
                break;
            case 'price':
                priceIsvalid = value > 0;
                fieldValidationErrors.price = priceIsvalid ? '' : 'Preço tem que ser maior que 0€'
                break;
            case 'delivery_time':
                delivery_timeIsValid = value > 0;
                fieldValidationErrors.delivery_time = delivery_timeIsValid ? '' : 'Numero de dias tem de ser superior a 0'
                break;
            default:
                break;
        }


        this.setState({
            formErrors: fieldValidationErrors,
            name_PTIsValid: name_PTIsValid,
            dimensionsIsValid: dimensionsIsValid,
            materials_PTIsValid: materials_PTIsValid,
            originIsValid: originIsValid,
            description_PTIsValid: description_PTIsValid,
            priceIsvalid: priceIsvalid,
            delivery_timeIsValid: delivery_timeIsValid
        }, this.validateForm)

    }

    validateForm() {
        this.setState({
            formIsValid:
                this.state.name_PTIsValid &&
                this.state.dimensionsIsValid &&
                this.state.materials_PTIsValid &&
                this.state.originIsValid &&
                this.state.description_PTIsValid &&
                this.state.priceIsvalid &&
                this.state.delivery_timeIsValid
        }, () => {
            this.props.updateMainState('validForm', this.state.formIsValid);
        })
    }

    render() {
        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            autoFocus
                            required
                            error={!this.state.name_PTIsValid ? true : false}
                            helperText={this.state.formErrors.name_PT}
                            id="name_PT"
                            name="name_PT"
                            label="Nome do Item"
                            fullWidth
                            type="text"
                            defaultValue={this.state.name_PT}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel id="category">Categoria</InputLabel>
                        <Select
                            required
                            fullWidth
                            labelId="category"
                            id="category"
                            name="category"
                            value={this.state.category}
                            onChange={(e) => this.handleInput(e)}
                            displayEmpty
                        >
                            <MenuItem value='mobiliario'>Mobiliário</MenuItem>
                            <MenuItem value='iluminaria'>Iluminária</MenuItem>
                            <MenuItem value='tapecaria'>Tapeçaria</MenuItem>
                            <MenuItem value='decoracao'>Decoração</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            error={!this.state.dimensionsIsValid ? true : false}
                            helperText={this.state.formErrors.dimensions}
                            id="dimensions"
                            name="dimensions"
                            label="Dimensões"
                            type='text'
                            fullWidth
                            defaultValue={this.state.dimensions}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            error={!this.state.materials_PTIsValid ? true : false}
                            helperText={this.state.formErrors.materials_PT}
                            id="materials_PT"
                            name="materials_PT"
                            label="Materiais"
                            type='text'
                            fullWidth
                            defaultValue={this.state.materials_PT}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            error={!this.state.originIsValid ? true : false}
                            helperText={this.state.formErrors.origin}
                            id="origin"
                            name="origin"
                            label="Origem/Artesão"
                            type='text'
                            fullWidth
                            defaultValue={this.state.origin}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            error={!this.state.priceIsvalid ? true : false}
                            helperText={this.state.formErrors.price}
                            id="price"
                            name="price"
                            label="Preço"
                            type='number'
                            InputProps={{ endAdornment: <InputAdornment position='end'>€</InputAdornment> }}
                            fullWidth
                            defaultValue={this.state.price}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            error={!this.state.delivery_timeIsValid ? true : false}
                            helperText={this.state.formErrors.delivery_time}
                            id="delivery_time"
                            name="delivery_time"
                            label="T. de Entrega"
                            type='number'
                            InputProps={{ endAdornment: <InputAdornment position='end'>Dias</InputAdornment> }}
                            fullWidth
                            defaultValue={this.state.delivery_time}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel id="online">Online?</InputLabel>
                        <Select
                            required
                            fullWidth
                            labelId="online"
                            id="online"
                            name="online"
                            value={this.state.online}
                            onChange={(e) => this.handleInput(e)}
                        >
                            <MenuItem value={true}>Sim</MenuItem>
                            <MenuItem value={false}>Não</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            variant='outlined'
                            required
                            multiline
                            rows={7}
                            error={!this.state.description_PTIsValid ? true : false}
                            helperText={this.state.formErrors.description_PT}
                            id="description_PT"
                            name="description_PT"
                            label="Descrição"
                            type='text'
                            fullWidth
                            defaultValue={this.state.description_PT}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}
import airConditioner from '../../assets/icons/amenities/air-conditioning.png'
import bikeParking from '../../assets/icons/amenities/bike-parking.png'
import coffee from '../../assets/icons/amenities/coffee.png'
import desk from '../../assets/icons/amenities/desk.png'
import heating from '../../assets/icons/amenities/heating.png'
import lockers from '../../assets/icons/amenities/lockers.png'
import lounge from '../../assets/icons/amenities/lounge.png'
import officeChair from '../../assets/icons/amenities/office-chair.png'
import parkingLot from '../../assets/icons/amenities/parkinglot.png'
import photocopy from '../../assets/icons/amenities/photocopy.png'
import printer from '../../assets/icons/amenities/printer.png'
import scanner from '../../assets/icons/amenities/scanner.png'
import projector from '../../assets/icons/amenities/projector.png'
import terrace from '../../assets/icons/amenities/terrace.png'
import Wheelchair from '../../assets/icons/amenities/wheelchair.png'
import wifi from '../../assets/icons/amenities/wi-fi.png'
import kitchen from '../../assets/icons/amenities/kitchen.png'
import tv from '../../assets/icons/amenities/tv.png'
import flipboard from '../../assets/icons/amenities/flipboard.png'
import wc from '../../assets/icons/amenities/wc.png'
import beach from '../../assets/icons/amenities/beach.png'
import monument from '../../assets/icons/amenities/monument.png'
import mountain from '../../assets/icons/amenities/mountain.png'
import park from '../../assets/icons/amenities/park.png'
import picture from '../../assets/icons/amenities/picture.png'
import room from '../../assets/icons/amenities/room.png'
import trail from '../../assets/icons/amenities/trail.png'

export const getAmenities = (ids) => {
    if (ids) return amenities.filter(amenity => ids.split(',').includes(amenity.id.toString()))

    return []
}

export const getGroupedAmenities = (ids) => {

    const groupedAmenities = getAmenities(ids).reduce((map, e) => ({
        ...map,
        [e.group]: [...(map[e.group] ?? []), e]
    }), {})
    
    return groupedAmenities

}

export const amenities = [
    { id: 1, namePT: 'Wifi', nameEN: 'Wifi', icon: wifi, group: 'Basics', groupPT: 'Básicos' },
    { id: 2, namePT: 'Aquecimento', nameEN: 'Heating', icon: heating, group: 'Basics', groupPT: 'Básicos' },
    { id: 3, namePT: 'Ar Condicionado', nameEN: 'Air Conditioning', icon: airConditioner, group: 'Basics', groupPT: 'Básicos' },
    { id: 4, namePT: 'Cacifos Pessoais', nameEN: 'Personal Lockers', icon: lockers, group: 'Facilities', groupPT: 'Instalações' },
    { id: 5, namePT: 'Café', nameEN: 'Coffee', icon: coffee, group: 'Caffeine Fix', groupPT: 'Café' },
    { id: 6, namePT: 'Secretarias de Pé', nameEN: 'Standing desks', icon: desk, group: 'Lugares' },
    { id: 7, namePT: 'Cadeiras Ergonómicas', nameEN: 'Ergonomic Chairs', icon: officeChair, group: 'Seating', groupPT: 'Lugares' },
    { id: 8, namePT: 'Estacionamento Gratuito', nameEN: 'Free parking Space', icon: parkingLot, group: 'Transportation', groupPT: 'Transportes' },
    { id: 9, namePT: 'Parque para bicicletas', nameEN: 'Bike parking', icon: bikeParking, group: 'Transportation', groupPT: 'Transportes' },
    { id: 10, namePT: 'Lounge', nameEN: 'Lounge', icon: lounge, group: 'Relax Zones', groupPT: 'Zonas para relaxar' },
    { id: 11, namePT: 'Jardim', nameEN: 'Garden', icon: terrace, group: 'Relax Zones', groupPT: 'Zona para relaxar' },
    { id: 12, namePT: 'Impressora', nameEN: 'Printer', icon: printer, group: 'Equipment', groupPT: 'Equipamento' },
    { id: 13, namePT: 'Scanner', nameEN: 'Scanner', icon: scanner, group: 'Equipment', groupPT: 'Equipamento' },
    { id: 14, namePT: 'Fotocopiadora', nameEN: 'Photocopier', icon: photocopy, group: 'Equipment', groupPT: 'Equipamento' },
    { id: 15, namePT: 'Projetor', nameEN: 'Projector', icon: projector, group: 'Equipment', groupPT: 'Equipamento' },
    { id: 16, namePT: 'Acessibilidade cadeira de rodas', nameEN: 'Wheelchair Accessibility', icon: Wheelchair, group: 'Accessibility', groupPT: 'Acessibilidade' },
    { id: 17, namePT: 'Cozinha Equipada', nameEN: 'Fully equipped Kitchen', icon: kitchen, group: 'Facilities', groupPT: 'Instalações' },
    { id: 18, namePT: 'Smart TV', nameEN: 'Smart TV', icon: tv, group: 'Equipment', groupPT: 'Equipamento' },
    { id: 19, namePT: 'Flip Board', nameEN: 'Flip Board', icon: flipboard, group: 'Equipment', groupPT: 'Equipamento' },
    { id: 20, namePT: 'WC', nameEN: 'WC', icon: wc, group: 'Facilities', groupPT: 'Instalações' },
    { id: 21, namePT: 'Quadro de Ardósia', nameEN: 'Slate Frame', icon: picture, group: 'Relax Zones', groupPT: 'Zonas para relaxar' },
    { id: 22, namePT: 'Sala Reuniões', nameEN: 'Meeting Room', icon: room, group: 'Facilities', groupPT: 'Instalações' },
    { id: 23, namePT: 'Praia Fluvial', nameEN: 'River Beach', icon: beach, group: 'Outdoor', groupPT: 'Outdoor' },
    { id: 24, namePT: 'Parque de Merendas', nameEN: 'Snacks park', icon: park, group: 'Outdoor', groupPT: 'Outdoor' },
    { id: 25, namePT: 'Monumentos', nameEN: 'Monuments', icon: monument, group: 'Outdoor', groupPT: 'Outdoor' },
    { id: 26, namePT: 'Percursos Pedestres', nameEN: 'Trails', icon: trail, group: 'Outdoor', groupPT: 'Outdoor' },
    { id: 27, namePT: 'Vista Panorâmica da Montanha', nameEN: 'Mountain Panoramic View', icon: mountain, group: 'Outdoor', groupPT: 'Outdoor' },
    { id: 28, namePT: 'WC/Balneário', nameEN: 'WC/Shower', icon: wc, group: 'Facilities', groupPT: 'Instalações' },
]

import React, { useState, useEffect } from 'react'
import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/PersonAdd'
import { Tooltip } from '@material-ui/core'
import UserCard from '../components/users/UserCard';
import axios from 'axios'
import Cookies from 'universal-cookie'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from '@material-ui/core'
import AddUserDialog from '../components/users/AddUserDialog';

import FeedBackDialog from '../components/ErrorDialog/FeedBackDialog';

import './css/Users.css'

const cookies = new Cookies();

function Users() {

    const [triggerAddDialog, setTriggerAddDialog] = useState(false);
    const [users, setUsers] = useState([]);
    const [activeUser, setActiveUser] = useState('');
    const [triggerFetch, setTriggerFetch] = useState(false);

    const [feedbackDialog, setFeedbackDialog] = useState(false);
    const [feedbackTitle, setFeedbackTitle] = useState('');
    const [feedbackContent, setFeedbackContent] = useState('')

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}/api/users`, { headers: { 'apikey': cookies.get('key') } })
            .then((res) => {
                setUsers(res.data);
            }, (err) => {
                console.log(err);
                setFeedbackDialog(true);
                setFeedbackTitle('Erro')
                setFeedbackContent(`Não foi possivel comunicar com o servidor. Se o problema persitir contacte o administrador do servidor. ${err}`)
            });
    }, [triggerFetch])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}/api/users/${cookies.get('userID')}`, { headers: { 'apikey': cookies.get('key') } })
            .then((res) => {
                setActiveUser(res.data)
            }, (err) => {
                console.log(err);
                setFeedbackDialog(true);
                setFeedbackTitle('Erro')
                setFeedbackContent(`Não foi possivel comunicar com o servidor. Se o problema persitir contacte o administrador do servidor. ${err}`)
            });
    }, [triggerFetch])

    const refresh = () => {
        setTriggerFetch(!triggerFetch);
    }

    return (
        <div className="users-container" >
            <div className="users-content">
                <div className="util-field-users">
                    <div className='users-title'>Users</div>
                    {activeUser.userType === 'Admin' ? (
                        <Tooltip title="Adicionar novo user" placement="left">
                            <Fab style={{ marginRight: '15px' }} color="primary" aria-label="add" onClick={() => setTriggerAddDialog(true)}>
                                <AddIcon />
                            </Fab>
                        </Tooltip>
                    ) : ''}
                </div>
                <div className="bottom-users-container">
                    {users.map(user => {
                        return (
                            <UserCard user={user} key={user.id} activeUser={activeUser} refreshUsers={refresh} />
                        )
                    })}
                </div>
            </div>
            <Dialog
                fullWidth={true}
                maxWidth='md'
                open={triggerAddDialog}
                onClose={() => setTriggerAddDialog(false)}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent>
                    <AddUserDialog refreshUsers={refresh} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setTriggerAddDialog(false); refresh(); }} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
            <FeedBackDialog
                open={feedbackDialog}
                close={() => setFeedbackDialog(false)}
                title={feedbackTitle}
                content={feedbackContent} />
        </div>
    )
}

export default Users
import React, { Component, useState } from 'react'
import Cookies from 'universal-cookie'
import axios from 'axios'
import { Redirect } from 'react-router-dom'

import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles';
import { Tooltip, InputAdornment, IconButton } from '@material-ui/core'
import Card from '@material-ui/core/Card';
import { makeStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import CardMedia from '@material-ui/core/CardMedia';
import Badge from '@material-ui/core/Badge'
import { CardContent, Divider, Typography } from '@material-ui/core';
import { DropzoneDialog } from 'material-ui-dropzone';
import TextField from '@material-ui/core/TextField';
import { Container } from '@material-ui/core';
import { Paper } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button } from '@material-ui/core'
import Grid from '@material-ui/core/Grid';
import FeedBackDialog from '../components/ErrorDialog/FeedBackDialog';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import FormData from 'form-data'

import UserPlaceholderImage from '../assets/users/user_placeholder.png'

import './css/Profile.css'

const cookies = new Cookies();

const formStyles = theme => ({
    paper: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px 50px 20px 50px',
    },
    avatar: {
        width: '100px',
        height: 'auto'
    },
    form: {
        with: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(2, 0, 2),

    },
    box: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(2, 0, 2)
    }
});

const useStyles = makeStyles((theme) => ({
    root: {
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '10px',
        height: 'fit-content'
    },
    header: {
        width: '100%',
        height: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

    },
    cardContent: {
        padding: 0,
    },
    cardActions: {
        justifyContent: 'space-around',
        paddingBottom: '5px',
        paddingTop: '0px'
    },
    media: {
        height: '250px',
        width: 'auto',
        marginTop: '15px',
    },
    avatar: {
    },
    badge: {
        backgroundColor: 'green',
    },
    dropzone: {
        minWidth: '250px',
        minHeight: '250px',
        width: 'fit-content',
        height: 'fit-content'
    },
    active_badge: {
        backgroundColor: 'rgb(204,204,204)',
        transform: 'scale(2)',
        marginRight: '-110px',
        marginTop: '25px',
        cursor: 'pointer',
        color: 'white',
        fontSize: '16px',
        '&:hover': {
            transform: 'scale(2.2)',
        }
    },
    description: {
        marginBottom: '-11px',
        marginTop: '-15px'
    }
}));

const theme = createTheme({
    overrides: {
        root: {
            display: 'flex',
        },
        MuiDropzonePreviewList: {
            image: {
                height: '300px'
            }
        },
        MuiGrid: {
            "spacing-xs-8": {
                width: '100%',
                margin: 0,
            },
            "grid-xs-4": {
                maxWidth: 'none',
                flexBasis: '100%'
            },
            item: { padding: '5px !important' }
        },
        MuiDialog: {
            paperFullWidth: {
                width: 'fit-content'
            }
        },
        MuiDialogActions: {
            root: {
                justifyContent: 'center'
            }
        },
        MuiDialogContent: {
            root: {
                display: 'flex',
                justifyContent: 'center'
            }
        },
        MuiDialogTitle: {
            root: {
                display: 'flex',
                justifyContent: 'center'
            }
        },
        MuiDropzoneArea: {
            root: {
                minHeight: '0'
            },
            textContainer: {
                display: 'none'
            }
        }
    }
})

const stringToColor = (string) => {

    if (string === undefined) string = 'No User'

    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }

    return color

}

const getFirstLetterFromFirstAndLastName = (name) => {

    if (name === undefined) name = 'No User'

    let firstLetterFirstName = '';
    let firstLetterLastName = '';

    if (name.split(' ')[1] === undefined) {
        firstLetterFirstName = name[0];
        firstLetterLastName = name[1];
    } else {
        firstLetterFirstName = name.split(' ')[0][0];
        firstLetterLastName = name.split(' ')[1][0];
    }

    return `${firstLetterFirstName}${firstLetterLastName}`
}

const UserCard = (props) => {

    const classes = useStyles();
    const [openImageDialog, setOpenImageDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);

    const removePicture = () => {
        axios.put(`${process.env.REACT_APP_SERVER_URL}/api/users/removePic/${props.user.id}`, {}, { headers: { 'apikey': cookies.get('key') } })
            .then((res) => {
                props.refresh();
            }, (err) => {
                console.log(err.response);
            });

        setDeleteDialog(false);
    }

    const changeProfilePic = (file) => {
        let formData = new FormData();
        formData.set('id', props.user.id);
        formData.set('username', props.user.username);
        formData.append('picture', file[0]);

        axios.put(`${process.env.REACT_APP_SERVER_URL}/api/users/profilePic/${props.user.id}`, formData, { headers: { 'apikey': cookies.get('key') } })
            .then((res) => {
                props.refresh();
            }, (err) => {
                console.log(err);
            });
    }

    return (
        <>
            <Card elevation={4} className={classes.card}>
                {props.user.picture === null ? '' : (
                    <Tooltip title="Eliminar foto" placement="bottom">
                        <Badge
                            badgeContent={'-'}
                            invisible={false}
                            classes={{ badge: classes.active_badge }}
                            overlap='circular'
                            onClick={() => setDeleteDialog(true)}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        />
                    </Tooltip>
                )}
                <div className='round'>
                    <Tooltip title={props.user.picture ? "Alterar foto" : 'Adicionar Foto'} placement="right">
                        <CardMedia
                            onClick={() => setOpenImageDialog(true)}
                            className='profilePic'
                            image={props.user.picture ? `${process.env.REACT_APP_SERVER_URL}/resources/images/uploads/users/${props.user.picture}` : UserPlaceholderImage}
                            component="img"
                            alt="User Image"
                            height="140"
                        />
                    </Tooltip>
                </div>
                <CardHeader className={classes.header}
                    avatar={
                        <Badge invisible={!props.user.loggedIn} classes={{ badge: classes.badge }} overlap='circular' variant='dot'>
                            <Avatar style={{ backgroundColor: stringToColor(props.user.username) }}>
                                {getFirstLetterFromFirstAndLastName(props.user.username)}
                            </Avatar>
                        </Badge>
                    }
                    title={props.user.username}
                    subheader={props.user.userType}
                />
                <Divider variant='middle' />
                <CardContent>
                    <Typography className={classes.description} variant="h6" color="textSecondary" component="p">
                        {props.user.email}
                    </Typography>
                </CardContent>
            </Card>
            <MuiThemeProvider theme={theme}>
                <DropzoneDialog
                    acceptedFiles={['image/*']}
                    cancelButtonText={'Cancelar'}
                    submitButtonText={'Submeter'}
                    filesLimit={1}
                    getFileLimitExceedMessage={() => 'Numero Maximo de ficheiros excedido. Apenas 1 ficheiro é permitido'}
                    getFileAddedMessage={() => 'Imagem adicionada com sucesso'}
                    getFileRemovedMessage={() => 'Imagem removida com sucesso'}
                    getDropRejectMessage={() => 'Ficheiro rejeitado. Apenas imagens são permitidas. Mais propriamente JPEG, PNG, BMP'}
                    dropzoneClass={classes.dropzone}
                    maxFileSize={2000000}
                    open={openImageDialog}
                    onClose={() => setOpenImageDialog(false)}
                    onSave={(file) => { changeProfilePic(file); setOpenImageDialog(false) }}
                    showPreviews={false}
                    showPreviewsInDropzone={true}
                    dialogTitle={'Clique ou Arraste Imagem'}
                    maxWidth='xs'
                />
            </MuiThemeProvider>
            <Dialog
                open={deleteDialog}
                onClose={() => setDeleteDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Eliminar foto do perfil?"}</DialogTitle>
                <DialogContent>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialog(false)} color="primary" autoFocus>
                        Cancelar
                    </Button>
                    <Button onClick={removePicture} color="primary">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

class EditForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: this.props.user.username,
            oldUserName: this.props.user.username,
            oldEmail: this.props.user.email,
            email: this.props.user.email,
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            formErrors: { username: '', email: '', oldPassword: '', newPassword: '', confirmPassword: '' },
            usernameIsValid: true,
            emailIsValid: true,
            oldPasswordIsValid: false,
            newPasswordIsValid: true,
            confirmPasswordIsValid: true,
            formIsValid: false,
            error: '',
            message: '',
            submiting: false,
            openDialog: false,
            redirect: false,
            showPassword1: false,
            showPassword2: false,
            showPassword3: false
        }
    }

    handleInput(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({ [name]: value }, () => { this.validateFields(name, value) });
    }

    validateFields(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let usernameIsValid = this.state.usernameIsValid;
        let emailIsValid = this.state.emailIsValid;
        let oldPasswordIsValid = this.state.oldPasswordIsValid;
        let newPasswordIsValid = this.state.newPasswordIsValid;
        let confirmPasswordIsValid = this.state.confirmPasswordIsValid;

        switch (fieldName) {
            case 'username':
                usernameIsValid = new RegExp(/^[a-zA-Z\u00C0-\u00FF]{3,}[\s]{1}[a-zA-Z\u00C0-\u00FF]{1,}$/).test(value) && value.length > 4;
                fieldValidationErrors.username = usernameIsValid ? '' : 'Nome Invalido'
                break;
            case 'email':
                emailIsValid = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value);
                fieldValidationErrors.email = emailIsValid ? '' : 'Nome Inválido'
                break;
            case 'oldPassword':
                oldPasswordIsValid = value.length > 7;
                fieldValidationErrors.oldPassword = oldPasswordIsValid ? '' : 'Escreva uma password completa'
                break;
            case 'newPassword':
                confirmPasswordIsValid = false;
                newPasswordIsValid = new RegExp(/^(?=(.*[a-z]){3,})(?=(.*[A-Z]){2,})(?=(.*[0-9]){2,}).{8,}$/).test(value);
                fieldValidationErrors.newPassword = newPasswordIsValid ? '' : 'Password deve conter no minimo 2 maiúsculas, 2 números, 3 minúsculas e mais de 8 caracteres;'
                break;
            case 'confirmPassword':
                confirmPasswordIsValid = this.state.newPassword === this.state.confirmPassword;
                fieldValidationErrors.confirmPassword = confirmPasswordIsValid ? '' : 'Passwords não são coincidem'
                break;
            default:
                break;
        }


        this.setState({
            formErrors: fieldValidationErrors,
            usernameIsValid: usernameIsValid,
            emailIsValid: emailIsValid,
            oldPasswordIsValid: oldPasswordIsValid,
            newPasswordIsValid: newPasswordIsValid,
            confirmPasswordIsValid: confirmPasswordIsValid,
            error: '',
            message: ''
        }, this.validateForm)

    }

    validateForm() {
        this.setState({
            formIsValid:
                this.state.usernameIsValid &&
                this.state.emailIsValid &&
                this.state.oldPasswordIsValid &&
                this.state.newPasswordIsValid &&
                this.state.confirmPasswordIsValid
        })
    }

    checkField = (event) => {
        event.preventDefault();

        let data = {}

        data.id = this.props.user.id;
        if (this.state.username !== this.state.oldUserName) data.username = this.state.username;
        if (this.state.email !== this.state.oldEmail) data.email = this.state.email
        if (this.state.newPassword !== '') {
            data.newPassword = this.state.newPassword;
            data.oldPassword = this.state.oldPassword;
        }


        let dataCounter = 0;
        Object.keys(data).forEach(() => dataCounter++);


        if (dataCounter <= 1) {
            this.setState({ message: 'Não foram detetadas alterações ' })
            return;
        }

        const urlEnc = Object.keys(data)
            .map((key, index) => `${key}=${encodeURIComponent(data[key])}`)
            .join('&');

        this.authenticateUser(urlEnc);
    }

    authenticateUser = (data) => {
        axios.post(`${process.env.REACT_APP_SERVER_URL}/user/auth`, {
            email: this.state.oldEmail,
            password: this.state.oldPassword,
        }).then((res) => {
            console.log(res)
            this.editUser(data);
        }, (err) => {
            console.log(err)
            this.setState({ error: 'Password errada' })
        });
    }

    editUser = (data) => {

        axios.put(`${process.env.REACT_APP_SERVER_URL}/api/users/${this.props.user.id}`, data, {
            headers: {
                'apikey': cookies.get('key'),
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then((res) => {
                console.log(res);
                this.setState({ submiting: false, openDialog: true })
            }, (err) => {
                console.log(err.response)
                this.setState({ submiting: false, error: 'Erro ao fazer as alterações, tente mais tarde' })
            });

    }

    render() {
        const { classes } = this.props;
        return (
            <Container component="main" maxWidth="md">
                <Paper elevation={4} className={classes.paper}>
                    <Typography style={{ paddingBottom: '20px' }} variant="h6" color="textSecondary" component="p">
                        Editar informações de conta
                    </Typography>
                    <Divider />
                    <form className={classes.form} onSubmit={this.checkField}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant='outlined'
                                    error={!this.state.usernameIsValid ? true : false}
                                    helperText={this.state.formErrors.username}
                                    id="title"
                                    name="username"
                                    label="Nome (Primeiro e Ultimo)"
                                    autoComplete='name'
                                    fullWidth
                                    type="text"
                                    defaultValue={this.state.username}
                                    onChange={(e) => this.handleInput(e)}

                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    variant='outlined'
                                    error={!this.state.emailIsValid ? true : false}
                                    helperText={this.state.formErrors.email}
                                    id="email"
                                    name="email"
                                    label="Email"
                                    autoComplete='email'
                                    type='email'
                                    fullWidth
                                    defaultValue={this.state.oldEmail}
                                    onChange={(e) => this.handleInput(e)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    error={!this.state.oldPasswordIsValid ? true : false}
                                    helperText={this.state.formErrors.oldPassword}
                                    name="oldPassword"
                                    label="Password"
                                    type={this.state.showPassword1 ? 'text' : 'password'}
                                    id="oldPassword"
                                    autoComplete="current-password"
                                    onChange={(e) => this.handleInput(e)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    tabIndex='-1'
                                                    aria-label='toggle password visibility'
                                                    onClick={() => this.setState({ showPassword1: !this.state.showPassword1 })}
                                                >
                                                    {this.state.showPassword1 ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    error={!this.state.newPasswordIsValid ? true : false}
                                    helperText={this.state.formErrors.newPassword}
                                    name="newPassword"
                                    label="Nova Password"
                                    type={this.state.showPassword2 ? 'text' : 'password'}
                                    id="newPassword"
                                    autoComplete="new-password"
                                    onChange={(e) => this.handleInput(e)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    tabIndex='-1'
                                                    aria-label='toggle password visibility'
                                                    onClick={() => this.setState({ showPassword2: !this.state.showPassword2 })}
                                                >
                                                    {this.state.showPassword2 ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    error={!this.state.confirmPasswordIsValid ? true : false}
                                    helperText={this.state.formErrors.confirmPassword}
                                    name="confirmPassword"
                                    label="Confirmar Password"
                                    type={this.state.showPassword3 ? 'text' : 'password'}
                                    id="confirmPassword"
                                    autoComplete="new-password"
                                    onChange={(e) => this.handleInput(e)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <IconButton
                                                    tabIndex='-1'
                                                    aria-label='toggle password visibility'
                                                    onClick={() => this.setState({ showPassword3: !this.state.showPassword3 })}
                                                >
                                                    {this.state.showPassword3 ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                        </Grid>
                        {this.state.submiting ? (
                            <Box className={classes.box}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={!this.state.formIsValid}
                                className={classes.submit}
                            >
                                Guardar Alterações
                            </Button>)
                        }
                    </form>
                    <Typography variant="subtitle1" color="error" component="p">
                        {this.state.error}
                    </Typography>
                    <Typography variant="subtitle1" color="primary" component="p">
                        {this.state.message}
                    </Typography>
                </Paper>
                <FeedBackDialog
                    open={this.state.openDialog}
                    close={() => this.setState({ redirect: true })}
                    title='Alterações efetuadas com sucesso'
                    content="As suas alterações foram feitas com sucesso, irá ser redirecionado para o login"
                />
                {this.state.redirect ? (<Redirect to="/" />) : ''}
            </Container>
        )
    }
}

const Form = withStyles(formStyles)(EditForm);
export { Form }


export default class Profile extends Component {
    constructor() {
        super();
        this.state = {
            user: {},
            loaded: false,
        }
    }

    componentDidMount = () => {
        this.fetchUser();
    }

    fetchUser = () => {
        axios.get(`${process.env.REACT_APP_SERVER_URL}/api/users/${cookies.get('userID')}`, { headers: { 'apikey': cookies.get('key') } })
            .then((res) => {
                this.setState({ user: res.data, loaded: true })
            }, (err) => {
                console.log(err);
            });
    }

    render() {

        return (
            <div className="profile-container">
                <div className="left-container">
                    <UserCard user={this.state.user} refresh={this.fetchUser} />
                </div>
                <div className="right-container">
                    {this.state.loaded ? (<Form user={this.state.user} refresh={this.fetchUser} />) : ''}
                </div>
            </div>
        )
    }
}

import React, { useState } from 'react'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import CardMedia from '@material-ui/core/CardMedia';
import { Tooltip } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Badge from '@material-ui/core/Badge'
import { Button } from '@material-ui/core'
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SeeMoreIcon from '@material-ui/icons/Visibility';

import axios from 'axios'
import Cookies from 'universal-cookie'

import ViewMoreDialog from './ViewMoreDialog';
import EditShopItemDialog from './EditShopItemDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        width: '100%',
        minWidth: '350px',
        '& > *': {

        },
    },
    card: {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
    },
    header: {
        width: '100%',
        height: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: '2px'

    },
    cardContent: {
        padding: 15,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    cardActions: {
        width: '100%',
        padding: '0px 20px 0px 20px',
        justifyContent: 'space-between',
        paddingBottom: '5px',
        paddingTop: '0px'
    },
    media: {
        height: '350px',
        width: '100%',
    },
    active_badge: {
        backgroundColor: 'green',
        transform: 'scale(0.7)',
        marginRight: '10px',
        cursor: 'pointer',
    },
    inactive_badge: {
        backgroundColor: 'rgb(192,0,0)',
        transform: 'scale(0.7)',
        marginRight: '10px',
        cursor: 'pointer'
    }
}));

const cookies = new Cookies();

function ShopItemCard(props) {
    const classes = useStyles();

    const [viewMoreDialog, setViewMoreDialog] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [editDialog, setEditDialog] = useState(false);
    const [feedbackDialog, setFeedbackDialog] = useState(false);
    const [feedbackTitle, setFeedbackTitle] = useState('');
    const [feedbackContent, setFeedbackContent] = useState('');

    const handleDetelePartner = () => {

        axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/store_items/${props.item.id}`, { headers: { 'apikey': cookies.get('key') } })
            .then((res) => {
                setFeedbackDialog(true);
                setFeedbackTitle('Sucesso')
                setFeedbackContent(`O Item ${props.item.name_PT} foi eliminado com sucesso.`);
            }, (err) => {
                console.log(err)
                setFeedbackDialog(true);
                setFeedbackTitle('Erro')
                setFeedbackContent(`Não foi possivel eliminar o Item ${props.item.name_PT}. Se o problema persitir contacte o administrador do servidor ${err}`)
            });
    }

    const changeOnline = () => {
        let formData = new FormData();
        formData.set('id', props.item.id);
        formData.set('online', !props.item.online)

        axios.put(`${process.env.REACT_APP_SERVER_URL}/api/store_items/${props.item.id}`, formData, { headers: { 'apikey': cookies.get('key') } })
            .then((res) => {
                setFeedbackDialog(true);
                setFeedbackTitle('Sucesso')
                setFeedbackContent(`O Item ${props.item.name_PT} está agora ${props.item.online ? 'Offline' : 'Online'}`);
            }, (err) => {
                console.log(err)
                setFeedbackDialog(true);
                setFeedbackTitle('Erro')
                setFeedbackContent(`Não foi possivel alterar o cowork ${props.item.name_PT}. Se o problema persitir contacte o administrador do servidor`)
            });
    }

    const handleCloseDialog = () => {
        setDeleteDialog(false);
        setFeedbackDialog(false);
        props.refresh();
    }

    const transformString = (string) => {
        switch (string) {
            case 'mobiliario': return 'Mobiliário';
            case 'iluminaria': return 'Iluminária';
            case 'tapecaria': return 'Tapeçaria';
            case 'decoracao': return 'Decoração';
            default: return 'Not a valid string'
        }
    }

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <CardMedia
                    className={classes.media}
                    image={`${process.env.REACT_APP_SERVER_URL}/resources/images/uploads/store/${props.item.portrait}`}
                    title="Item_portrait"
                    component="img"
                    alt="item_image"
                    height="140"

                />
                <CardHeader className={classes.header}
                    action={
                        <Tooltip title={props.item.online ? 'Online' : 'Offline'} placement='right' >
                            <Badge
                                invisible={false}
                                classes={props.item.online ? { badge: classes.active_badge } : { badge: classes.inactive_badge }}
                                onClick={() => changeOnline()}
                            />
                        </Tooltip>
                    }

                    title={props.item.name_PT}

                />
                <CardActions disableSpacing className={classes.cardActions}>
                    <div>
                        <Tooltip title='Ver mais' placement='bottom'>
                            <IconButton aria-label="ver mais" component='a' onClick={() => setViewMoreDialog(true)}>
                                <SeeMoreIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <Divider orientation="vertical" flexItem />
                    <div>
                        <Tooltip title='Categoria' placement='bottom'>
                            <Typography variant="body1" color="textSecondary" component="p">
                                {transformString(props.item.category)}
                            </Typography>
                        </Tooltip>
                    </div>
                    <Divider orientation="vertical" flexItem />
                    <div>
                        <Tooltip title='Editar' placement='bottom'>
                            <IconButton aria-label="editar item" onClick={() => setEditDialog(true)}>
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title='Eliminar' placement='bottom' >
                            <IconButton aria-label="eliminar item" onClick={() => setDeleteDialog(true)}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </CardActions>
            </Card>
            <Dialog
                open={deleteDialog}
                onClose={() => setDeleteDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Tem a certeza que pretende eliminar este registo?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Se eliminar este Item não poderá reverter este porcesso. Tem a certeza?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialog(false)} color="primary" autoFocus>
                        Cancelar
                    </Button>
                    <Button onClick={handleDetelePartner} color="primary">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth='md'
                open={editDialog}
                onClose={() => setEditDialog(false)}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent>
                    <EditShopItemDialog data={props.item} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setEditDialog(false); props.refresh() }} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={feedbackDialog}
                onClose={() => setFeedbackDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="confirmation-dialog-title">{feedbackTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirmation-dialog-description">
                        {feedbackContent}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth='md'
                open={viewMoreDialog}
                onClose={() => setViewMoreDialog(false)}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent>
                    <ViewMoreDialog data={props.item} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setViewMoreDialog(false)} color="primary">
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ShopItemCard

import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem'

export default class InfoPT extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: props.defaultData.name,
            webpage: props.defaultData.webpage,
            location: props.defaultData.location,
            description_PT: props.defaultData.description_PT,
            profession_PT: props.defaultData.profession_PT,
            online: props.defaultData.online,
            formErrors: { name: '', wepage: '', location: '', description_PT: '', profession_PT: '' },
            nameIsValid: false,
            webpageIsValid: true,
            locationIsValid: false,
            description_PTIsValid: false,
            profession_PTIsValid: false,
            formIsValid: false,
        }
    }

    handleInput(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.props.updateMainState(name, value);
        this.setState({ [name]: value }, () => { this.validateFields(name, value) });
    }

    validateFields(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let nameIsValid = this.state.nameIsValid;
        let webpageIsValid = this.state.webpageIsValid;
        let locationIsValid = this.state.locationIsValid;
        let description_PTIsValid = this.state.description_PTIsValid;
        let profession_PTIsValid = this.state.profession_PTIsValid;

        switch (fieldName) {
            case 'name':
                nameIsValid = new RegExp(/^[a-zA-Z\u00C0-\u00FF]{3,}[\s]{1}[a-zA-Z\u00C0-\u00FF]{1,}$/).test(value) && value.length > 4;
                fieldValidationErrors.name = nameIsValid ? '' : 'Nome Invalido'
                break;
            case 'webpage':
                webpageIsValid = new RegExp(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/).test(value) || value.length === 0;
                fieldValidationErrors.webpage = webpageIsValid ? '' : 'Webpage URL Invalido, só podem ser adicionados URL começados por http:// ou https://. Ex: https://meuwebsite.com'
                break;
            case 'location':
                locationIsValid = new RegExp(/^[a-zA-Z\u00C0-\u00FF\s*]*$/).test(value) && value.length > 1;
                fieldValidationErrors.location = locationIsValid ? '' : 'Localização Inválida'
                break;
            case 'description_PT':
                description_PTIsValid = value.length > 1;
                fieldValidationErrors.description_PT = description_PTIsValid ? '' : 'Deve escrever uma descrição'
                break;
            case 'profession_PT':
                profession_PTIsValid = new RegExp(/^[a-zA-Z\u00C0-\u00FF\s*]*$/).test(value) && value.length > 2;
                fieldValidationErrors.profession_PT = profession_PTIsValid ? '' : 'Profissão inválida'
                break;
            default:
                break;
        }


        this.setState({
            formErrors: fieldValidationErrors,
            nameIsValid: nameIsValid,
            webpageIsValid: webpageIsValid,
            locationIsValid: locationIsValid,
            description_PTIsValid: description_PTIsValid,
            profession_PTIsValid: profession_PTIsValid
        }, this.validateForm)

    }

    validateForm() {
        this.setState({
            formIsValid:
                this.state.nameIsValid &&
                this.state.locationIsValid &&
                this.state.webpageIsValid &&
                this.state.description_PTIsValid &&
                this.state.profession_PTIsValid
        }, () => {
            this.props.updateMainState('validForm', this.state.formIsValid);
        })
    }

    render() {
        return (
            <React.Fragment>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={5}>
                        <TextField
                            required
                            error={!this.state.nameIsValid ? true : false}
                            helperText={this.state.formErrors.name}
                            autoFocus
                            id="name"
                            name="name"
                            label="Nome (primeiro e último)"
                            fullWidth
                            type="text"
                            defaultValue={this.state.name}
                            autoComplete='given-name'
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            error={!this.state.locationIsValid ? true : false}
                            helperText={this.state.formErrors.location}
                            id="location"
                            name="location"
                            label="Localização"
                            type='text'
                            fullWidth
                            defaultValue={this.state.location}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <InputLabel id="online">Online?</InputLabel>
                        <Select
                            required
                            fullWidth
                            labelId="online"
                            id="online"
                            name="online"
                            value={this.state.online}
                            onChange={(e) => this.handleInput(e)}
                        >
                            <MenuItem value={true}>Sim</MenuItem>
                            <MenuItem value={false}>Não</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            error={!this.state.profession_PTIsValid ? true : false}
                            helperText={this.state.formErrors.profession_PT}
                            id="profession_PT"
                            name="profession_PT"
                            label="Profissão"
                            type='text'
                            fullWidth
                            defaultValue={this.state.profession_PT}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            error={!this.state.webpageIsValid ? true : false}
                            helperText={this.state.formErrors.webpage}
                            id="webpage"
                            name="webpage"
                            label="Webpage"
                            type='text'
                            fullWidth
                            defaultValue={this.state.webpage}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            required
                            multiline
                            rows={8}
                            error={!this.state.description_PTIsValid ? true : false}
                            helperText={this.state.formErrors.description_PT}
                            id="description_PT"
                            name="description_PT"
                            label="Descrição"
                            type='text'
                            fullWidth
                            defaultValue={this.state.description_PT}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}
import React, { useState, useEffect } from 'react'
import MUIDataTable from 'mui-datatables'
import { FormGroup, FormLabel, Menu, MenuItem, TextField } from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios'
import Cookies from 'universal-cookie'

import FeedBackDialog from '../ErrorDialog/FeedBackDialog';
import EditClient from './EditClient';

const cookies = new Cookies();

const getMuiTheme = () => createTheme({
    overrides: {
        MUIDataTableBodyCell: {
            root: {
                textAlign: 'center'
            }
        },
        MUIDataTableHeadCell: {
            root: {
                textAlign: 'center',
                paddingLeft: '30px'
            },
            contentWrapper: {
                display: 'unset'
            }
        },
        MuiButton: {
            root: {
                padding: 0
            },
            text: {
                padding: 0
            }
        }
    }
})

export default function ClientsTable(props) {

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openEditDialog, setopenEditDialog] = useState(false);
    const [data, setData] = useState([]);

    const [feedbackDialog, setFeedbackDialog] = useState(false);
    const [feedbackTitle, setFeedbackTitle] = useState('');
    const [feedbackContent, setFeedbackContent] = useState('')

    useEffect(() => {
        setData(props.data)
    }, [props.data])

    const handleClick = (event, rowMeta) => {
        setAnchorEl(rowMeta.event.target);
    };

    const handleCloseEditModal = () => {
        setopenEditDialog(false);
        setAnchorEl(null);
        props.refreshData();
    }

    const handleDelete = () => {
        axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/clients/${selectedRowData.id}`, { headers: { 'apikey': cookies.get('key') } })
            .then((res) => {
                handleDeleteClose();
                setAnchorEl(null);
                setFeedbackDialog(true);
                setFeedbackTitle('Sucesso')
                setFeedbackContent(`Client foi eliminado`)
            }, (err) => {
                console.log(err);
                setFeedbackDialog(true);
                setFeedbackTitle('Erro')
                setFeedbackContent(`Não foi possivel comunicar com o servidor. Se o problema persitir contacte o administrador do servidor. ${err}`)
            });
    }

    const handleDeleteClose = () => {
        setOpenDeleteDialog(false);
        setAnchorEl(null);
        props.refreshData();
    }

    const columns = [
        {
            name: 'id',
            label: 'ID',
            options: { filter: false, }
        },
        {
            name: 'name',
            label: 'Nome',
            options: { filter: false }
        },
        {
            name: 'email',
            label: 'Email',
            options: { filter: false }
        },
        {
            name: 'address',
            label: 'Morada',
            options: { filter: false }
        },
        {
            name: 'nif',
            label: 'NIF/Passaporte',
            options: { filter: false }
        },
        {
            name: 'phone',
            label: 'Nº Telemovel',
            options: { filter: false }
        },
        {
            name: 'nReservations',
            label: 'Nº de Reservas',
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return value;
                },
                searchable: false,
                filter: true,
                filterType: 'custom',
                customFilterListOptions: {
                    render: v => {
                        if (v[0] && v[1]) {
                            return [`Min: ${v[0]}`, `Max: ${v[1]}`];
                        } else if (v[0]) {
                            return `Min: ${v[0]}`;
                        } else if (v[1]) {
                            return `Max: ${v[1]}`;
                        }
                        return [];
                    },
                    update: (filterList, filterPos, index) => {
                        if (filterPos === 0) {
                            filterList[index].splice(filterPos, 1, '');
                        } else if (filterPos === 1) {
                            filterList[index].splice(filterPos, 1);
                        } else if (filterPos === -1) {
                            filterList[index] = [];
                        }

                        return filterList;
                    },
                },
                filterOptions: {
                    names: [],
                    logic(value, filters) {
                        if (filters[0] && filters[1]) {
                            return value <= filters[0] || value >= filters[1];
                        } else if (filters[0]) {
                            return value <= filters[0];
                        } else if (filters[1]) {
                            return value >= filters[1];
                        }
                        return false;
                    },
                    display: (filterList, onChange, index, column) => (
                        <div>
                            <FormLabel>Nº Reservas</FormLabel>
                            <FormGroup row>
                                <TextField
                                    label='Min'
                                    value={filterList[index][0] || ''}
                                    onChange={event => {
                                        filterList[index][0] = event.target.value;
                                        onChange(filterList[index], index, column)
                                    }}
                                    style={{ width: '25%', marginRight: '5%' }}
                                />
                                <TextField
                                    label='Max'
                                    value={filterList[index][1] || ''}
                                    onChange={event => {
                                        filterList[index][1] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}
                                    style={{ width: '25%' }}
                                />
                            </FormGroup>
                        </div>
                    ),
                },
            },
        },
        {
            name: 'lastReservation',
            label: 'Ultima Reserva',
            options: {
                filter: true,
                filterType: 'custom',
                searchable: false,
                customFilterListOptions: {
                    render: v => {
                        if (v[0] && v[1]) {
                            return [`Data Inicio: ${v[0]}`, `Data Fim: ${v[1]}`];
                        } else if (v[0]) {
                            return `Data Inicio: ${v[0]}`;
                        } else if (v[1]) {
                            return `Data Fim: ${v[1]}`;
                        }
                        return [];
                    },
                    update: (filterList, filterPos, index) => {
                        if (filterPos === 0) {
                            filterList[index].splice(filterPos, 1, '');
                        } else if (filterPos === 1) {
                            filterList[index].splice(filterPos, 1);
                        } else if (filterPos === -1) {
                            filterList[index] = [];
                        }

                        return filterList;
                    },
                },
                filterOptions: {
                    names: [],
                    logic(date, filters) {
                        if (filters[0] && filters[1]) {
                            return date < filters[0] || date > filters[1];
                        } else if (filters[0]) {
                            return date < filters[0];
                        } else if (filters[1]) {
                            return date > filters[1];
                        }
                        return false;
                    },
                    display: (filterList, onChange, index, column) => (
                        <div>
                            <FormLabel>Check In</FormLabel>
                            <FormGroup row>
                                <TextField
                                    label='Data inicio'
                                    value={filterList[index][0] || ''}
                                    onChange={event => {
                                        filterList[index][0] = event.target.value;
                                        onChange(filterList[index], index, column)
                                    }}
                                    style={{ width: '45%', marginRight: '5%' }}
                                />
                                <TextField
                                    label='Data Fim'
                                    value={filterList[index][1] || ''}
                                    onChange={event => {
                                        filterList[index][1] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}
                                    style={{ width: '45%' }}
                                />
                            </FormGroup>
                        </div>
                    ),
                },
            },
        }
    ]

    const options = {
        filter: true,
        filterType: 'multiselect',
        responsive: 'standard',
        tableBodyHeight: '450px',
        tableBodyMaxHeigth: '',
        selectableRows: 'none',
        rowsPerPageOptions: [10, 25, 50, 100],
        onCellClick: handleClick,
        onRowClick: item => setSelectedRowData({
            id: item[0],
            name: item[1],
            email: item[2],
            address: item[3],
            nif: item[4],
            phone: item[5],
            nReservations: item[6],
        }),
        textLabels: {
            body: {
                noMatch: "Sem resultados",
                toolTip: "Ordenar",
                columnHeaderToolTip: column => `Ordenar por ${column.label}`
            },
            pagination: {
                next: 'Proxima',
                previous: 'Anterior',
                rowsPerPage: 'Linhas por pagina',
                displayRows: 'de'
            },
            toolbar: {
                search: 'Procurar',
                downloadCsv: 'Download CSV',
                print: 'Imprimir',
                viewColumns: 'Ver Colunas',
                filterTable: 'Filtrar Tabela'
            },
            filter: {
                all: 'ALL',
                title: 'FILTROS',
                reset: 'RESET'
            },
            viewColumns: {
                title: 'Mostrar Colunas',
                titleAria: 'Mostrar/Esconder Colunas',
            }
        }
    }

    return (
        <>
            <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title={"Clientes"}
                    data={data}
                    columns={columns}
                    options={options}
                />
            </MuiThemeProvider>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={() => setopenEditDialog(true)}>Editar</MenuItem>
                <MenuItem onClick={() => setOpenDeleteDialog(true)}>Eliminar</MenuItem>
            </Menu>
            <Dialog
                open={openDeleteDialog}
                onClose={handleDeleteClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Tem a certeza que pretende eliminar este registo"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Se eliminar esta cliente não poderá reverter este porcesso. Tem a certeza?
                        Ao eliminar este cliente não irá influenciar qualquer reserva em seu nome.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDeleteClose} color="primary" autoFocus>
                        Cancelar
                    </Button>
                    <Button onClick={handleDelete} color="primary">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth='md'
                open={openEditDialog}
                onClose={handleCloseEditModal}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogContent>
                    <EditClient data={selectedRowData} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseEditModal} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <FeedBackDialog
                open={feedbackDialog}
                close={() => setFeedbackDialog(false)}
                title={feedbackTitle}
                content={feedbackContent} />
        </>
    )
}
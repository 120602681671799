import React, { Component } from 'react'
import axios from 'axios'
import { Link, Redirect } from 'react-router-dom'
import { Button, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

import logo from '../assets/login/home.png'

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        width: '100px',
        height: 'auto'
    },
    form: {
        width: '300px',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(2, 0, 2),

    },
    box: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(2, 0, 2)
    },
    backButton: {
        width: '300px'
    }
});


class PassRecovery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submiting: false,
            error: false,
            sucess: false,
            userEmail: '',
            redirect: false,
        }
    }


    setInitialState() {
        return {
            submiting: false,
            userEmail: '',
        }
    }

    handleSubmit = event => {
        event.preventDefault();

        this.setState({
            submiting: true,
        }, () => {
            axios.post(`${process.env.REACT_APP_SERVER_URL}/api/passresetrequest`, {
                email: event.target[0].value,
            }).then((res) => {
                this.setState({
                    submiting: false,
                    sucess: true
                })
            }, (err) => {
                console.log(err)
                this.setState({
                    submiting: false,
                    error: true,
                })
                this.setState(this.setInitialState());
            });
        })
    }

    render() {
        const { classes } = this.props;

        return (
            <Container component="main" maxWidth="md">
                <CssBaseline />
                <div className={classes.paper}>
                    <Link to="/"><img src={logo} alt="" className={classes.avatar} style={{ marginBottom: '20px' }} /></Link>

                    <Typography style={{ marginTop: '10px', textAlign: 'center' }}> Introduza o seu email e siga as instruções </Typography>
                    <form className={classes.form} onSubmit={this.handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="userName"
                            label="Email"
                            name="usernameField"
                            autoComplete="email"
                            type='email'
                            autoFocus
                        />
                        {this.state.error && (
                            <Typography style={{ marginTop: '10px', color: 'red', textAlign: 'center' }}> Usuário não existe </Typography>
                        )}
                        {this.state.submiting ? (
                            <Box className={classes.box}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Enviar
                            </Button>)
                        }
                    </form>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => this.setState({ redirect: true })}
                        className={classes.backButton}
                    >
                        Voltar
                    </Button>
                </div>
                <Dialog
                    fullWidth
                    maxWidth='sm'
                    open={this.state.sucess}
                    onClose={() => this.setState({ redirect: true })}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogContent>
                        <Typography variant='h6' gutterBottom>O seu pedido foi efetuado com sucesso</Typography>
                        <Typography variant='subtitle2'>Irá receber um email com as instruções para criar uma nova Password</Typography>
                        <Typography variant='subtitle2'>Irá ser redirecionado para a pagina de login assim que fehcar esta janela</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({ redirect: true })} color="primary">
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>
                {this.state.redirect ? (<Redirect to='/' />) : ''}
            </Container>
        )
    }
}

export default withStyles(styles)(PassRecovery)

import React from 'react'
import Box from '@material-ui/core/Box'
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

import './SideMenu.css'

import { bottomListItems, mainListItems, secondaryListItems } from './ListItems';

function SideMenu() {


    return (
        <div className="sideMenu-container">
            <Toolbar />
            <Box sx={{ overflow: 'auto' }}>
                <List>{mainListItems}</List>
                <Divider />
                <div style={{ display: 'flex', flexDirection:'column', justifyContent:'space-between', height: '100%'}}>
                    <List>{secondaryListItems}</List>
                    <List style={{ position: 'fixed', bottom: '0', width: '230px'}}>{bottomListItems}</List>
                </div>
            </Box>
        </div>
    );
}

export default SideMenu

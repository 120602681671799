import React from 'react'

import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List';

export default class Review extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: props.data.name,
            email: props.data.email,
            phone: props.data.phone,
            address: props.data.address,
            nif: props.data.nif
        }
    }

    componentDidMount() {
        this.props.updateMainState('formValid', true);
    }

    transformString(string, maxChar, insertDots) {
        return string.slice(0, maxChar) + (((string.length > maxChar) && insertDots) ? '...' : '');
    }

    render() {
        return (
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    Confirme os dados
                </Typography>
                <List disablePadding>
                    <ListItem key={this.state.name}>
                        <ListItemText primary="Nome" />
                        <Typography variant="body2">{this.state.name}</Typography>
                    </ListItem>
                    <ListItem key={this.state.location}>
                        <ListItemText primary="Email" />
                        <Typography variant="body2">{this.state.email}</Typography>
                    </ListItem>
                    <ListItem key={this.state.vrUrl}>
                        <ListItemText primary="Nº Telemovel" />
                        <Typography variant="body2">{this.state.phone}</Typography>
                    </ListItem>
                    <ListItem key={this.state.address}>
                        <ListItemText primary="Morada" />
                        <Typography variant="body2">{this.state.address}</Typography>
                    </ListItem>
                    <ListItem key={this.state.nif}>
                        <ListItemText primary="NIF/Passaporte" />
                        <Typography variant="body2">{this.state.nif}</Typography>
                    </ListItem>
                </List>
            </React.Fragment>
        );
    }
}
import * as React from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import CoworkIcon from '@material-ui/icons/House';
import PartnersIcon from '@material-ui/icons/People';
import ReservationIcon from '@material-ui/icons/ReceiptRounded';
import UserIcon from '@material-ui/icons/Person';
import StoreIcon from '@material-ui/icons/LocalGroceryStore';
import AssignmentIcon from '@material-ui/icons/Assignment';
import FeedbackIcon from '@material-ui/icons/Feedback'
import HelpIcon from '@material-ui/icons/HelpOutline'
import { Link as RouterLink } from 'react-router-dom'


export const mainListItems = (
	<div>
		<ListItem button component={RouterLink} to={{ pathname: '/app/dashboard' }}>
			<ListItemIcon>
				<DashboardIcon />
			</ListItemIcon>
			<ListItemText primary="Dashboard" />
		</ListItem>
		<ListItem button component={RouterLink} to={{ pathname: '/app/coworks', state: { test: 'test' } }}>
			<ListItemIcon>
				<CoworkIcon />
			</ListItemIcon>
			<ListItemText primary="Coworks" />
		</ListItem>
		<ListItem button component={RouterLink} to={{ pathname: '/app/partners', state: { test: 'test' } }}>
			<ListItemIcon>
				<PartnersIcon />
			</ListItemIcon>
			<ListItemText primary="Parceiros" />
		</ListItem>
		<ListItem button component={RouterLink} to={{ pathname: '/app/reservations' }}>
			<ListItemIcon>
				<ReservationIcon />
			</ListItemIcon>
			<ListItemText primary="Reservas" />
		</ListItem>
		<ListItem button component={RouterLink} to={{ pathname: '/app/store', state: { test: 'test' } }}>
			<ListItemIcon>
				<StoreIcon />
			</ListItemIcon>
			<ListItemText primary="Loja" />
		</ListItem>
		<ListItem button component={RouterLink} to={{ pathname: '/app/users', state: { test: 'test' } }}>
			<ListItemIcon>
				<UserIcon />
			</ListItemIcon>
			<ListItemText primary="Users" />
		</ListItem>
	</div>
);

export const secondaryListItems = (
	<div>
		<ListItem button component={RouterLink} to={{ pathname: '/app/clients' }}>
			<ListItemIcon>
				<PartnersIcon />
			</ListItemIcon>
			<ListItemText primary="Clientes" />
		</ListItem>
		<ListItem button component={RouterLink} to={{ pathname: '/app/feedback' }}>
			<ListItemIcon>
				<FeedbackIcon />
			</ListItemIcon>
			<ListItemText primary="Feedback" />
		</ListItem>
		<ListItem button component={RouterLink} to={{ pathname: '/app/analytics' }}>
			<ListItemIcon>
				<AssignmentIcon />
			</ListItemIcon>
			<ListItemText primary="Estatísticas" />
		</ListItem>
	</div>
);

export const bottomListItems = (
	<div>
		<ListItem button component={RouterLink} to={{ pathname: '/app/help' }}>
			<ListItemIcon>
				<HelpIcon />
			</ListItemIcon>
			<ListItemText primary="Ajuda" />
		</ListItem>
	</div>
)
import React from 'react'

import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List';

export default class Review extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name_PT: props.data.name_PT,
            name_EN: props.data.name_EN,
            dimensions: props.data.dimensions,
            materials_PT: props.data.materials_PT,
            materials_EN: props.data.materials_EN,
            origin: props.data.origin,
            price: props.data.price,
            description_PT: props.data.description_PT,
            description_EN: props.data.description_EN,
            online: props.data.online,
            category: props.data.category,
            delivery_time: props.data.delivery_time,
            portrait: props.data.portrait,
            sliderImage1: props.data.sliderImage1,
            sliderImage2: props.data.sliderImage2,
            sliderImage3: props.data.sliderImage3,
        }
    }

    componentDidMount() {
        this.props.updateMainState('formValid', true);
    }

    transformString(string, maxChar, insertDots) {
        return string.slice(0, maxChar) + (((string.length > maxChar) && insertDots) ? '...' : '');
    }

    transformCategoryString = (string) => {
        switch(string) {
            case 'mobiliario': return 'Mobiliário';
            case 'iluminaria': return 'Iluminária';
            case 'tapecaria': return 'Tapeçaria';
            case 'decoracao': return 'Decoração';
            default: return 'Not a valid string'
        }
    }

    render() {
        return (
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    Confirme os dados
                </Typography>
                <List disablePadding>
                    <ListItem key={this.state.name_PT}>
                        <ListItemText primary="Nome em Português" />
                        <Typography variant="body2">{this.state.name_PT}</Typography>
                    </ListItem>
                    <ListItem key={this.state.name_EN}>
                        <ListItemText primary="Nome em Ingês" />
                        <Typography variant="body2">{this.state.name_EN}</Typography>
                    </ListItem>
                    <ListItem key={this.state.dimensions}>
                        <ListItemText primary="Dimensões" />
                        <Typography variant="body2">{this.state.dimensions}</Typography>
                    </ListItem>
                    <ListItem key={this.state.materials_PT}>
                        <ListItemText primary="Materiais em Português" />
                        <Typography variant="body2">{this.state.materials_PT}</Typography>
                    </ListItem>
                    <ListItem key={this.state.materials_EN}>
                        <ListItemText primary="Materiais em Inglês" />
                        <Typography variant="body2">{this.state.materials_EN}</Typography>
                    </ListItem>
                    <ListItem key={this.state.description_PT}>
                        <ListItemText primary="Origem/Artesão" />
                        <Typography variant="body2">{this.state.origin}</Typography>
                    </ListItem>
                    <ListItem key='descriptionPT'>
                        <ListItemText primary="Descrição em Português" />
                        <Typography variant="body2">{this.transformString(this.state.description_PT, 25, true)}</Typography>
                    </ListItem>
                    <ListItem key='descriptionEN'>
                        <ListItemText primary="Descrição em Inglês" />
                        <Typography variant="body2">{this.transformString(this.state.description_EN, 25, true)}</Typography>
                    </ListItem>
                    <ListItem key={'online'}>
                        <ListItemText primary="Online?" />
                        <Typography variant="body2">{this.state.online ? 'Sim' : 'Não'}</Typography>
                    </ListItem>
                    <ListItem key={this.state.price}>
                        <ListItemText primary="Preço" />
                        <Typography variant="body2">{`${this.state.price} €`}</Typography>
                    </ListItem>
                    <ListItem key={this.state.category}>
                        <ListItemText primary="Categoria" />
                        <Typography variant="body2">{this.transformCategoryString(this.state.category)}</Typography>
                    </ListItem>
                
                    <ListItem key='portrait'>
                        <ListItemText primary="Imagem Retrato" />
                        <Typography variant="body2">{this.state.portrait[0].name}</Typography>
                    </ListItem>
                    <ListItem key='imageslider1'>
                        <ListItemText primary="Imagem Slider 1" />
                        <Typography variant="body2">{this.state.sliderImage1[0].name}</Typography>
                    </ListItem>
                    <ListItem key='imageslider2'>
                        <ListItemText primary="Imagem slider 2" />
                        <Typography variant="body2">{this.state.sliderImage2[0].name}</Typography>
                    </ListItem>
                    <ListItem key='imageslider3'>
                        <ListItemText primary="Imagem slider 3" />
                        <Typography variant="body2">{this.state.sliderImage3[0].name}</Typography>
                    </ListItem>
                </List>
            </React.Fragment>
        );
    }
}
import moment from 'moment';
import React, { useState } from 'react';
import { Line } from 'react-chartjs-2'
import { RRule } from 'rrule';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    navButtons: {
        letterSpacing: 0,
        border: '1px solid rgba(0, 0, 0, 0.2)',
        boxShadow: 'inset 0px 3px 5px rgba(0, 0, 0, 0.13)',
        padding: '0px 5px 0px 5px',

    },
    selection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '5px',
        marginRight: '5px',
        marginLeft: '15px',
        marginBottom: '0px'
    },
}));

const buildRRule = (timeFrame) => {

    let rule = '';

    switch (timeFrame) {
        case 'semana':
            rule = new RRule({
                dtstart: moment().subtract(1, 'week')._d,
                freq: RRule.DAILY,
                interval: 1,
                count: 8
            }).all();
            break;
        case 'mes':
            rule = new RRule({
                dtstart: moment().subtract(1, 'months')._d,
                freq: RRule.DAILY,
                interval: 1,
                count: 32
            }).all();
            break;
        case 'ano':
            rule = new RRule({
                dtstart: moment().subtract(1, 'year')._d,
                freq: RRule.MONTHLY,
                interval: 1,
                count: 13
            }).all();
            break;
        default: return []
    }

    for (let i = 0; i < rule.length; i++) {
        if (timeFrame === 'ano') {
            rule[i] = moment(rule[i]).format('MMM YYYY')
        } else {
            rule[i] = moment(rule[i]).format('DD MMM')
        }
    }

    return rule;
}

function FooterGraph(props) {
    const classes = useStyles();
    const [selectedTimeFrame, setSelectedTimeFrame] = useState('mes');

    let clients = [...props.data];
    let clientCounter = 0;

    const prepareDataToGraph = () => {
        const data = []
        const numberOfDays = buildRRule(selectedTimeFrame);

        numberOfDays.forEach((day, index) => {
            data.push({ x: day, y: calculateClientsPerDay(day) })
        })

        return data;
    }

    const calculateClientsPerDay = (day) => {

        if (selectedTimeFrame === 'ano') {
            clients.forEach((client, index) => {
                if (moment(new Date(client.createdAt)).format('MMM YYYY') === day) {
                    clients = clients.filter(item => item !== client) // clients.splice(index, 1) doesnt seem to work here
                    clientCounter++;
                }
            })
            return clientCounter;
        }

        clients.forEach((client, index) => {
            if (moment(new Date(client.createdAt)).format('DD MMM') === day) {
                clients = clients.filter(item => item !== client) // clients.splice(index, 1) doesnt seem to work here
                clientCounter++;
            }
        })

        return clientCounter;
    }

    const state = {
        labels: buildRRule(selectedTimeFrame),
        datasets: [
            {
                data: prepareDataToGraph(),
                backgroundColor: 'rgba(75,192,192,1)',
                borderColor: 'rgba(75,192,192,0.5)'
            },
        ],
    }

    return (
        <div>
            <div className={classes.selection}>
                <Typography>Total de clientes</Typography>
                <ToggleButtonGroup
                    value={selectedTimeFrame}
                    exclusive
                    onChange={(event, timeFrame) => setSelectedTimeFrame(timeFrame)}
                    aria-label='selected cowork'
                >
                    <ToggleButton className={classes.navButtons} value='semana'>Semana</ToggleButton>
                    <ToggleButton className={classes.navButtons} value='mes'>Mês</ToggleButton>
                    <ToggleButton className={classes.navButtons} value='ano'>Ano</ToggleButton>
                </ToggleButtonGroup>
            </div>
            <div>
                <Line
                    data={state}
                    width={1200}
                    height={180}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        tension: 0.3,
                        cubicInterpolationMode: 'monotone',
                        scales: {
                            x: {
                                grid: {
                                    display: false,
                                    type: 'time',
                                },

                            },
                            y: {
                                ticks: {
                                    stepSize: 1
                                }
                            }
                        },
                        plugins: {
                            legend: {
                                display: false,
                            },
                            title: {
                                display: false,
                                position: 'top',
                                text: ['Total de Clientes'],
                                padding: 10
                            },
                        },
                    }}
                />
            </div>
        </div>
    )
}

export default FooterGraph;
import React from 'react'

import './css/Help.css'


function Help() {

    return (
        <div className='helpMainDiv' >
            <div className="help-content">
                <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_SERVER_URL}/resources/files/misc/help.pdf`}>Ver Manual de Utilização</a>
            </div>
        </div>
    )
}

export default Help
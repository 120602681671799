import React, { Component } from 'react'
import { TextField, Button, Tooltip, Grid} from '@material-ui/core';
import { Add } from "@material-ui/icons"

export default class InfoEN extends Component {

    constructor(props) {
        super(props);
        this.state = {
            short_description_EN: props.defaultData.short_description_EN,
            full_description_EN: props.defaultData.full_description_EN,
            tncPDFen: props.defaultData.tncPDFen,
            formErrors: { short_description_EN: '', full_description_EN: '' },
            short_description_ENIsValid: true,
            full_description_ENIsValid: true,
            formIsValid: true,
        }
    }

    handlePDFUpload = (event) => {
        this.props.updateMainState('tncPDFen', event.target.files[0]);
        this.setState({ tncPDFen: event.target.files[0] }, () => this.validateForm())
    }

    handleInput(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.props.updateMainState(name, value);
        this.setState({ [name]: value }, () => { this.validateFields(name, value) });
    }

    validateFields(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let short_description_ENIsValid = this.state.short_description_ENIsValid;
        let full_description_ENIsValid = this.state.full_description_ENIsValid;

        switch (fieldName) {
            case 'description_EN':
                short_description_ENIsValid = value.length > 1;
                fieldValidationErrors.short_description_EN = short_description_ENIsValid ? '' : 'Deve escrever uma descrição'
                break;
            case 'profession_EN':
                full_description_ENIsValid = value.length > 1;
                fieldValidationErrors.full_description_EN = full_description_ENIsValid ? '' : 'Deve escrever uma descrição'
                break;
            default:
                break;
        }


        this.setState({
            formErrors: fieldValidationErrors,
            short_description_ENIsValid: short_description_ENIsValid,
            full_description_ENIsValid: full_description_ENIsValid
        }, this.validateForm)

    }

    validateForm() {
        this.setState({
            formIsValid:
                this.state.short_description_ENIsValid &&
                this.state.full_description_ENIsValid
        }, () => {
            this.props.updateMainState('validForm', this.state.formIsValid);
        })
    }

    render() {
        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={10} style={{ paddingRight: '0' }}>
                        <TextField
                            required
                            id="tnc"
                            name="tnc"
                            label="Termos de utilização em Inglês"
                            type='text'
                            fullWidth
                            value={this.state.tncPDFen === null ? 'Deixar vazio se não quiser alterar' : this.state.tncPDFen.name}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12} sm={2} style={{ marginBottom: '-12px', padding: '0', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Tooltip title='Upload PDF dos termos de utilização'>
                            <label htmlFor="upload-photo">
                                <input
                                    style={{ display: 'none' }}
                                    id="upload-photo"
                                    name="upload-photo"
                                    type="file"
                                    accept='application/pdf'
                                    onChange={e => this.handlePDFUpload(e)}
                                />
                                <Button size='small' color="primary" variant="contained" component="span">
                                    <Add />
                                </Button>
                            </label>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            required
                            multiline
                            variant='outlined'
                            error={!this.state.short_description_ENIsValid ? true : false}
                            helperText={this.state.formErrors.short_description_EN}
                            id="short_description_EN"
                            name="short_description_EN"
                            label="Short Description (200 characters Max.)"
                            type='text'
                            rows={3}
                            inputProps={{ maxLength: 200 }}
                            fullWidth
                            defaultValue={this.state.short_description_EN}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            required
                            variant='outlined'
                            multiline
                            rows={10}
                            error={!this.state.full_description_EN ? true : false}
                            helperText={this.state.formErrors.full_description_EN}
                            id="full_description_EN"
                            name="full_description_EN"
                            label="Full Description"
                            type='text'
                            fullWidth
                            defaultValue={this.state.full_description_EN}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}
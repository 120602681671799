import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';

import { Tooltip } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Badge from '@material-ui/core/Badge'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import ItemIcon from '@material-ui/icons/EventSeat'
import CategoryIcon from '@material-ui/icons/Category'
import DeliveryIcon from '@material-ui/icons/LocalShipping'
import DimensionsIcon from '@material-ui/icons/Height'
import MaterialIcon from '@material-ui/icons/ColorizeRounded'
import OriginIcon from '@material-ui/icons/TripOrigin'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';


const styles = theme => ({
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    card: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '20px',
        width: '100%'

    },
    imagesCard: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '15px',
        marginBottom: '15px',
        cursor: 'pointer',
        '&:hover': {
            filter: 'contrast(0.7)'
        }
    },
    header: {
        width: '100%',
        height: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

    },
    contentCard: {
        overflowWrap: 'anywhere',
        marginBottom: '15px',
        width: '70%'
    },
    media: {
        height: 'auto',
        width: '100%',
    },
    full_media: {
        height: '600px',
        width: 'auto',
        backgroundSize: 'contain'
    },
    description: {
        whiteSpace: 'pre-line',
        textAlign: 'center'
    },
    active_badge: {
        backgroundColor: 'green',
        marginTop: '-10px',
        transform: 'scale(0.7)'
    },
    inactive_badge: {
        backgroundColor: 'rgb(192,0,0)',
        marginTop: '-10px',
        transform: 'scale(0.7)'
    },
    avatar: {
        backgroundColor: 'rgb(187,222,251)',
        color: 'rgb(30,136,229)'
    }
});

class ViewMoreDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.data.name_PT,
            dimensions: this.props.data.dimensions,
            materials: this.props.data.materials_PT,
            origin: this.props.data.origin,
            price: this.props.data.price,
            description: this.props.data.description_PT,
            description_EN: this.props.data.description_EN,
            category: this.props.data.category,
            online: this.props.data.online,
            delivery: this.props.data.delivery_time,
            portrait: this.props.data.portrait,
            sliderImage1: this.props.data.sliderImage1,
            sliderImage2: this.props.data.sliderImage2,
            sliderImage3: this.props.data.sliderImage3,

            imageDialog: false,
            activeImage: null,
        }
    }


    render() {

        const stringToColor = (string) => {

            if (string === undefined) string = 'No User'

            let hash = 0;
            let i;

            for (i = 0; i < string.length; i += 1) {
                hash = string.charCodeAt(i) + ((hash << 5) - hash);
            }

            let color = '#';

            for (i = 0; i < 3; i += 1) {
                const value = (hash >> (i * 8)) & 0xff;
                color += `00${value.toString(16)}`.substr(-2);
            }
            return color
        }

        const { classes } = this.props;

        return (
            <React.Fragment>
                <CssBaseline />
                <Card elevation={3} className={classes.card}>
                    <CardHeader className={classes.header}
                        avatar={
                            <Avatar style={{ backgroundColor: stringToColor(this.state.category) }}>
                                {this.state.category[0].toUpperCase()}
                            </Avatar>
                        }
                        action={
                            <Tooltip title={this.state.online ? 'Online' : 'Offline'} placement='right' >
                                <Badge invisible={false} classes={this.state.online ? { badge: classes.active_badge } : { badge: classes.inactive_badge }} overlap='circular' />
                            </Tooltip>
                        }
                        title={this.state.name}
                        subheader={this.state.category}
                    />
                </Card>
                <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                        <Card elevation={3} className={classes.imagesCard} onClick={() => this.setState({ imageDialog: true, activeImage: this.state.portrait })} >
                            <CardMedia
                                className={classes.media}
                                image={`${process.env.REACT_APP_SERVER_URL}/resources/images/uploads/store/${this.state.portrait}`}
                                title="Portrait"
                                component="img"
                                alt="portrait"
                            />
                        </Card>
                        <Card elevation={3} className={classes.imagesCard} onClick={() => this.setState({ imageDialog: true, activeImage: this.state.sliderImage1 })} >
                            <CardMedia
                                className={classes.media}
                                image={`${process.env.REACT_APP_SERVER_URL}/resources/images/uploads/store/${this.state.sliderImage1}`}
                                title="Slider Image 1"
                                component="img"
                                alt="sliderImage2"
                            />
                        </Card>
                        <Card elevation={3} className={classes.imagesCard} onClick={() => this.setState({ imageDialog: true, activeImage: this.state.sliderImage2 })} >
                            <CardMedia
                                className={classes.media}
                                image={`${process.env.REACT_APP_SERVER_URL}/resources/images/uploads/store/${this.state.sliderImage2}`}
                                title="Slider Image 2"
                                component="img"
                                alt="sliderImage2"
                            />
                        </Card>
                        <Card elevation={3} className={classes.imagesCard} onClick={() => this.setState({ imageDialog: true, activeImage: this.state.sliderImage3 })} >
                            <CardMedia
                                className={classes.media}
                                image={`${process.env.REACT_APP_SERVER_URL}/resources/images/uploads/store/${this.state.sliderImage3}`}
                                title="Slider Image 3"
                                component="img"
                                alt="sliderImage3"
                            />
                        </Card>
                    </div>

                    <Card elevation={3} className={classes.contentCard} >
                        <CardContent className={classes.cardContent}>
                            <List style={{ paddingBottom: '0' }}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.avatar}>
                                            <ItemIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Nome" secondary={this.state.name} />
                                </ListItem>
                                <Divider light />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.avatar}>
                                            <CategoryIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Categoria" secondary={this.state.category} />
                                </ListItem>
                                <Divider light />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.avatar}>
                                            <DeliveryIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Tempo de Entrega" secondary={`${this.state.delivery} Dias`} />
                                </ListItem>
                                <Divider light />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.avatar}>
                                            <DimensionsIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Dimensões" secondary={this.state.dimensions} />
                                </ListItem>
                                <Divider light />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.avatar}>
                                            <MaterialIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Materiais" secondary={this.state.materials} />
                                </ListItem>
                                <Divider light />
                                <ListItem>
                                    <ListItemAvatar>
                                        <Avatar className={classes.avatar}>
                                            <OriginIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Origem" secondary={this.state.origin} />
                                </ListItem>
                                <Divider light />
                                <ListItem>
                                    <ListItemText style={{ whiteSpace: 'pre-wrap', marginBottom: '15px' }} primary="Descrição Português" secondary={this.state.description} />
                                </ListItem>
                                <Divider light />
                                <ListItem>
                                    <ListItemText style={{ whiteSpace: 'pre-wrap', marginBottom: '15px' }} primary="Descrição Inglês" secondary={this.state.description_EN} />
                                </ListItem>
                                <Divider light />
                            </List>
                        </CardContent>
                    </Card>
                </div>
                <Dialog
                    fullWidth={true}
                    maxWidth='md'
                    open={this.state.imageDialog}
                    onClose={() => this.setState({ imageDialog: false })}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogContent>
                        <Card elevation={0} >
                            <CardMedia
                                className={classes.full_media}
                                image={`${process.env.REACT_APP_SERVER_URL}/resources/images/uploads/store/${this.state.activeImage}`}
                                alt={this.state.activeImage}
                            />
                        </Card>
                    </DialogContent>
                </Dialog>
            </React.Fragment >
        )
    }
}

export default withStyles(styles)(ViewMoreDialog)
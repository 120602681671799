import React, { Component } from 'react'
import axios from 'axios'
import { Link, Redirect } from 'react-router-dom'
import { Button, InputAdornment, IconButton } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Cookies from 'universal-cookie'
import { Visibility, VisibilityOff } from '@material-ui/icons';

import logo from '../assets/login/home.png'

const styles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        width: '100px',
        height: 'auto'
    },
    form: {
        with: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(2, 0, 2),

    },
    box: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: theme.spacing(2, 0, 2)
    }
});

const cookies = new Cookies();

const cookieExpirationDate = () => {
    let date = new Date();
    date.setTime(date.getTime() + (12 * 60 * 60 * 1000));
    return new Date(date);
}

const cookieOptions = {
    path: "/",
    expires: cookieExpirationDate(),
    sameSite: true
}

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submiting: false,
            fireRedirect: false,
            error: false,
            userEmail: '',
            userName: '',
            key: '',
            userID: '',
            errorMessage: '',
            showPassword: false
        }
    }

    componentDidMount() {
        cookies.remove('user');
        cookies.remove('key');
        cookies.remove('auth');
        cookies.remove('userID');
    }

    setInitialState() {
        return {
            submiting: false,
            fireRedirect: false,
            userEmail: '',
            userName: '',
            key: ''
        }
    }

    handleSubmit = event => {
        event.preventDefault();

        this.setState({
            submiting: true,
            UserEmail: event.target[0].value
        }, () => {
            axios.post(`${process.env.REACT_APP_SERVER_URL}/user/auth`, {
                email: event.target[0].value,
                password: event.target[2].value,
            }).then((res) => {
                this.setState({
                    submiting: false,
                    fireRedirect: true,
                    userName: res.headers.username,
                    key: res.headers.apikey,
                    userID: res.headers.userid
                }, () => this.login())
            }, (err) => {
                console.log(err.response)
                this.setState({
                    submiting: false,
                    error: true,
                    errorMessage: err.response ? err.response.data : 'Imposivel contactar o servidor, tente mais tarde'
                })
                this.setState(this.setInitialState());
            });
        })
    }

    login() {
        axios.post(`${process.env.REACT_APP_SERVER_URL}/api/ping`, { userID: this.state.userID, time: new Date()})
        cookies.set('auth', true, cookieOptions);
        cookies.set('key', this.state.key, cookieOptions);
        cookies.set('user', this.state.userName, cookieOptions);
        cookies.set('userID', this.state.userID, cookieOptions);

        this.setState({
            submiting: false,
            fireRedirect: true,
        })
    }

    render() {
        const { classes } = this.props;

        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <img src={logo} alt="" className={classes.avatar} style={{ marginBottom: '20px' }} />

                    <form className={classes.form} onSubmit={this.handleSubmit}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="userName"
                            label="Email"
                            name="usernameField"
                            autoComplete="email"
                            type='email'
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="passwordField"
                            label="Password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            id="password"
                            autoComplete="current-password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton
                                            tabIndex='-1'
                                            aria-label='toggle password visibility'
                                            onClick={() => this.setState({ showPassword: !this.state.showPassword })}
                                        >
                                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        {this.state.error && (
                            <Typography style={{ marginTop: '10px', color: 'red', textAlign: 'center' }}>{this.state.errorMessage}</Typography>
                        )}
                        {this.state.submiting ? (
                            <Box className={classes.box}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                            >
                                Entrar
                            </Button>)
                        }
                        {this.state.fireRedirect && (
                            <Redirect to={{
                                pathname: "/app/dashboard",
                                state: { userAuth: true }
                            }} />
                        )}
                        <Grid container>
                            <Grid item xs>
                                <Link to="/passwordRecovery">Esqueci-me da password</Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Container>
        )
    }
}

export default withStyles(styles)(Login)

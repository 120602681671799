import React from 'react'
import moment from 'moment'

import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List';

export default class Review extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: props.data.title,
            cowork: props.data.cowork,
            description: props.data.description,
            start: props.data.start,
            end: props.data.end,
            color: props.data.color,
            frequency: props.data.frequency,
            interval: props.data.interval
        }
    }

    componentDidMount() {
        this.props.updateMainState('formValid', true);
    }

    transformFrequencyString(string) {
        switch (string) {
            case 'DAILY': return 'Diáriamente';
            case 'WEEKLY': return 'Semanalmente';
            case 'MONTHLY': return 'Mensalmente';
            case 'YEARLY': return 'Anualmente';
            default: return 'Nunca'
        }
    }

    transformString(string, maxChar, insertDots) {
        return string.slice(0, maxChar) + (((string.length > maxChar) && insertDots) ? '...' : '');
    }

    render() {
        return (
            <React.Fragment>
                <div style={{
                    borderRadius: '20px',
                    height: '25px',
                    width: '100%',
                    backgroundColor: this.state.color,
                    marginBottom: '15px',
                    color: 'white',
                    lineHeight: '25px',
                    textIndent: '10px',
                    fontSize: '16px'
                }}>{this.state.title}</div>
                <Typography variant="h6" gutterBottom>
                    Confirme os dados
                </Typography>
                <List disablePadding>
                    <ListItem key={this.state.title}>
                        <ListItemText primary="Titulo da Tarefa" />
                        <Typography variant="body2">{this.state.title}</Typography>
                    </ListItem>
                    <ListItem key={this.state.cowork}>
                        <ListItemText primary="Cowork" />
                        <Typography variant="body2">{this.state.cowork}</Typography>
                    </ListItem>
                    <ListItem key='description'>
                        <ListItemText primary="Descrição" />
                        <Typography variant="body2">{this.transformString(this.state.description, 25, true)}</Typography>
                    </ListItem>
                    <ListItem key={'frequency'}>
                        <ListItemText primary="Repetir" />
                        <Typography variant="body2">{this.transformFrequencyString(this.state.frequency)}</Typography>
                    </ListItem>
                    {this.state.frequency !== 'NEVER' && (
                        <ListItem key={'interval'}>
                            <ListItemText primary="Intervalo" />
                            <Typography variant="body2">{this.state.interval}</Typography>
                        </ListItem>
                    )}
                    <ListItem key={'start'}>
                        <ListItemText primary="Data Inicio" />
                        <Typography variant="body2">{moment(this.state.start).format('YYYY/MM/DD HH:mm')}</Typography>
                    </ListItem>
                    <ListItem key={'end'}>
                        <ListItemText primary="Data Fim" />
                        <Typography variant="body2">{moment(this.state.end).format('YYYY/MM/DD HH:mm')}</Typography>
                    </ListItem>
                </List>
            </React.Fragment>
        );
    }
}
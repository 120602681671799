import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';


export default class Info extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: props.defaultData.name,
            email: props.defaultData.email,
            phone: props.defaultData.phone,
            address: props.defaultData.address,
            nif: props.defaultData.nif,
            formErrors: { name: '', email: '', phone: '', nif: '' },
            nameIsValid: true,
            emailIsValid: true,
            phoneIsValid: true,
            formIsValid: true,
            nifIsValid: true
        }
    }


    handleInput(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.props.updateMainState(name, value);
        this.setState({ [name]: value }, () => { this.validateFields(name, value) });
    }

    validateFields(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let nameIsValid = this.state.nameIsValid;
        let emailIsValid = this.state.emailIsValid;
        let phoneIsValid = this.state.phoneIsValid;
        let nifIsValid = this.state.nifIsValid;

        switch (fieldName) {
            case 'name':
                nameIsValid = new RegExp(/^[a-zA-Z\u00C0-\u00FF]{3,}[\s]{1}[a-zA-Z\u00C0-\u00FF]{1,}$/).test(value) && value.length > 4;
                fieldValidationErrors.name = nameIsValid ? '' : 'Nome Invalido'
                break;
            case 'email':
                emailIsValid = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(value);
                fieldValidationErrors.email = emailIsValid ? '' : 'Email inválido'
                break;
            case 'phone':
                phoneIsValid = value.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/) && (value.length >= 9);
                fieldValidationErrors.phone = phoneIsValid ? '' : 'Nº Telemóvel inválido'
                break;
            case 'nif':
                nifIsValid = new RegExp(/^[a-zA-Z0-9]*$/).test(value) || value === '';
                fieldValidationErrors.nif = nifIsValid ? '' : 'Nif/Passaporte inválido';
                break;
            default:
                break;
        }


        this.setState({
            formErrors: fieldValidationErrors,
            nameIsValid: nameIsValid,
            emailIsValid: emailIsValid,
            phoneIsValid: phoneIsValid,
            nifIsValid: nifIsValid
        }, this.validateForm)

    }

    validateForm() {
        this.setState({
            formIsValid:
                this.state.nameIsValid &&
                this.state.emailIsValid &&
                this.state.phoneIsValid &&
                this.state.nifIsValid
        }, () => {
            this.props.updateMainState('validForm', this.state.formIsValid);
        })
    }

    render() {
        return (
            <React.Fragment>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            autoFocus
                            error={!this.state.nameIsValid ? true : false}
                            helperText={this.state.formErrors.name}
                            id="name"
                            name="name"
                            label="Nome (Primeiro e Ultimo)"
                            fullWidth
                            type="text"
                            defaultValue={this.state.name}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            error={!this.state.emailIsValid ? true : false}
                            helperText={this.state.formErrors.email}
                            id="email"
                            name="email"
                            label="Email"
                            type='email'
                            fullWidth
                            defaultValue={this.state.email}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            required
                            error={!this.state.phoneIsValid ? true : false}
                            helperText={this.state.formErrors.phone}
                            id="phone"
                            name="phone"
                            label="Nº Telemovel"
                            type='text'
                            fullWidth
                            defaultValue={this.state.phone}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            id="nif"
                            name="nif"
                            label="NIF/Passaporte"
                            type='text'
                            fullWidth
                            error={!this.state.nifIsValid}
                            helperText={this.state.formErrors.nif}
                            defaultValue={this.state.nif}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="address"
                            name="address"
                            label="Morada"
                            type='text'
                            fullWidth
                            defaultValue={this.state.address}
                            onChange={(e) => this.handleInput(e)}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}